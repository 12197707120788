import { EditMachineRendererComponent } from '@tab-container/machine-overview/table-config/edit-machine-renderer/edit-machine-renderer.component';
import { Permission } from '@shared/models';
import { ColumnDefinitionV3 } from '@gea/digital-ui-lib';

export const UPDATE_MACHINE = 'update-machine';
export const MACHINE_OVERVIEW_TABLE_ID = 'machine-overview-table';

const adminPermissions = new Set<Permission>([
  'APP_hrt_insightpartner-bluredcare_admin',
  'GEA_App_Administrator',
  'GEA_App_Operator',
  'customerAdmin',
  'admin',
  'maintenance_manager',
  'executive_board',
]);

const createColumnDefinition = ({
  key,
  displayName,
  isAdmin = false,
  sortable = false,
  shouldRender = true,
}: {
  displayName: string;
  key: string;
  isAdmin?: boolean;
  sortable?: boolean;
  shouldRender?: boolean;
}) => ({
  key,
  isAdmin,
  shouldRender,
  displayName: `MACHINE_OVERVIEW.TABLE_HEADERS.${displayName}`,
  sortable,
  renderer: isAdmin ? { component: EditMachineRendererComponent } : undefined,
});

export const machineColumnDefinitionsConfig = (permission: Permission): ColumnDefinitionV3[] => {
  const isAdmin = adminPermissions.has(permission);
  const shouldRender = isAdmin;

  return [
    createColumnDefinition({ isAdmin, displayName: 'MACHINE_NAME', key: 'machine.name' }),
    createColumnDefinition({ shouldRender, displayName: 'OMNI_ID', key: 'omniId' }),
    createColumnDefinition({ isAdmin, shouldRender, displayName: 'NAME', key: 'contactPersonName' }),
    createColumnDefinition({ isAdmin, shouldRender, displayName: 'EMAIL', key: 'contactPersonEmail' }),
    createColumnDefinition({ isAdmin, shouldRender, displayName: 'PHONE', key: 'contactPersonPhone' }),
    createColumnDefinition({ displayName: 'EQUIPMENT_TYPE', key: 'machine.equipmentType' }),
    createColumnDefinition({ displayName: 'SHOUT_DOWNS', key: 'shutdowns' }),
    createColumnDefinition({ displayName: 'WARNINGS', key: 'warnings' }),
  ].filter((column) => column.shouldRender) as ColumnDefinitionV3[];
};
