import { Component, EventEmitter, Input, Output, inject, DestroyRef } from '@angular/core';
import { SpinnerComponent } from '@app/shared/components';
import { RecommendationDto } from '@app/shared/models';
import { ButtonV2Module, SnackbarService } from '@gea/digital-ui-lib';
import { TranslateModule } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { RecommendationDialogComponent } from '../dialog';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
import { CreateRecommendation } from '@app/shared/state';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';

@Component({
  selector: 'gea-hrt-fault-recommendations-actions',
  standalone: true,
  imports: [ButtonV2Module, TranslateModule, SpinnerComponent],
  template: `
    <div class="section actions">
      <geaui-button-v2 (click)="openAddRecommendationDialog()" data-testid="btn-new-recommendation">
        {{ 'TAB_CONTAINER.FAULT_RECOMMENDATIONS.BUTTON_NEW_RECOMMENDATION' | translate }}
      </geaui-button-v2>

      @defer (when !recommendations()) {
        <geaui-button-v2
          data-testid="btn-edit-order"
          type="secondary"
          [disabled]="recommendations()?.length === 0"
          (click)="setEditMode.emit()">
          {{ 'TAB_CONTAINER.FAULT_RECOMMENDATIONS.BUTTON_EDIT_ORDER' | translate }}
        </geaui-button-v2>
      } @placeholder {
        <gea-hrt-spinner />
      }
    </div>
  `,
  styles: `
    .actions {
      margin: 16px 0;
      display: flex;
      align-items: baseline;
      gap: 1rem;

      & > * {
        margin: auto 0;
      }
    }
  `,
})
export class FaultRecommendationsActionsComponent {
  @Input({ required: true }) recommendations$: Observable<RecommendationDto[] | undefined> = of(undefined);
  @Input({ required: true }) selectedFaultCode!: number;

  @Output() setEditMode = new EventEmitter<void>();

  recommendations = toSignal(this.recommendations$, { initialValue: undefined });

  private readonly destroyRef = inject(DestroyRef);

  constructor(
    private readonly store: Store,
    private readonly dialog: MatDialog,
    private readonly snackBar: SnackbarService
  ) {}

  openAddRecommendationDialog() {
    const dialogRef = this.dialog.open(RecommendationDialogComponent, {
      minWidth: '50vw',
      minHeight: '50vh',
      autoFocus: true,
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (result?: RecommendationDto) => {
          if (!result?.actionName) {
            return;
          }

          const { actionName, images } = result;

          if (!this.selectedFaultCode) {
            return;
          }

          this.store.dispatch(new CreateRecommendation({ faultCode: this.selectedFaultCode, actionName, images })).subscribe({
            next: () =>
              this.snackBar.add({
                detail: 'TAB_CONTAINER.FAULT_RECOMMENDATIONS.DIALOG.NEW_RECOMMENDATION_SAVED_SUCCESSFULLY',
                summary: 'TAB_CONTAINER.FAULT_RECOMMENDATIONS.DIALOG.NEW_RECOMMENDATION_SAVED_SUCCESSFULLY',
                severity: 'success',
              }),
            error: () =>
              this.snackBar.add({
                detail: 'TAB_CONTAINER.FAULT_RECOMMENDATIONS.DIALOG.NEW_RECOMMENDATION_SAVED_SUCCESSFULLY',
                summary: 'TAB_CONTAINER.FAULT_RECOMMENDATIONS.DIALOG.NEW_RECOMMENDATION_SAVED_SUCCESSFULLY',
                severity: 'success',
              }),
          });
        },
        error: () =>
          this.snackBar.add({
            detail: 'TAB_CONTAINER.FAULT_RECOMMENDATIONS.DIALOG.NEW_RECOMMENDATION_SAVED_SUCCESSFULLY',
            summary: 'TAB_CONTAINER.FAULT_RECOMMENDATIONS.DIALOG.NEW_RECOMMENDATION_SAVED_SUCCESSFULLY',
            severity: 'success',
          }),
      });
  }
}
