import { Injectable } from '@angular/core';
import { ApiErrorResponse } from '@gea/digital-ui-lib';
import { EMPTY, Observable, throwError } from 'rxjs';
import { HrtApiService } from '../hrt-api/hrt-api.service';
import {
  CreateRecommendationParams,
  FetchRecommendationsParams,
  RecommendationDto,
  RecommendationOrderDto,
  RecommendationStatus,
  TroubleshootingDto,
} from '@shared/models';

const statusActionMapping: Record<RecommendationStatus, string> = {
  [RecommendationStatus.PENDING]: 'setPending',
  [RecommendationStatus.DONE]: 'setDone',
  [RecommendationStatus.SKIPPED]: 'setSkipped',
};

@Injectable({
  providedIn: 'root',
})
export class TroubleshootingApiService {
  readonly recommendationsListUrl = 'machines/{machine}/faults/{fault}/recommendations';
  readonly approvedRecommendationsListUrl = 'machines/{machine}/faults/{fault}/approvedrecommendations';

  constructor(private readonly api: HrtApiService) {}

  private buildUrl(template: string, machine: number, faultCode: number): string {
    return template.replace('{machine}', machine.toString()).replace('{fault}', faultCode.toString());
  }

  private throwClientError(): Observable<never> {
    return throwError(() => ({ message: 'TROUBLESHOOTING.CLIENT_ERROR.NO_FAULT_CHOSEN' }) as ApiErrorResponse);
  }

  fetchRecommendations({ machine, faultCode }: FetchRecommendationsParams): Observable<TroubleshootingDto> {
    if (!machine || !faultCode) {
      return throwError(
        () =>
          ({
            message: 'TROUBLESHOOTING.CLIENT_ERROR.NO_FAULT_CHOSEN',
          }) as ApiErrorResponse
      );
    }
    const url = this.recommendationsListUrl.replace('{machine}', machine.toString()).replace('{fault}', faultCode.toString());
    return this.api.getForOrganization<TroubleshootingDto>(url);
  }

  fetchApprovedRecommendations({ machine, faultCode }: FetchRecommendationsParams): Observable<TroubleshootingDto> {
    if (!machine || !faultCode) {
      return throwError(
        () =>
          ({
            message: 'TROUBLESHOOTING.CLIENT_ERROR.NO_FAULT_CHOSEN',
          }) as ApiErrorResponse
      );
    }
    const url = this.approvedRecommendationsListUrl
      .replace('{machine}', machine.toString())
      .replace('{fault}', faultCode.toString());
    return this.api.getForOrganization<TroubleshootingDto>(url);
  }

  fetchRecommendationsByFaultCode(faultCode: number): Observable<RecommendationDto[]> {
    return this.api.get<RecommendationDto[]>(`faults/${faultCode}/recommendations`);
  }

  updateRecommendationsOrder(recommendations: RecommendationDto[]): Observable<void> {
    const recommendationOrders: RecommendationOrderDto[] = recommendations.map((value, i) => ({
      id: value.id,
      order: i + 1,
      images: value.images ?? [],
    }));

    return this.api.put('settings/catalog/recommendations/order', recommendationOrders);
  }

  setRecommendationStatus(
    machine: number,
    faultCode: number,
    recommendationId: number,
    status: RecommendationStatus
  ): Observable<void> {
    if (!recommendationId || status === undefined) return EMPTY;
    const url = `${this.buildUrl(this.recommendationsListUrl, machine, faultCode)}/${recommendationId}/${statusActionMapping[status]}`;
    return this.api.putForOrganization(url);
  }

  createRecommendation({ actionName, faultCode, images }: CreateRecommendationParams): Observable<RecommendationDto> {
    return this.api.post<RecommendationDto>(`settings/catalog/faults/${faultCode}/recommendations`, { actionName, images });
  }

  updateRecommendation(recommendation: RecommendationDto): Observable<void> {
    return this.api.put(`recommendations/${recommendation.id}`, { ...recommendation, type: 1 });
  }

  validateRecommendation(recommendation: RecommendationDto): Observable<void> {
    return this.api.post(`settings/recommendations/${recommendation.id}/validate`, recommendation);
  }

  deleteRecommendation(recommendationId: number): Observable<void> {
    return this.api.delete(`recommendations/${recommendationId}`);
  }
}
