import { DecimalPipe, NgFor, NgSwitch, NgSwitchCase, NgSwitchDefault } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SystemHealthCheck } from '@shared/models';
import { HealthCheckDialogComponent } from '../health-check-dialog';
import { ComplexDialogV3Service, IconV3Component } from '@gea/digital-ui-lib';
import { TranslateModule } from '@ngx-translate/core';
import { ToTitlePipe } from '@app/shared/pipes';

@Component({
  selector: 'gea-hrt-system-health-check',
  standalone: true,
  imports: [IconV3Component, TranslateModule, ToTitlePipe, NgSwitch, NgSwitchCase, NgSwitchDefault, NgFor, DecimalPipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './health-check.component.html',
  styleUrls: ['./health-check.component.scss'],
})
export class SystemHealthCheckComponent {
  @Input({ required: true }) data!: SystemHealthCheck;
  @Input({ required: true }) organizationId!: number;
  @Input({ required: true }) machine!: number;

  constructor(public dialog: ComplexDialogV3Service) {}

  computedClass() {
    const className = {
      '-1': 'info',
      0: 'healthy',
      1: 'warning',
      2: 'error',
    }[this.data.status];
    return `${className}`;
  }

  get meta(): [string, number][] {
    // @ts-expect-error Type '[string, string | number][]' is not assignable to type '[string, number][]'.
    return Object.entries(this.data.healthStatusMetadata).slice(0, 3);
  }

  openDetailsPopUp(data: SystemHealthCheck) {
    this.dialog.open(
      {
        header: 'SYSTEM_HEALTH.DETAILS.TITLE',
        closable: true,
        hideButtons: true,
        data: data,
        width: '90vw',
      },
      HealthCheckDialogComponent
    );
  }
}
