<div class="section recommendations">
  <gea-hrt-info-box
    [withBorder]="false"
    [boxTitle]="'TAB_CONTAINER.FAULT_RECOMMENDATIONS.RECOMMENDATIONS.TITLE' | translate"
    [tooltip]="'TAB_CONTAINER.FAULT_RECOMMENDATIONS.RECOMMENDATIONS.TOOLTIP' | translate">
    <ng-container *ngIf="recommendations$ | async as recommendations; else loadingTemplate">
      <ng-container *ngIf="permission$ | async as permission; else loadingTemplate">
        <ng-container *ngIf="editOrderModeOn; else recommendationsList">
          <gea-hrt-recommendation-order-list
            data-testid="recommendation-order-list"
            [list]="recommendations"
            (orderFinished)="orderFinished.emit()" />
        </ng-container>

        <ng-template #recommendationsList>
          <gea-hrt-recommendations-list
            data-testid="recommendation-list"
            [isEditable]="true"
            [permission]="permission"
            [list]="recommendations" />
        </ng-template>
      </ng-container>
    </ng-container>

    <ng-template #loadingTemplate>
      <gea-hrt-spinner />
    </ng-template>
  </gea-hrt-info-box>
</div>
