import { NgIf } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ResetDemoAccountComponent } from '@app/shared/components';
import { Organization } from '@app/shared/models';
import { ButtonV2Module, IconV3Component, NavigationItem } from '@gea/digital-ui-lib';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'gea-hrt-tab-container-header',
  standalone: true,
  imports: [NgIf, IconV3Component, ButtonV2Module, ResetDemoAccountComponent, TranslateModule],
  templateUrl: './tab-container-header.component.html',
  styleUrl: './tab-container-header.component.scss',
})
export class TabContainerHeaderComponent {
  @Input({ required: true }) activeTab!: NavigationItem;
  @Input() organization?: Organization;
  @Input() shouldRenderResetDemoAccountButton = false;
  @Input() shouldRenderCustomerOverviewButton = false;

  @Output() openCustomerOverview = new EventEmitter();
}
