import { Component, DestroyRef, OnInit } from '@angular/core';
import {
  ApiErrorResponse,
  ColumnDefinitionV3,
  ErrorHandlerV2Service,
  SnackbarService,
  TableServiceV3,
  TableV3Component,
} from '@gea/digital-ui-lib';
import {
  MACHINE_OVERVIEW_TABLE_ID,
  UPDATE_MACHINE,
  machineColumnDefinitionsConfig,
} from '@tab-container/machine-overview/table-config/machine-column-definitions.config';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Select, Store } from '@ngxs/store';
import { MachineOverviewModel, Permission } from '@shared/models';
import { combineLatest, filter, first, Observable, switchMap, tap } from 'rxjs';
import { FetchMachines, MachinesState, OrganizationsState, SetMachineOverviewData } from '@shared/state';
import { OrganizationsApiService } from '@shared/services';
import { AsyncPipe } from '@angular/common';
import { SpinnerComponent } from '@app/shared/components';

@Component({
  selector: 'gea-hrt-machine-overview',
  standalone: true,
  imports: [TableV3Component, AsyncPipe, SpinnerComponent],
  template: `
    <div class="machine-overview-container">
      @defer (when permission$ | async) {
        <geaui-table-v3
          [tableId]="TABLE_ID"
          [columnDefinitions]="columnDefinitions"
          [data]="machineOverviewData"
          [sortable]="true"
          [loading]="loading">
        </geaui-table-v3>
      } @placeholder {
        <gea-hrt-spinner />
      }
    </div>
  `,
  styles: `
    .machine-overview-container {
      display: flex;
      flex-direction: column;
      margin: auto;
      margin: 16px 0;
    }
  `,
})
export class MachineOverviewComponent implements OnInit {
  readonly TABLE_ID = MACHINE_OVERVIEW_TABLE_ID;

  loading = false;

  machineOverviewData: MachineOverviewModel[] = [];
  columnDefinitions: ColumnDefinitionV3[] = [];

  @Select(OrganizationsState.permission) readonly permission$!: Observable<Permission | undefined>;

  constructor(
    private readonly store: Store,
    private readonly destroyRef: DestroyRef,
    private readonly organizationsApi: OrganizationsApiService,
    private readonly errorHandler: ErrorHandlerV2Service,
    private readonly snackbarService: SnackbarService,
    private readonly tableService: TableServiceV3
  ) {}

  ngOnInit() {
    this.loadInitialData();
    this.setupTableActions();
  }

  private loadInitialData() {
    const machines$ = this.store.selectSnapshot(MachinesState.machines)?.length
      ? this.initializeComponent()
      : this.store.dispatch(new FetchMachines()).pipe(switchMap(() => this.initializeComponent()));

    machines$.subscribe({
      error: (error) => this.errorHandler.handleError(error),
    });
  }

  private initializeComponent(): Observable<MachineOverviewModel[]> {
    return combineLatest([this.permission$, this.store.dispatch(new SetMachineOverviewData())]).pipe(
      switchMap(([permission]) => {
        if (permission) {
          this.columnDefinitions = machineColumnDefinitionsConfig(permission);
        }
        return this.store.select(MachinesState.machineOverviewData);
      }),
      filter((machines) => machines.length > 0),
      first(),
      tap((machines) => {
        this.machineOverviewData = machines;
      })
    );
  }

  private setupTableActions() {
    this.tableService.actions
      .pipe(
        filter((action) => action.tableId === this.TABLE_ID && action.action === UPDATE_MACHINE),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((action) => this.updateMachine(action.rowData));
  }

  private save(updatedMachine: MachineOverviewModel) {
    const organizationId = this.store.selectSnapshot(OrganizationsState.selectedId);

    if (!organizationId) {
      return;
    }

    this.loading = true;
    this.organizationsApi.updateMachine(organizationId, updatedMachine).subscribe({
      next: () => this.onSaveSuccess(),
      error: (error: ApiErrorResponse) => this.onSaveError(error),
    });
  }

  private updateMachine(updatedMachine: MachineOverviewModel) {
    const index = this.machineOverviewData.findIndex((m) => m.machine.id === updatedMachine.machine.id);

    if (index !== -1) {
      this.machineOverviewData = [
        ...this.machineOverviewData.slice(0, index),
        updatedMachine,
        ...this.machineOverviewData.slice(index + 1),
      ];
    }

    this.save(updatedMachine);
  }

  private onSaveSuccess() {
    this.loading = false;
    this.store.dispatch(new FetchMachines());
    this.snackbarService.add({
      summary: 'MACHINE_OVERVIEW.SNACKBAR.SAVE_MACHINE.SUMMARY',
      detail: 'MACHINE_OVERVIEW.SNACKBAR.SAVE_MACHINE.DETAIL',
      severity: 'success',
    });
  }

  private onSaveError(error: ApiErrorResponse) {
    this.loading = false;
    this.errorHandler.handleError(error);
    this.snackbarService.add({
      summary: 'MACHINE_OVERVIEW.SNACKBAR.SAVE_MACHINE.ERROR',
      detail: error.message || 'An unknown error occurred',
      severity: 'error',
    });
  }
}
