import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';

import { AnnunciationsMetaData, FaultCatalog, Permission, RecommendationDto } from '@shared/models';
import { SelectOption } from '@gea/digital-ui-lib';
import {
  ClearRecommendations,
  CreateRecommendationFromResolvedFault,
  FaultRecommendationsState,
  FetchFaultCatalog,
  FetchRecommendationsByFault,
  TroubleShootingState,
  FetchResolvedFaults,
  FetchAnnunciationsMetadata,
  OrganizationsState,
} from '@shared/state';
import { Select, Store } from '@ngxs/store';
import { Observable, Subject, Subscription, takeUntil } from 'rxjs';

import { SolvedFaultResponse } from './';
import { Router, ActivatedRoute } from '@angular/router';
import { NgIf } from '@angular/common';
import { HintComponent } from '@app/shared/components';
import { TranslateModule } from '@ngx-translate/core';

import { SolvedFaultsComponent } from './solved-faults/solved-faults.component';
import { FaultRecommendationsActionsComponent } from './actions/actions.component';
import { FaultRecommendationsSectionComponent } from './recommendations-section/recommendations-section.component';
import { FaultRecommendationsFiltersComponent } from './filters/fault-recommendations-filters.component';

@Component({
  selector: 'gea-hrt-fault-recommendations',
  templateUrl: './fault-recommendations.component.html',
  styleUrls: ['./fault-recommendations.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    FaultRecommendationsFiltersComponent,
    NgIf,
    FaultRecommendationsSectionComponent,
    FaultRecommendationsActionsComponent,
    SolvedFaultsComponent,
    HintComponent,
    TranslateModule,
  ],
})
export class FaultRecommendationsComponent implements OnInit, OnDestroy {
  closeDialog$!: Subscription;

  selectedFaultCode?: number;
  hasRecommendations?: boolean;
  selectedFaultName?: string;

  editOrderModeOn = false;
  ngDestroyed$ = new Subject<void>();
  faultCatalog: FaultCatalog = [];

  @Select(FaultRecommendationsState.faultCatalog) faultCatalog$?: Observable<FaultCatalog | undefined>;
  @Select(FaultRecommendationsState.annunciationsMetadata) annunciationsMetadata$!: Observable<AnnunciationsMetaData | undefined>;
  @Select(TroubleShootingState.recommendations) recommendations$!: Observable<RecommendationDto[] | undefined>;
  @Select(OrganizationsState.permission) permission$!: Observable<Permission>;

  constructor(
    readonly store: Store,
    readonly router: Router,
    readonly route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.fetchResolvedFaults();
    this.fetchMetadata();
    this.subscribeToQueryParams();
    this.subscribeToFaultCatalog();
  }

  ngOnDestroy() {
    this.ngDestroyed$.next();
    this.ngDestroyed$.complete();
  }

  private fetchResolvedFaults() {
    this.store.dispatch(new FetchResolvedFaults());
  }

  private fetchMetadata() {
    this.store.dispatch(new FetchAnnunciationsMetadata());
  }

  private subscribeToQueryParams() {
    this.route.queryParams.pipe(takeUntil(this.ngDestroyed$)).subscribe((params) => {
      if (this.selectedFaultCode !== undefined || this.hasRecommendations !== undefined) {
        return;
      }

      this.setHasRecommendations(params['hasRecommendations'] === 'true');

      // @ts-expect-error Argument of type 'number' is not assignable to parameter of type 'SelectOption<number>'.ts(2345)
      this.faultChanged(Number(params['faultCode']));
    });
  }

  private subscribeToFaultCatalog() {
    this.faultCatalog$?.pipe(takeUntil(this.ngDestroyed$)).subscribe((faultCatalog) => {
      if (!faultCatalog?.length) {
        return;
      }

      this.faultCatalog = faultCatalog;
      this.updateSelectedFaultNameFilter();

      if (!this.selectedFaultCode) {
        this.updateSelectedFaultCode(faultCatalog[0].id);
      }
    });
  }

  private setQueryParams(queryParams: { faultCode?: number; hasRecommendations?: boolean }) {
    this.router.navigate([], { queryParams, queryParamsHandling: 'merge' }).catch(() => {
      console.warn('query param could not be set');
    });
  }

  faultChanged(temp?: SelectOption<number>) {
    // @ts-expect-error Type 'SelectOption<number>' is not assignable to type 'number'.ts(2322)
    const faultCode: number = temp;

    if (faultCode === this.selectedFaultCode) {
      return;
    }

    this.updateSelectedFaultCode(faultCode);
  }

  updateSelectedFaultCode(faultCode: number) {
    this.selectedFaultCode = faultCode;

    this.updateSelectedFaultNameFilter();
    this.setQueryParams({ faultCode });
    this.fetchRecommendations();
  }

  setHasRecommendations(hasRecommendations: boolean) {
    this.selectedFaultCode = undefined;
    this.hasRecommendations = hasRecommendations;

    this.setQueryParams({ hasRecommendations });
    this.fetchFaultCatalog();
  }

  fetchFaultCatalog() {
    const { store, hasRecommendations } = this;

    store.dispatch([new ClearRecommendations(), new FetchFaultCatalog({ hasRecommendations })]);
  }

  fetchRecommendations() {
    const { store, selectedFaultCode } = this;

    if (selectedFaultCode) {
      store.dispatch(new FetchRecommendationsByFault(selectedFaultCode));
    }
  }

  setReviewStatus({ resolvedFault, reviewStatus }: SolvedFaultResponse) {
    this.store.dispatch(new CreateRecommendationFromResolvedFault({ resolvedFault, reviewStatus })).subscribe(() => {
      this.fetchResolvedFaults();
    });
  }

  orderFinished() {
    this.editOrderModeOn = false;
  }

  /**
   * For some unknown reason, the faultName dropdown will only refresh this way
   */
  private updateSelectedFaultNameFilter() {
    this.selectedFaultName = undefined;
    setTimeout(() => {
      this.selectedFaultName = this.faultCatalog.find((fault) => fault.id === this.selectedFaultCode)?.name ?? ' ';
    }, 0);
  }
}
