<div class="dialog-container">
  <div mat-dialog-title class="dialog-header">
    <gea-hrt-heading class="dialog-title" type="sub">{{
      'MACHINE_OVERVIEW.CONFIRMATION_DIALOG.TITLE' | translate
    }}</gea-hrt-heading>
    <gea-hrt-close-dialog-button (closeDialog)="dismiss()" />
  </div>
  <mat-dialog-content>
    <div class="content">
      <div class="message">
        <p>{{ 'MACHINE_OVERVIEW.CONFIRMATION_DIALOG.MESSAGE' | translate }}</p>
      </div>
      <div class="buttons-container">
        <geaui-button-v2 (click)="dismiss()" class="cancel-button">
          {{ 'MACHINE_OVERVIEW.CONFIRMATION_DIALOG.DISMISS' | translate }}
        </geaui-button-v2>
        <geaui-button-v2 (click)="confirm()" class="confirm-button">
          {{ 'MACHINE_OVERVIEW.CONFIRMATION_DIALOG.CONFIRM' | translate }}
        </geaui-button-v2>
      </div>
    </div>
  </mat-dialog-content>
</div>
