<div class="images-dialog-container">
  <span (click)="closeDialog()" class="close-button">
    <geaui-icon-v3 class="close-icon" file="close" color="grey" />
  </span>
  <mat-dialog-content>
    <div class="gallery">
      <div class="main-image">
        <img [src]="currentImage" alt="Preview" class="preview-image" />
      </div>
      <div class="thumbnails">
        <div *ngFor="let image of data.images; let i = index; trackBy: trackByImage" class="carousel-slide current thumbnail">
          <img [src]="image" alt="Preview" class="preview-image" (click)="setCurrentImage(image)" />
        </div>
      </div>
    </div>
  </mat-dialog-content>
</div>
