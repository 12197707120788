import { Injectable } from '@angular/core';

import { Action, Selector, State, StateContext } from '@ngxs/store';
import { ApiErrorResponse, ErrorHandlerV2Service } from '@gea/digital-ui-lib';
import { EMPTY, catchError, first, of, tap } from 'rxjs';

import { FetchFaultAnalysis } from '@shared/state/faults';
import { ProblemSolvingApiService } from '@app/shared/services';
import { TroubleshootingApiService } from '@shared/services';
import {
  ClearRecommendations,
  ClearSelectedFault,
  CreateRecommendation,
  DeleteRecommendation,
  FetchRecommendations,
  FetchRecommendationsByFault,
  ReorderRecommendations,
  SetRecommendationStatus,
  SetTroubleShootingSelectedFault,
  SolveProblem,
  SolveProblemSuccessful,
  UpdateRecommendation,
  ValidateRecommendation,
} from './troubleshooting.action';
import { RecommendationDto, RecommendationFaultStatus, TroubleshootingStateModel, TroubleshootingDto } from '@shared/models';

@State<TroubleshootingStateModel>({
  name: 'troubleshooting',
})
@Injectable()
export class TroubleShootingState {
  constructor(
    readonly troubleshootingApiService: TroubleshootingApiService,
    readonly problemSolvingApi: ProblemSolvingApiService,
    readonly errorHandler: ErrorHandlerV2Service
  ) {}

  @Selector()
  static recommendationText(state: TroubleshootingStateModel): string | undefined {
    return state.recommendationText;
  }

  @Selector()
  static recommendations(state: TroubleshootingStateModel): RecommendationDto[] | undefined {
    return state.recommendations;
  }

  @Selector()
  static approvedRecommendations(state: TroubleshootingStateModel): RecommendationDto[] | undefined {
    return state.approvedRecommendations;
  }

  @Selector()
  static recommendationFaultStatus(state: TroubleshootingStateModel): RecommendationFaultStatus | undefined {
    return state.status;
  }

  @Selector()
  static selectedFault(state: TroubleshootingStateModel): number | undefined {
    return state.selected;
  }

  @Selector()
  static problemSolvedSuccess(state: TroubleshootingStateModel): TroubleshootingStateModel['problemSolvedSuccess'] {
    return state.problemSolvedSuccess;
  }

  /** ACTION **/

  @Action(FetchRecommendations)
  fetchRecommendations(
    { patchState, dispatch }: StateContext<TroubleshootingStateModel>,
    { params: { faultCode, machine } }: FetchRecommendations
  ) {
    dispatch(new ClearRecommendations());

    patchState({
      recommendations: undefined,
      recommendationText: undefined,
    });

    return this.troubleshootingApiService.fetchRecommendations({ machine, faultCode }).pipe(
      catchError((error: ApiErrorResponse) => {
        if (error?.headers) {
          this.errorHandler.handleError(error);
        }
        return of({
          recommendationText: 'n.a.',
          recommendations: [],
          status: RecommendationFaultStatus.PENDING,
        } as TroubleshootingDto);
      }),
      first(),
      tap(({ recommendationText, recommendations, status }) => {
        patchState({
          recommendationText,
          recommendations,
          status,
        });
      })
    );
  }

  @Action(FetchRecommendations)
  fetchApprovedRecommendation(
    { patchState, dispatch }: StateContext<TroubleshootingStateModel>,
    { params: { machine, faultCode } }: FetchRecommendations
  ) {
    dispatch(new ClearRecommendations());

    patchState({
      approvedRecommendations: undefined,
    });

    return this.troubleshootingApiService.fetchApprovedRecommendations({ machine, faultCode }).pipe(
      catchError((error: ApiErrorResponse) => {
        if (error?.headers) {
          this.errorHandler.handleError(error);
        }
        return of({
          recommendationText: 'n.a.',
          recommendations: [],
          status: RecommendationFaultStatus.PENDING,
        } as TroubleshootingDto);
      }),
      first(),
      tap(({ recommendations }) => {
        patchState({ approvedRecommendations: recommendations });
      })
    );
  }

  @Action(FetchRecommendationsByFault)
  fetchRecommendationsByFaultCode(
    { patchState, dispatch }: StateContext<TroubleshootingStateModel>,
    action: FetchRecommendationsByFault
  ) {
    dispatch(new ClearRecommendations());

    patchState({
      recommendations: undefined,
    });

    return this.troubleshootingApiService.fetchRecommendationsByFaultCode(action.faultCode).pipe(
      catchError((error: ApiErrorResponse) => {
        if (error?.headers) {
          this.errorHandler.handleError(error);
        }
        return of([] as RecommendationDto[]);
      }),
      first(),
      tap((recommendations) => {
        patchState({
          recommendations,
        });
      })
    );
  }

  @Action(SetTroubleShootingSelectedFault)
  setTroubleShootingSelectedFault(
    { patchState }: StateContext<TroubleshootingStateModel>,
    { faultCode }: SetTroubleShootingSelectedFault
  ) {
    patchState({
      selected: faultCode,
    });
  }

  @Action(ClearSelectedFault)
  clearSelectedFault({ patchState }: StateContext<TroubleshootingStateModel>) {
    patchState({
      selected: undefined,
    });
  }

  @Action(ClearRecommendations)
  clearRecommendations({ patchState }: StateContext<TroubleshootingStateModel>) {
    return of(
      patchState({
        recommendationText: undefined,
        recommendations: undefined,
      })
    );
  }

  @Action(ReorderRecommendations)
  updateRecommendationsOrder({ patchState }: StateContext<TroubleshootingStateModel>, action: ReorderRecommendations) {
    patchState({
      recommendations: undefined,
    });

    return this.troubleshootingApiService
      .updateRecommendationsOrder(action.recommendations)
      .pipe(catchError((error: ApiErrorResponse) => this.handleError(error)))
      .subscribe(() => {
        patchState({
          recommendations: action.recommendations,
        });
      });
  }

  @Action(SetRecommendationStatus)
  setRecommendationStatus(
    { dispatch }: StateContext<TroubleshootingStateModel>,
    { params: { faultCode, machine, recommendationId, recommendationStatus } }: SetRecommendationStatus
  ) {
    return this.troubleshootingApiService
      .setRecommendationStatus(machine, faultCode, recommendationId, recommendationStatus)
      .pipe(
        catchError((error: ApiErrorResponse) => this.handleError(error)),
        tap(() => {
          dispatch(new FetchRecommendations({ machine, faultCode }));
        })
      )
      .subscribe({
        next() {
          return of(true);
        },
        error() {
          return of(false);
        },
      });
  }

  @Action(UpdateRecommendation)
  updateRecommendation(
    { patchState, getState }: StateContext<TroubleshootingStateModel>,
    { recommendation }: UpdateRecommendation
  ) {
    patchState({
      recommendations: undefined,
    });

    return this.troubleshootingApiService.updateRecommendation(recommendation).pipe(
      catchError((error: ApiErrorResponse) => this.handleError(error)),
      tap(() => {
        const currentState = getState();
        const recommendations =
          currentState.recommendations?.map((r) => {
            return r.id === recommendation.id
              ? {
                  ...r,
                  ...recommendation,
                }
              : r;
          }) ?? [];

        patchState({
          ...currentState,
          recommendations,
        });
      })
    );
  }

  @Action(ValidateRecommendation)
  validateRecommendation(
    { patchState, getState }: StateContext<TroubleshootingStateModel>,
    { recommendation }: ValidateRecommendation
  ) {
    patchState({
      recommendations: undefined,
    });

    return this.troubleshootingApiService.validateRecommendation(recommendation).pipe(
      catchError((error: ApiErrorResponse) => this.handleError(error)),
      tap(() => {
        const currentState = getState();
        const recommendations =
          currentState.recommendations?.map((r) => {
            return r.id === recommendation.id
              ? {
                  ...r,
                  ...recommendation,
                }
              : r;
          }) ?? [];

        patchState({
          ...currentState,
          recommendations,
        });
      })
    );
  }

  @Action(DeleteRecommendation)
  deleteRecommendation(
    { getState, setState, patchState }: StateContext<TroubleshootingStateModel>,
    { recommendationID }: DeleteRecommendation
  ) {
    patchState({
      recommendations: undefined,
    });

    return this.troubleshootingApiService.deleteRecommendation(recommendationID).pipe(
      catchError((error: ApiErrorResponse) => this.handleError(error)),
      tap(() => {
        const currentState = getState();
        const recommendations = [...(currentState?.recommendations ?? [])];
        const index = recommendations.findIndex(({ id }) => id === recommendationID);

        recommendations.splice(index, 1);

        setState({
          ...currentState,
          recommendations,
        });
      })
    );
  }

  @Action(SolveProblem)
  solveProblem(
    { dispatch }: StateContext<TroubleshootingStateModel>,
    { params: { faultCode, machine, selectedRecommendations, additionalNote, images = [] } }: SolveProblem
  ) {
    return this.problemSolvingApi.solveProblem({ machine, faultCode, selectedRecommendations, additionalNote, images }).pipe(
      catchError((error: ApiErrorResponse) => this.handleError(error)),
      tap(() => {
        dispatch([new SolveProblemSuccessful(), new FetchFaultAnalysis({ machines: [] })]);
      })
    );
  }

  @Action(SolveProblemSuccessful)
  solveProblemSuccessful({ patchState }: StateContext<TroubleshootingStateModel>) {
    setTimeout(() => patchState({ problemSolvedSuccess: false }));
    return of(
      patchState({
        problemSolvedSuccess: true,
      })
    );
  }

  @Action(CreateRecommendation)
  createRecommendation(
    { patchState, getState }: StateContext<TroubleshootingStateModel>,
    { params: { faultCode, actionName, images } }: CreateRecommendation
  ) {
    return this.troubleshootingApiService.createRecommendation({ faultCode, actionName, images }).pipe(
      catchError((error: ApiErrorResponse) => {
        this.errorHandler.handleError(error);
        return EMPTY;
      }),
      tap((newRecommendation: RecommendationDto) => {
        if (newRecommendation) {
          const currentState = getState();
          const updatedRecommendations = [...(currentState?.recommendations ?? []), newRecommendation];
          patchState({
            ...currentState,
            recommendations: updatedRecommendations,
          });
        }
      })
    );
  }

  handleError(error: ApiErrorResponse) {
    if (error) {
      this.errorHandler.handleError(error);
    }
    return EMPTY;
  }
}
