import { Component, OnInit } from '@angular/core';
import { AsyncPipe, NgIf } from '@angular/common';
import { ButtonV2Module, ColumnRendererComponentV3, IconV3Component } from '@gea/digital-ui-lib';
import { Organization } from '@shared/models';
import { Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { OrganizationsState, SetSelectedOrganization } from '@shared/state';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'gea-hrt-text-organization-name-render',
  standalone: true,
  imports: [NgIf, AsyncPipe, ButtonV2Module, IconV3Component, TranslateModule],
  template: `
    <div class="name-container">
      <h5 [title]="rowData?.name">{{ rowData?.name }}</h5>

      <div class="action-container">
        <div class="selected-customer" *ngIf="(organizationId$ | async) === rowData?.id; else notSelected">
          <span class="selected-customer-icon">
            <geaui-icon-v3 file="check_green" />
          </span>
          <span class="selected-customer-label">{{ 'CUSTOMER_OVERVIEW.CUSTOMER_SELECTED' | translate }}</span>
        </div>
        <ng-template #notSelected>
          <div class="select-customer-button">
            <geaui-button-v2 buttonType="primary" buttonSize="md" [disabled]="isDisabled" (click)="selectOrganization(rowData)">
              {{ 'CUSTOMER_OVERVIEW.SELECT_CUSTOMER' | translate }}
            </geaui-button-v2>
          </div>
        </ng-template>
      </div>
    </div>
  `,
  styles: `
    .name-container {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }

    h5 {
      font-size: 16px;
      font-weight: 500;
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 0;
    }

    .action-container {
      margin-top: 5px;
    }

    .selected-customer {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
    }

    .selected-customer-label {
      flex: 1;
    }
  `,
})
export class TextOrganizationNameRenderComponent implements ColumnRendererComponentV3<Organization>, OnInit {
  rowData?: Organization;
  @Select(OrganizationsState.selectedId) organizationId$?: Observable<number>;
  isDisabled = false;

  constructor(
    private readonly router: Router,
    private readonly store: Store
  ) {}

  ngOnInit(): void {
    if (this.rowData) {
      this.isDisabled = this.rowData.machines?.length === 0;
    }
  }

  selectOrganization(organization?: Organization) {
    if (organization) {
      this.store.dispatch(new SetSelectedOrganization(organization));
      void this.router.navigate([`fault-analysis/${organization.id}`]);
    }
  }
}
