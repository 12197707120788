import { resolvedFaultsColumnDefinitions } from './column-definition';
import { Component, OnInit, EventEmitter, Output, OnDestroy, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { RecommendationReviewStatus, ResolvedFault } from '@app/shared/models';
import { FilterTableSettings, TableServiceV3, TableV3Component } from '@gea/digital-ui-lib';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Subject, filter, takeUntil, Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { NgIf } from '@angular/common';
import { FaultRecommendationsState } from '@app/shared/state';
import { Select } from '@ngxs/store';
import { HintComponent, InfoBoxComponent, SpinnerComponent } from '@app/shared/components';

export enum TableActionType {
  VIEW_SOLVED_FAULT_DETAIL = 'VIEW_SOLVED_FAULT_DETAIL',
  VIEW_SOLVED_FAULT_IMAGES = 'VIEW_SOLVED_FAULT_IMAGES',
}

export type SolvedFaultResponse = { resolvedFault: ResolvedFault; reviewStatus: RecommendationReviewStatus };

@Component({
  selector: 'gea-hrt-fault-recommendations-solved-faults',
  standalone: true,
  imports: [NgIf, InfoBoxComponent, TranslateModule, TableV3Component, SpinnerComponent, HintComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './solved-faults.component.html',
})
export class SolvedFaultsComponent implements OnInit, OnDestroy {
  TABLE_ID = 'SOLVED_FAULTS_TABLE';
  columnDefinitions = resolvedFaultsColumnDefinitions;
  parsedData?: ResolvedFault[];
  private cachedFilters: Record<string, RegExp> = {};

  @Select(FaultRecommendationsState.resolvedFaults) resolvedFaults$!: Observable<ResolvedFault[] | undefined>;
  @Output() setReviewStatus = new EventEmitter<SolvedFaultResponse>();

  private readonly ngDestroyed$ = new Subject<void>();

  constructor(
    private readonly tableService: TableServiceV3,
    private readonly dialog: MatDialog,
    private readonly translateService: TranslateService,
    private readonly cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.subscribeToTableActions();
    this.subscribeToResolvedFaults();
  }

  ngOnDestroy() {
    this.ngDestroyed$.next();
    this.ngDestroyed$.complete();
  }

  private subscribeToResolvedFaults() {
    this.resolvedFaults$.pipe(takeUntil(this.ngDestroyed$)).subscribe((data) => {
      if (data) {
        this.subscribeToTableSettings(data);
      }
    });
  }

  private subscribeToTableSettings(data: ResolvedFault[]) {
    this.tableService
      .getFilterTableSettings(this.TABLE_ID)
      .pipe(takeUntil(this.ngDestroyed$))
      .subscribe((tableFilter) => {
        this.updateFilterCache(tableFilter);
        this.parsedData = this.filterAndSortData(data, tableFilter);
        this.cdr.markForCheck();
      });
  }

  private subscribeToTableActions() {
    this.tableService.actions
      .pipe(
        takeUntil(this.ngDestroyed$),
        filter(
          (action) =>
            action.tableId === this.TABLE_ID && Object.values(TableActionType).includes(action.action as TableActionType)
        )
      )
      .subscribe((action) => {
        if (action.action === TableActionType.VIEW_SOLVED_FAULT_DETAIL) {
          this.openResolvedFaultDialog(action);
        } else if (action.action === TableActionType.VIEW_SOLVED_FAULT_IMAGES) {
          this.openResolvedFaultImagesDialog(action);
        }
      });
  }

  private async openResolvedFaultDialog({ rowData: resolvedFault }: { rowData: ResolvedFault }) {
    const { SolvedFaultsDetailDialogComponent } = await import('./detail-dialog/detail-dialog.component');
    this.dialog
      .open(SolvedFaultsDetailDialogComponent, { data: resolvedFault })
      .afterClosed()
      .pipe(takeUntil(this.ngDestroyed$))
      .subscribe((response) => {
        if (response) {
          this.setReviewStatus.emit(response);
        }
      });
  }

  private async openResolvedFaultImagesDialog({ rowData: resolvedFault }: { rowData: ResolvedFault }) {
    const { RecommendationImagesDialogComponent } = await import('@tab-container/troubleshooting');
    this.dialog.open(RecommendationImagesDialogComponent, { data: { images: resolvedFault.images || [] }, autoFocus: true });
  }

  private updateFilterCache(filterSettings: FilterTableSettings) {
    this.cachedFilters = {};
    for (const key in filterSettings.columns) {
      const columnFilter = filterSettings.columns[key]?.filter?.[0];
      if (columnFilter) {
        this.cachedFilters[key] = new RegExp(columnFilter, 'i');
      }
    }
  }

  private filterAndSortData(data: ResolvedFault[], filterSettings: FilterTableSettings): ResolvedFault[] {
    return [...data]
      .filter((item) => this.applyColumnFilters(item))
      .sort((a, b) => this.applyColumnSorting(a, b, filterSettings));
  }

  private applyColumnFilters(item: ResolvedFault): boolean {
    for (const key in this.cachedFilters) {
      const itemValue = item[key as keyof ResolvedFault] as string;
      if (typeof itemValue === 'string' && !this.cachedFilters[key].test(itemValue)) {
        return false;
      }
    }
    return true;
  }

  private applyColumnSorting(a: ResolvedFault, b: ResolvedFault, filterSettings: FilterTableSettings): number {
    for (const key in filterSettings.columns) {
      const sortDirection = filterSettings.columns[key]?.sort;
      if (!sortDirection) continue;

      const aValue = a[key as keyof ResolvedFault];
      const bValue = b[key as keyof ResolvedFault];

      if (typeof aValue === 'string' && typeof bValue === 'string') {
        return sortDirection === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
      }
      if (typeof aValue === 'number' && typeof bValue === 'number') {
        return sortDirection === 'asc' ? aValue - bValue : bValue - aValue;
      }
    }
    return 0;
  }
}
