import { FaultComparator } from './fault.comparator';
import { Injectable } from '@angular/core';

import { Action, Selector, State, StateContext } from '@ngxs/store';
import { ApiErrorResponse, ErrorHandlerV2Service, SelectOption } from '@gea/digital-ui-lib';
import { catchError, first, of } from 'rxjs';

import { DEFAULT_DATE_RANGE } from '@shared/helpers';
import {
  ClearFaults,
  FetchFaultAnalysis,
  FetchFaultCodes,
  ResetFaultAnalysis,
  SetSelectedFaultAnalysisFaultCodes,
  SortAnnunciations,
} from './faults.action';
import { FaultsApiService } from '@shared/services';
import {
  FaultAnalysisHistoryItem,
  Shutdown,
  Warning,
  FaultAnalysisDto,
  MachineFaultDto,
  FaultsStateModel,
  Annunciation,
  SortOrder,
} from '@shared/models';
import { SetTroubleShootingSelectedFault } from '../troubleshooting';

@State<FaultsStateModel>({
  name: 'faults',
})
@Injectable()
export class FaultsState {
  constructor(
    readonly faultsApi: FaultsApiService,
    readonly errorHandler: ErrorHandlerV2Service
  ) {}

  @Selector()
  static faultyMachines(state: FaultsStateModel): SelectOption<number>[] | undefined {
    return state.faultyMachines;
  }

  @Selector()
  static faultCodes(state: FaultsStateModel): SelectOption[] | undefined {
    return state.faultCodes;
  }

  @Selector()
  static faultCodeGroups(state: FaultsStateModel): SelectOption[] | undefined {
    return state.faultCodeGroups;
  }

  @Selector()
  static selected(state: FaultsStateModel): number[] | undefined {
    return state.selected;
  }

  @Selector()
  static shutdowns(state: FaultsStateModel): Shutdown[] | undefined {
    return state.shutdowns;
  }

  @Selector()
  static warnings(state: FaultsStateModel): Warning[] | undefined {
    return state.warnings;
  }

  @Selector()
  static annunciations(state: FaultsStateModel): MachineFaultDto[] {
    return [...(state.warnings ?? []), ...(state.shutdowns ?? [])];
  }

  @Selector()
  static faultHistory(state: FaultsStateModel): FaultAnalysisHistoryItem[] | undefined {
    return state.faultHistory;
  }

  @Action(FetchFaultCodes)
  fetchFaultCodes(
    { patchState, dispatch }: StateContext<FaultsStateModel>,
    { params: { machines, dateRange = DEFAULT_DATE_RANGE, faultStatus = ['0'] } }: FetchFaultCodes
  ) {
    dispatch(new ClearFaults());

    patchState({
      faultyMachines: undefined,
      shutdowns: undefined,
      warnings: undefined,
      selected: undefined,
      faultHistory: undefined,
      faultCodes: undefined,
      faultCodeGroups: undefined,
    });

    this.faultsApi
      .fetchFaultCodes({ machines, dateRange, faultStatus })
      .pipe(
        catchError((error: ApiErrorResponse) => {
          if (error) {
            this.errorHandler.handleError(error);
          }
          const faultAnalysis: FaultAnalysisDto = {
            machines: [],
            shutdowns: [],
            warnings: [],
            faultHistory: [],
          };

          return of({ faultAnalysis, faultCodeGroups: [], faultCodes: [] });
        }),
        first()
      )
      .subscribe((state) => {
        const { faultAnalysis } = state;
        const faultyMachines = faultAnalysis.machines.map(({ name, id: value }) => ({ name, value }));
        const shutdowns = this.sortFaults([...faultAnalysis.shutdowns], 'totalPending', 'desc');
        const warnings = this.sortFaults([...faultAnalysis.warnings], 'totalPending', 'desc');

        patchState({
          ...state,
          faultyMachines,
          shutdowns,
          warnings,
          faultHistory: state.faultAnalysis.faultHistory,
        });

        dispatch(new SetSelectedFaultAnalysisFaultCodes(state.faultCodeGroups.map(({ value }) => value)));

        if (!state.faultCodeGroups.length) {
          dispatch(new SetTroubleShootingSelectedFault(undefined));
        }
      });
  }

  @Action(FetchFaultAnalysis)
  fetchFaultAnalysis(
    { patchState }: StateContext<FaultsStateModel>,
    { params: { machines, faults = [], dateRange = DEFAULT_DATE_RANGE, faultStatus = ['0'] } }: FetchFaultAnalysis
  ) {
    patchState({
      shutdowns: undefined,
      warnings: undefined,
      faultHistory: undefined,
      faultyMachines: undefined,
    });

    this.faultsApi
      .fetchFaultAnalysis({ machines, dateRange, faults, faultStatus })
      .pipe(
        catchError((error: ApiErrorResponse) => {
          if (error) {
            this.errorHandler.handleError(error);
          }

          const faultAnalysis: FaultAnalysisDto = {
            machines: [],
            shutdowns: [],
            warnings: [],
            faultHistory: [],
          };

          return of(faultAnalysis);
        })
      )
      .subscribe((faultAnalysis: FaultAnalysisDto) => {
        const faultyMachines = faultAnalysis.machines.map(({ name, id: value }) => ({ name, value }));
        const shutdowns = [...faultAnalysis.shutdowns].sort(FaultComparator.by('totalPending', 'desc'));
        const warnings = [...faultAnalysis.warnings].sort(FaultComparator.by('totalPending', 'desc'));

        patchState({ ...faultAnalysis, faultyMachines, shutdowns, warnings, faultHistory: faultAnalysis.faultHistory });
      });
  }

  @Action(ClearFaults)
  clearFaults({ patchState }: StateContext<FaultsStateModel>) {
    patchState({
      faultCodes: [],
      faultCodeGroups: [],
      shutdowns: [],
      warnings: [],
      faultHistory: [],
      selected: undefined,
    });
  }

  @Action(ResetFaultAnalysis)
  resetFaultAnalysis({ patchState }: StateContext<FaultsStateModel>) {
    patchState({
      shutdowns: [],
      warnings: [],
      faultHistory: [],
      selected: [],
    });
  }

  @Action(SetSelectedFaultAnalysisFaultCodes)
  setSelectedFaultCodes({ patchState }: StateContext<FaultsStateModel>, { faultCodes }: SetSelectedFaultAnalysisFaultCodes) {
    patchState({
      selected: faultCodes,
    });
  }

  @Action(SortAnnunciations)
  sortAnnunciations(
    { patchState, getState }: StateContext<FaultsStateModel>,
    { params: { columnName, sortOrder, annunciation } }: SortAnnunciations
  ) {
    const data = getState().warnings;

    if (data) {
      const sorted = this.sortFaults(data, sortOrder, columnName as SortOrder);

      patchState({
        [annunciation]: sorted,
      });
    }
  }

  private sortFaults(faults: Annunciation[], column: string, order: SortOrder): Annunciation[] {
    return [...faults].sort(FaultComparator.by(column, order));
  }
}
