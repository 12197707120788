import { CloseDialogButtonComponent, HeadingComponent, ImagesPreviewComponent } from '@shared/components';
import { MatDialogContent, MatDialogRef } from '@angular/material/dialog';
import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { RecommendationDto } from '@shared/models';
import { ButtonV2Module, CheckboxV2Module, MultiSelectV2Module, SelectOption, TextfieldV2Module } from '@gea/digital-ui-lib';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'gea-hrt-problem-solve-dialog',
  templateUrl: './solve-dialog.component.html',
  styleUrls: ['./solve-dialog.component.scss'],
  standalone: true,
  imports: [
    HeadingComponent,
    TranslateModule,
    CloseDialogButtonComponent,
    MatDialogContent,
    MultiSelectV2Module,
    CheckboxV2Module,
    TextfieldV2Module,
    ImagesPreviewComponent,
    ButtonV2Module,
  ],
})
export class ProblemSolveDialogComponent implements OnInit {
  constructor(readonly dialogRef: MatDialogRef<ProblemSolveDialogComponent>) {
    this.formGroup = new FormGroup({
      additionalNote: new FormControl(''),
      selectedRecommendations: new FormControl([]),
      images: new FormControl<(string | ArrayBuffer[])[]>([]),
    });
  }

  @Input({ required: true }) recommendations: RecommendationDto[] = [];

  formGroup: FormGroup;
  recommendationsOptions: SelectOption[] = [];
  selectedRecommendations: SelectOption[] = [];

  noneOfTheAbove = false;

  ngOnInit() {
    this.recommendations.forEach(({ id: value, actionName: name, status }) => {
      this.recommendationsOptions.push({ value, name });

      if (status === 2) {
        this.selectedRecommendations.push({ name, value });
      }
    });

    if (this.recommendationsOptions.length === 0) {
      this.noneOfTheAbove = true;
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }

  save() {
    const selectedRecommendations: SelectOption[] = this.formGroup.controls['selectedRecommendations'].value;

    if (selectedRecommendations) {
      const values = selectedRecommendations.map((option) => option.value);
      this.formGroup.controls['selectedRecommendations'].setValue(values);
    }

    if (this.dialogRef) {
      this.dialogRef.close(this.formGroup.value);
    }
  }

  toggleSelection(input: boolean) {
    this.noneOfTheAbove = input;
    if (this.noneOfTheAbove) {
      this.selectedRecommendations = [];
    }
  }
}
