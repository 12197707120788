import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';

import { Shutdown, Warning } from '@shared/models';
import {
  ClearFaults,
  FaultsState,
  FetchFaultAnalysis,
  MachinesState,
  ResetFaultAnalysis,
  SetSelectedFaultAnalysisFaultCodes,
  SetSelectedMachinesForFaultAnalysis,
} from '@shared/state';
import { DateHelper } from '@shared/helpers';
import { FaultAnalysisSectionComponent } from './section';
import { FaultHistoryComponent } from './history';
import { FaultAnalysisFiltersComponent } from './filters/fault-analysis-filters.component';

@Component({
  selector: 'gea-hrt-fault-analysis',
  template: `
    <div class="fault-analysis-container">
      <gea-hrt-fault-filters
        (setMachines)="setSelectedMachines($event)"
        (setFaultCodes)="setSelectedFaultCodes($event)"
        (setDateRange)="setDateRange($event)"
        (setStatus)="setStatus($event)" />

      <gea-hrt-fault-analysis-section [annunciations$]="shutdowns$" type="shutdown" />
      <gea-hrt-fault-analysis-section [annunciations$]="warnings$" type="warning" />
      <gea-hrt-fault-history />
    </div>
  `,
  styleUrls: ['./fault-analysis.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [FaultAnalysisFiltersComponent, FaultAnalysisSectionComponent, FaultHistoryComponent],
})
export class FaultAnalysisComponent {
  @Select(FaultsState.shutdowns) shutdowns$!: Observable<Shutdown[] | undefined>;
  @Select(FaultsState.warnings) warnings$!: Observable<Warning[] | undefined>;

  constructor(private readonly store: Store) {}

  setSelectedMachines(machines: number[]) {
    const selectedMachines = this.store.selectSnapshot(MachinesState.selectedForFaultAnalyses) ?? [];

    if (JSON.stringify(selectedMachines) !== JSON.stringify(machines)) {
      this.store.dispatch(new ClearFaults());
      this.store.dispatch(new SetSelectedMachinesForFaultAnalysis(machines));
    }
  }

  setSelectedFaultCodes(faultCodes: number[]) {
    if (faultCodes.length) {
      this.store.dispatch(new SetSelectedFaultAnalysisFaultCodes(faultCodes));
      this.fetchFaultAnalysis();
    } else {
      this.store.dispatch(new ResetFaultAnalysis());
    }
  }

  setDateRange(dateRange: number) {
    this.fetchFaultAnalysis({ dateRange });
  }

  setStatus(status: string[]) {
    this.fetchFaultAnalysis({ status });
  }

  private fetchFaultAnalysis({ dateRange = 28, status = ['PENDING'] }: { dateRange?: number; status?: string[] } = {}) {
    const machines = this.store.selectSnapshot(MachinesState.selectedForFaultAnalyses) ?? [];
    const selectedFaultCodes = this.store.selectSnapshot(FaultsState.selected) ?? [];

    this.store.dispatch(
      new FetchFaultAnalysis({
        machines: machines.length ? machines : [0],
        dateRange: DateHelper.daysBack(dateRange),
        faultStatus: this.mapStatusToFaultCode(status),
        faults: selectedFaultCodes.length ? selectedFaultCodes : [0],
      })
    );
  }

  private mapStatusToFaultCode(status: string[]): string[] {
    return status.map((s) => (s === 'PENDING' ? '0' : '1'));
  }
}
