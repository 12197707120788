import { AsyncPipe, NgIf } from '@angular/common';
import { Observable } from 'rxjs';
import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { FiltersBaseComponentComponent, SpinnerComponent } from '@app/shared/components';
import { AnnunciationsMetaData, FaultCatalog } from '@app/shared/models';
import { AutocompleteSelectV2Module, CheckboxV2Module, LayoutService, SelectOption } from '@gea/digital-ui-lib';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'gea-hrt-fault-recommendations-filters',
  templateUrl: './fault-recommendations-filters.component.html',
  styleUrl: './fault-recommendations-filters.component.scss',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgIf, AutocompleteSelectV2Module, AsyncPipe, CheckboxV2Module, SpinnerComponent, TranslateModule],
})
export class FaultRecommendationsFiltersComponent extends FiltersBaseComponentComponent {
  faultCodes: SelectOption[] = [];

  @Input({ required: true }) set faultCatalog(catalog: FaultCatalog | null) {
    if (!catalog) {
      return;
    }

    this.faultCodes = catalog.map(({ id: value, name }) => ({ value, name }));
  }

  @Input({ required: true }) set hasRecommendations(value: boolean | undefined) {
    if (value === undefined) {
      return;
    }

    this.formGroup.controls['hasRecommendations'].patchValue(value);
  }

  @Input({ required: true }) selectedFaultName?: string;
  @Input({ required: true }) annunciationsMetadata$!: Observable<AnnunciationsMetaData | undefined>;

  @Output() setSelectedFault = new EventEmitter<SelectOption<number>>();
  @Output() setHasRecommendations = new EventEmitter<boolean>();

  formGroup = new FormGroup({
    fault: new FormControl(),
    hasRecommendations: new FormControl(false),
  });

  constructor(
    public layout: LayoutService,
    public translate: TranslateService
  ) {
    super(layout, translate);
  }
}
