<div class="tab-container-header">
  <div class="tab-name flex">
    <h3>{{ activeTab.label | translate }}</h3>
    <gea-hrt-reset-demo-account *ngIf="shouldRenderResetDemoAccountButton" />
  </div>

  <div class="flex">
    <a href="mailto:Blu-Red.DOC.Support@gea.com?subject=Regarding BLU-RED-Care:">
      <geaui-icon-v3 file="mail" class="icon-mail" />
      <span class="contact-text">{{ 'CONTACT_TEXT' | translate }}</span>
    </a>

    <div *ngIf="organization" class="selected-customer">
      <h3>{{ organization.name }}</h3>
    </div>

    <geaui-button-v2 *ngIf="shouldRenderCustomerOverviewButton" (click)="openCustomerOverview.emit()">
      {{ 'BUTTON_OPEN_CUSTOMER_OVERVIEW' | translate }}
    </geaui-button-v2>
  </div>
</div>
