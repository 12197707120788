import { Component } from '@angular/core';
import { ColumnRendererComponentV3 } from '@gea/digital-ui-lib';
import { Organization } from '@shared/models';
import { FaultCardComponent } from '@customer-overview/table-config/renderer/fault-cards-renderer/fault-card/fault-card.component';

@Component({
  selector: 'gea-hrt-fault-cards',
  standalone: true,
  imports: [FaultCardComponent],
  template: `<section>
    <div>
      <gea-hrt-fault-card [cardType]="'shutdown'" [displayNumber]="rowData?.countShutdowns ?? 0" />
    </div>
    <div>
      <gea-hrt-fault-card [cardType]="'warning'" [displayNumber]="rowData?.countWarnings ?? 0" />
    </div>
  </section> `,
  styles: `
    section {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 5px 0;
      padding-left: 15px;
      margin: 0;

      div {
        margin-right: 15px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  `,
})
export class FaultCardsRenderComponent implements ColumnRendererComponentV3<Organization> {
  rowData?: Organization;
}
