import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IconV3Component } from '@gea/digital-ui-lib';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

@Component({
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'gea-hrt-empty-data-placeholder',
  imports: [IconV3Component, TranslateModule],
  template: `
    <div class="empty-data-placeholder">
      <p>{{ 'EMPTY_DATA_PLACEHOLDER' | translate: { text: text } }}</p>
      <geaui-icon-v3 file="thumbs-up" alt="SVG Icon" />
    </div>
  `,
  styles: `
    .empty-data-placeholder {
      text-align: center;
      p {
        font-size: 1.5rem;
      }
      img {
        height: 5vh;
      }
    }
  `,
})
export class EmptyDataPlaceholderComponent {
  @Input({ required: true }) text!: string;

  constructor(readonly translate: TranslateService) {}
}
