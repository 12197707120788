import { NgIf, AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { SpinnerComponent, UpdateTimeComponent } from '@app/shared/components';
import { MachinesState, SystemHealthState } from '@app/shared/state';
import { SelectOption } from '@gea/digital-ui-lib';
import { TranslateModule } from '@ngx-translate/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'gea-hrt-system-health-last-check-time',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgIf, AsyncPipe, UpdateTimeComponent, TranslateModule, SpinnerComponent],
  template: `
    <div class="section">
      <p class="last-reloaded">
        {{ 'SYSTEM_HEALTH.DATA_EXTRACTED_ON' | translate }}:
        <ng-container *ngIf="(selectedMachine$ | async)?.length">
          <ng-container *ngIf="lastHealthCheckTime$ | async as lastHealthCheckTime; else loading">
            <gea-hrt-update-time [date]="lastHealthCheckTime" />
          </ng-container>
        </ng-container>
      </p>
    </div>

    <ng-template #loading>
      <gea-hrt-spinner />
    </ng-template>
  `,
  styles: `
    .last-reloaded {
      color: var(--geaui-vibrant-blue);
    }
  `,
})
export class SystemHealthLastCheckTimeComponent {
  @Select(SystemHealthState.lastHealthCheckTime) lastHealthCheckTime$!: Observable<string | undefined>;
  @Select(MachinesState.selected) selectedMachine$!: Observable<SelectOption<number>[] | undefined>;
}
