import { Organization, SortOrganizationsParams } from '@shared/models';

export class FetchOrganizations {
  static readonly type = '[CustomerOverview] Fetch Organizations';
}

export class SetSelectedOrganization {
  static readonly type = '[CustomerOverview] Set Selected Organization';
  constructor(public readonly organization: Organization | null) {}
}

export class SortOrganizations {
  static readonly type = '[CustomerOverview] Sort Organizations';
  constructor(public readonly params: SortOrganizationsParams) {}
}

export class FilterOrganizations {
  static readonly type = '[CustomerOverview] Filter Organizations';
  constructor(public readonly filterString: string) {}
}
