import { Permission, RecommendationDto } from '@shared/models';
import { ChangeDetectionStrategy, Component, Input, OnDestroy } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ButtonV2Module, IconV3Component, SnackbarService } from '@gea/digital-ui-lib';
import { NgIf } from '@angular/common';
import { Store } from '@ngxs/store';
import { DeleteRecommendation, UpdateRecommendation, ValidateRecommendation } from '@app/shared/state';
import { MatDialog } from '@angular/material/dialog';
import { DeleteRecommendationDialogComponent, RecommendationDialogComponent } from '@app/tab-container/fault-recommendations';
import { Subject } from 'rxjs';
import { takeUntil, catchError } from 'rxjs/operators';

interface RecommendationDialogData {
  actionName?: string;
  images: string[];
}

@Component({
  selector: 'gea-hrt-recommendation-editable-actions',
  templateUrl: './editable-actions.component.html',
  styleUrl: './editable-actions.component.scss',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ButtonV2Module, TranslateModule, IconV3Component, NgIf],
})
export class EditableActionsComponent implements OnDestroy {
  @Input({ required: true }) recommendation!: RecommendationDto;
  @Input() permission?: Permission;

  private readonly destroy$ = new Subject<void>();

  constructor(
    public readonly translate: TranslateService,
    private readonly store: Store,
    private readonly snackBar: SnackbarService,
    private readonly dialog: MatDialog
  ) {}

  updateRecommendation(recommendation: RecommendationDto) {
    if (!recommendation.actionName) {
      return;
    }

    this.store
      .dispatch(new UpdateRecommendation(recommendation))
      .pipe(
        takeUntil(this.destroy$),
        catchError(() => {
          this.snackBar.add({
            detail: 'ERROR.UPDATE_RECOMMENDATION_FAILED',
            summary: 'ERROR.UPDATE_RECOMMENDATION_FAILED',
            severity: 'error',
          });
          throw new Error('Update recommendation failed');
        })
      )
      .subscribe(() => {
        this.snackBar.add({
          detail: 'TAB_CONTAINER.FAULT_RECOMMENDATIONS.DIALOG.RECOMMENDATION_UPDATED_SUCCESSFULLY',
          summary: 'TAB_CONTAINER.FAULT_RECOMMENDATIONS.DIALOG.RECOMMENDATION_UPDATED_SUCCESSFULLY',
          severity: 'success',
        });
      });
  }

  validateRecommendation(recommendation: RecommendationDto) {
    const updatedRecommendation = { ...recommendation, validationCount: 2 };

    this.store
      .dispatch(new ValidateRecommendation(updatedRecommendation))
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          this.snackBar.add({
            detail: 'ERROR.VALIDATION_SUCCESSFUL',
            summary: 'ERROR.VALIDATION_SUCCESSFUL',
            severity: 'error',
          });
        },
        error: () => {
          this.snackBar.add({
            detail: 'ERROR.VALIDATION_FAILED',
            summary: 'ERROR.VALIDATION_FAILED',
            severity: 'error',
          });
        },
      });
  }

  openDeleteRecommendationDialog(recommendation: RecommendationDto) {
    const dialogRef = this.dialog.open(DeleteRecommendationDialogComponent, {
      minWidth: '50vw',
      minHeight: '50vh',
      autoFocus: true,
      data: { recommendation },
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.confirmDeleteRecommendation(recommendation);
        }
      });
  }

  openEditRecommendationDialog(recommendation: RecommendationDto) {
    const dialogRef = this.dialog.open(RecommendationDialogComponent, {
      minWidth: '50vw',
      minHeight: '50vh',
      autoFocus: true,
      data: { recommendation },
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: RecommendationDialogData | undefined) => {
        if (data?.actionName) {
          const updatedRecommendation = {
            ...recommendation,
            actionName: data.actionName,
            images: recommendation.images !== data.images ? data.images : recommendation.images,
          };

          this.updateRecommendation(updatedRecommendation);
        }
      });
  }

  confirmDeleteRecommendation({ id }: RecommendationDto) {
    this.store
      .dispatch(new DeleteRecommendation(id))
      .pipe(
        takeUntil(this.destroy$),
        catchError(() => {
          this.snackBar.add({
            detail: 'ERROR.DELETE_RECOMMENDATION_FAILED',
            summary: 'ERROR.DELETE_RECOMMENDATION_FAILED',
            severity: 'error',
          });
          throw new Error('Delete recommendation failed');
        })
      )
      .subscribe(() => {
        this.snackBar.add({
          detail: 'TAB_CONTAINER.FAULT_RECOMMENDATIONS.DELETE_DIALOG.RECOMMENDATION_DELETED_SUCCESSFULLY',
          summary: 'TAB_CONTAINER.FAULT_RECOMMENDATIONS.DELETE_DIALOG.RECOMMENDATION_DELETED_SUCCESSFULLY',
          severity: 'success',
        });
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
