import { ChangeDetectionStrategy, Component, effect, OnInit, signal } from '@angular/core';
import { Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { GetHealthCheck, MachinesState, SetSelectedMachines } from '@shared/state';
import { SystemHealthComponentListComponent } from './component-list/component-list.component';
import { SystemHealthLastCheckTimeComponent } from './last-check-time/last-check-time.component';
import { SystemHealthFiltersComponent } from './filters/';
import { Observable } from 'rxjs';

@Component({
  selector: 'gea-hrt-system-health',
  styleUrls: ['./system-health.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [SystemHealthFiltersComponent, SystemHealthLastCheckTimeComponent, SystemHealthComponentListComponent],
  template: `
    <div class="system-health-container">
      <gea-hrt-health-filters [selectedMachine]="selectedMachine()" (setSelectedMachine)="fetchData($event.value)" />
      <gea-hrt-system-health-last-check-time />
      <gea-hrt-system-health-component-list [selectedMachine]="selectedMachine()" />
    </div>
  `,
})
export class SystemHealthComponent implements OnInit {
  @Select(MachinesState.selectedMachine) selectedMachine$!: Observable<number | undefined>;

  selectedMachine = signal<number | undefined>(undefined);

  constructor(
    private readonly store: Store,
    private readonly router: Router
  ) {
    effect(() => {
      const machine = this.selectedMachine();

      if (machine !== undefined && machine !== null) {
        this.fetchData(machine);
      }
    });
  }

  ngOnInit() {
    this.selectedMachine$.subscribe(this.selectedMachine.set);
  }

  private setQueryParams(queryParams: { machine?: number }) {
    this.router.navigate([], { queryParams, queryParamsHandling: 'merge' }).catch(() => {
      console.warn('Query parameter could not be set');
    });
  }

  fetchData(machine?: number | null) {
    if (!machine) {
      return;
    }

    this.store.dispatch(new SetSelectedMachines([machine]));
    this.store.dispatch(new GetHealthCheck({ machine }));
    this.setQueryParams({ machine });
  }
}
