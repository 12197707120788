import { NgFor } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ToggleV2Module } from '@gea/digital-ui-lib';

@Component({
  selector: 'gea-hrt-related-opd-toggle',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgFor, ToggleV2Module],
  template: `
    <div class="hrt-toggle-container">
      <div *ngFor="let param of switches; let i = index; trackBy: trackByIndex" class="hrt-toggle-item">
        <span>
          {{ param[1] }}
        </span>
        <geaui-toggle-v2 (toggled)="toggleSwitch(param[0])" [initialValue]="param[2]" />
      </div>
    </div>
  `,
  styles: `
    .hrt-toggle-container {
      .hrt-toggle-item {
        display: block;
        float: left;
        display: flex;
        gap: 8px;
        margin-left: 24px;
        label: {
          cursor: pointer;
        }
      }
    }

    ::ng-deep .hrt-toggle-container .geaui-toggle-container {
      margin-bottom: 10px;
    }
  `,
})
export class RelatedOperatingDataTogglesComponent {
  selection: string[] = [];

  @Output() updateSelection = new EventEmitter<string[]>();

  @Input({ required: true }) switches!: [string, string, boolean][];

  @Input({ required: true }) set defaultSelection(value: string[]) {
    this.selection = value;
  }

  trackByIndex(_: number, item: [string, string, boolean]): string {
    return item[0];
  }

  toggleSwitch(key: string) {
    const { selection } = this;
    const index = selection.indexOf(key);

    index === -1 ? selection.push(key) : selection.splice(index, 1);

    this.updateSelection.emit(selection);
  }
}
