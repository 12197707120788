export class FetchMachines {
  static readonly type = '[FaultAnalysis] Fetch Machines';
}

export class SetSelectedMachines {
  static readonly type = '[FaultAnalysis] Set Selected Machines';
  constructor(public machines: number[]) {}
}

export class SetSelectedMachinesForFaultAnalysis {
  static readonly type = '[FaultAnalysis] Set Selected Machines for fault analysis';
  constructor(public machines: number[]) {}
}

export class ClearMachines {
  static readonly type = '[FaultAnalysis] Clear Machines';
}

export class SetMachineOverviewData {
  static readonly type = '[FaultAnalysis] Set Machine Overview Data';
}
