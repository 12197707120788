import { ConfirmationDialogComponent } from './../../confirmation-dialog/confirmation-dialog.component';
import { AfterContentInit, Component, ChangeDetectionStrategy, Input, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { MachineOverviewModel, MachineOverviewModelMachine } from '@shared/models';
import { ColumnRendererComponentV3, TableServiceV3 } from '@gea/digital-ui-lib';
import { UPDATE_MACHINE } from '@tab-container/machine-overview/table-config/machine-column-definitions.config';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { Subject, takeUntil } from 'rxjs';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'gea-hrt-edit-machine-renderer',
  template: `
    <div class="edit-machine-container">
      <input
        [(ngModel)]="value"
        (change)="showConfirmationDialog()"
        type="text"
        aria-label="Edit machine data"
        class="edit-machine-input" />
    </div>
  `,
  styleUrls: ['./edit-machine-renderer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [FormsModule, MatDialogModule],
  standalone: true,
})
export class EditMachineRendererComponent
  implements AfterContentInit, OnDestroy, ColumnRendererComponentV3<MachineOverviewModel>
{
  private readonly ngDestroyed$ = new Subject<void>();

  @Input() rowData!: MachineOverviewModel;
  @Input() tableId!: string;
  @Input() columnKey!: keyof MachineOverviewModel;
  @Input() config?: { type: string; key: keyof MachineOverviewModelMachine };

  initialValue = '';
  value = '';

  constructor(
    private readonly tableService: TableServiceV3,
    readonly dialog: MatDialog,
    readonly cdr: ChangeDetectorRef
  ) {}

  ngAfterContentInit() {
    if (!this.tableId) {
      throw new Error('EditMachineRendererComponent: tableId is undefined');
    }
    if (!this.rowData) {
      throw new Error('EditMachineRendererComponent: rowData is undefined');
    }
    if (!this.columnKey) {
      throw new Error('EditMachineRendererComponent: columnKey is undefined');
    }

    this.initialValue = this.getInitialValue();
    this.value = this.initialValue;
  }

  ngOnDestroy() {
    this.ngDestroyed$.next();
    this.ngDestroyed$.complete();
  }

  private getInitialValue(): string {
    const key = this.columnKey.split('.')[1] as unknown as keyof MachineOverviewModelMachine;

    if (key) {
      return this.rowData.machine[key] as string;
    }

    return this.rowData[this.columnKey] as string;
  }

  save() {
    const { config, rowData, columnKey, value } = this;
    const updatedRow = config
      ? {
          ...rowData,
          machine: {
            ...rowData.machine,
            name: value,
          },
        }
      : {
          ...rowData,
          [columnKey]: value,
        };

    this.tableService.publishAction(this.tableId, UPDATE_MACHINE, updatedRow);
    this.initialValue = this.value;
    this.cdr.markForCheck();
  }

  discard() {
    this.value = this.initialValue;
    this.cdr.markForCheck();
  }

  showConfirmationDialog() {
    this.dialog
      .open(ConfirmationDialogComponent, {
        autoFocus: true,
      })
      .afterClosed()
      .pipe(takeUntil(this.ngDestroyed$))
      .subscribe((confirm) => {
        if (confirm) {
          this.save();
        } else {
          this.discard();
        }
        this.cdr.markForCheck();
      });
  }
}
