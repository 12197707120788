import { Component } from '@angular/core';

import { ColumnRendererComponentV3 } from '@gea/digital-ui-lib';
import { Shutdown, Warning } from '@shared/models';

interface ProgressBarConfig {
  barHeight: number;
  countColor: string;
  totalCountColor: string;
}

@Component({
  selector: 'gea-hrt-progress-bar-renderer',
  standalone: true,
  template: `<div class="progressbar-wrapper">
    <span
      class="progressbar-count"
      [style.backgroundColor]="config.countColor"
      [style.height.px]="config.barHeight"
      [style.width.%]="getCountWidth()"
      style="position: inherit">
    </span>
    <span
      class="progressbar-total-count"
      [style.backgroundColor]="config.totalCountColor"
      [style.height.px]="config.barHeight"
      [style.width.%]="getTotalCountWidth()"
      style="position: inherit">
    </span>
  </div> `,
  styles: `
    .progressbar-wrapper {
      display: flex;
      position: relative;
      flex-direction: row;
      .progressbar-total-count {
        width: calc(100% - 50px);
        display: inline-block;
      }
      .progressbar-count {
        display: inline-block;
      }
    }
  `,
})
export class ProgressBarRendererComponent implements ColumnRendererComponentV3<Warning | Shutdown> {
  rowData?: Warning | Shutdown;
  columnKey = '';
  config: ProgressBarConfig = {
    barHeight: 20,
    countColor: 'var(--geaui-yellow)',
    totalCountColor: 'var(--geaui-light-gray)',
  };

  getCountWidth(): number {
    const count = this.extractCount();
    return count * 70; // 100% - 30px
  }

  getTotalCountWidth(): number {
    const count = this.extractCount();
    return (1 - count) * 70; // 100% - 30px
  }

  private extractCount() {
    if (!this.rowData) return 0;

    const count = Number(this.rowData[this.columnKey]);

    if (isNaN(count)) return 0.01;
    return count;
  }
}
