import { MsalService, MsalBroadcastService } from '@azure/msal-angular';
import { MessageService, ConfirmationService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { environment } from './../environments/environment';
import { Provider } from '@angular/core';

export const APP_PROVIDERS: Provider[] = [
  {
    provide: 'storageURL',
    useValue: 'https://strgaccappassetsdev.blob.core.windows.net/strg-container-i18n-dev/',
  },
  {
    provide: 'hrtBaseUrl',
    useValue: environment.hrt.baseURL || environment.baseURL,
  },
  {
    provide: 'isAdminInLocal',
    useFactory: () => environment.name === 'local' && (environment.isAdminInLocal || false),
  },
  {
    provide: 'hrtFaultDatabaseBaseUrl',
    useValue: environment.hrtFaultdatabase?.baseUrl || '',
  },
  {
    provide: 'hrtFaultDatabaseSubscriptionKey',
    useValue: environment.hrtFaultdatabase?.subscriptionKey || '',
  },
  { provide: 'baseUrl', useValue: environment.baseURL },
  { provide: 'hrtSubscriptionKey', useValue: environment.hrt.subscriptionKey },
  { provide: 'subscriptionKey', useValue: environment.subscriptionKey },
  {
    provide: 'iconPath',
    useValue: 'https://content.apps.tst.gea.com/strg-container-icons-test/',
  },
  {
    provide: 'packageVersion',
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    useFactory: () => require('../../package.json').version,
  },
  MsalService,
  MsalBroadcastService,
  MessageService,
  ConfirmationService,
  DialogService,
];
