<div class="readonly-actions">
  <ng-container [ngSwitch]="recommendation.status">
    <span *ngSwitchCase="Status.SKIPPED" class="label variant-skipped" data-testid="skipped-label">
      {{ 'TROUBLESHOOTING.RECOMMENDATIONS.LABEL_SKIPPED' | translate }}
    </span>
    <span *ngSwitchCase="Status.DONE" class="label variant-done" data-testid="done-label">
      {{ 'TROUBLESHOOTING.RECOMMENDATIONS.LABEL_DONE' | translate }}
    </span>
    <geaui-button-v2
      *ngSwitchCase="Status.PENDING"
      type="secondary"
      data-testid="btn-skip-task"
      [loading]="loading()"
      (click)="updateStatus(Status.SKIPPED)">
      {{ 'TROUBLESHOOTING.RECOMMENDATIONS.BUTTON_SKIP_TASK' | translate }}
    </geaui-button-v2>
  </ng-container>

  <ng-container *ngIf="recommendation.status === Status.PENDING; else resetButton">
    <geaui-button-v2 type="secondary" data-testid="btn-done" [loading]="loading()" (click)="updateStatus(Status.DONE)">
      {{ 'TROUBLESHOOTING.RECOMMENDATIONS.BUTTON_DONE' | translate }}
    </geaui-button-v2>
  </ng-container>
</div>

<ng-template #resetButton>
  <geaui-icon-v3
    class="reset"
    title="{{ 'TROUBLESHOOTING.RECOMMENDATIONS.BUTTON_RESET' | translate }}"
    file="reload"
    data-testid="btn-reset"
    (click)="updateStatus(Status.PENDING)" />
</ng-template>
