import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatDialogContent, MatDialogRef } from '@angular/material/dialog';
import { CloseDialogButtonComponent, HeadingComponent } from '@app/shared/components';
import { ButtonV2Module } from '@gea/digital-ui-lib';
import { TranslateModule } from '@ngx-translate/core';
@Component({
  selector: 'gea-hrt-machine-overview-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrl: './confirmation-dialog.component.scss',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [TranslateModule, HeadingComponent, MatDialogContent, ButtonV2Module, CloseDialogButtonComponent],
})
export class ConfirmationDialogComponent {
  private readonly dialogRef = inject(MatDialogRef<ConfirmationDialogComponent>);

  confirm(): void {
    this.dialogRef.close(true);
  }

  dismiss(): void {
    this.dialogRef.close();
  }
}
