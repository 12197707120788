import { FetchFaultCodesParams, FetchFaultAnalysisParams, SortAnnunciationsParams } from '@app/shared/models';

export class FetchFaultCodes {
  static readonly type = '[FaultAnalysis] Fetch Fault Codes';
  constructor(public readonly params: FetchFaultCodesParams) {}
}

export class FetchFaultAnalysis {
  static readonly type = '[FaultAnalysis] Fetch Faults';
  constructor(public readonly params: FetchFaultAnalysisParams) {}
}

export class ClearFaults {
  static readonly type = '[FaultAnalysis] Clear Faults';
}

export class ResetFaultAnalysis {
  static readonly type = '[FaultAnalysis] Clear Shutdowns and Warnings';
}

export class SetSelectedFaultAnalysisFaultCodes {
  static readonly type = '[FaultAnalysis] Set Selected Fault Codes';
  constructor(public readonly faultCodes: number[]) {}
}

export class SortAnnunciations {
  static readonly type = '[CustomerOverview] Sort Annunciations';
  constructor(public readonly params: SortAnnunciationsParams) {}
}
