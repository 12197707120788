import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { CloseDialogButtonComponent, HeadingComponent } from '@app/shared/components';
import { RecommendationDto } from '@app/shared/models';
import { ButtonV2Module } from '@gea/digital-ui-lib';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  standalone: true,
  imports: [HeadingComponent, CloseDialogButtonComponent, MatDialogModule, ButtonV2Module, TranslateModule],
  selector: 'gea-hrt-delete-recommendation-dialog',
  templateUrl: './delete-dialog.component.html',
  styleUrls: ['./delete-dialog.component.scss'],
})
export class DeleteRecommendationDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<DeleteRecommendationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data?: { recommendation?: RecommendationDto }
  ) {}

  commit() {
    this.dialogRef.close(this.data?.recommendation);
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
