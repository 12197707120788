import { MatIconRegistry } from '@angular/material/icon';
import { Component, Inject, OnInit, OnDestroy, PLATFORM_ID } from '@angular/core';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { Store, Select } from '@ngxs/store';
import { HeaderModule, ImageService, SetEnvironment, UserModel, UserState } from '@gea/digital-ui-lib';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '@environments/environment';
import { isPlatformBrowser, NgClass, NgIf } from '@angular/common';
import { combineLatestWith, Observable, Subject, takeUntil } from 'rxjs';
import { JwtPayload, jwtDecode } from 'jwt-decode';
import { SpinnerComponent } from './shared/components';
import { RouterModule } from '@angular/router';
import { FetchMemberships } from './shared/state';

interface CustomJwtPayload extends JwtPayload {
  extension_user_context?: string;
}

@Component({
  selector: 'gea-hrt-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [NgClass, HeaderModule, SpinnerComponent, NgIf, RouterModule],
})
export class AppComponent implements OnInit, OnDestroy {
  contentReady = false;
  noAuth = true;
  firefox = false;
  private readonly destroy$ = new Subject<void>();

  @Select(UserState.user) user$!: Observable<UserModel>;
  @Select(UserState.token) token$!: Observable<string>;

  constructor(
    @Inject(PLATFORM_ID) private readonly platformId: Record<string, string | number | boolean>,
    private readonly translate: TranslateService,
    private readonly store: Store,
    readonly title: Title,
    readonly iconRegistry: MatIconRegistry,
    readonly imageService: ImageService,
    readonly domSanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.firefox = navigator.userAgent.toLowerCase().includes('firefox');
    }

    this.initUserSubscriptions();

    if (environment.name) {
      this.store.dispatch(new SetEnvironment(environment.name));
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private initUserSubscriptions(): void {
    this.user$.pipe(combineLatestWith(this.token$), takeUntil(this.destroy$)).subscribe(([user, token]) => {
      if (user?.language) {
        this.translate.use(user.language);
      }

      if (isPlatformBrowser(this.platformId)) {
        this.setGlobalUserContext(user?.userId);
      }

      this.contentReady = !!token;
      if (this.contentReady) {
        this.extractExtensionUserContext(token);
        this.store.dispatch(new FetchMemberships());
      }
    });
  }

  private setGlobalUserContext(userId?: string): void {
    if (userId) {
      window.userId = userId;
    }
  }

  private extractExtensionUserContext(token: string): void {
    if (!token || typeof token !== 'string' || !token.trim()) {
      return;
    }

    try {
      const decodedToken: CustomJwtPayload = jwtDecode(token);
      const extensionUserContext = decodedToken.extension_user_context ?? null;

      if (extensionUserContext && isPlatformBrowser(this.platformId)) {
        window.userType = extensionUserContext;
      }
    } catch (error) {
      console.warn('Error decoding token:', error);
    }
  }
}
