<gea-hrt-info-box
  boxTitle="{{ 'FAULT_RECOMMENDATIONS.SOLVED_FAULTS.TITLE' | translate }}"
  tooltip="{{ 'FAULT_RECOMMENDATIONS.SOLVED_FAULTS.TOOLTIP' | translate }}"
  [withBorder]="true">
  <div class="table-container-section">
    <ng-container *ngIf="parsedData; else loading">
      <geaui-table-v3
        *ngIf="parsedData.length; else noData"
        class="table"
        [tableId]="TABLE_ID"
        [columnDefinitions]="columnDefinitions"
        [sortable]="true"
        [data]="parsedData" />
    </ng-container>
  </div>
</gea-hrt-info-box>

<ng-template #loading>
  <gea-hrt-spinner />
</ng-template>

<ng-template #noData>
  <gea-hrt-hint>{{ 'FAULT_ANALYSIS.HISTORY.NO_DATA_AVAILABLE' | translate }}</gea-hrt-hint>
</ng-template>
