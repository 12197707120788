import { NgFor } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RecommendationDto } from '@shared/models';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Store } from '@ngxs/store';
import { ReorderRecommendations, ValidateRecommendation } from '@shared/state';
import { ButtonV2Module, SnackbarService } from '@gea/digital-ui-lib';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MatList, MatListItem, MatListModule } from '@angular/material/list';
import { RecommendationItemComponent } from '@app/tab-container/troubleshooting';

@Component({
  selector: 'gea-hrt-recommendation-order-list',
  templateUrl: './recommendation-order-list.component.html',
  styleUrls: ['./recommendation-order-list.component.scss'],
  standalone: true,
  imports: [NgFor, MatListModule, RecommendationItemComponent, ButtonV2Module, TranslateModule, MatList, MatListItem],
})
export class RecommendationOrderListComponent implements OnInit {
  @Input() list: RecommendationDto[] = [];

  @Output() orderFinished = new EventEmitter<void>();

  listForOrdering: RecommendationDto[] = [];

  constructor(
    readonly store: Store,
    readonly snackBar: SnackbarService,
    readonly translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.listForOrdering = [...this.list];
  }

  drop(temp: Event) {
    // TODO
    const event = temp as unknown as CdkDragDrop<RecommendationDto[]>;
    moveItemInArray(this.listForOrdering, event.previousIndex, event.currentIndex);
  }

  save() {
    this.store.dispatch(new ReorderRecommendations(this.listForOrdering));
    this.snackBar.add({
      detail: this.translate.instant('TAB_CONTAINER.FAULT_RECOMMENDATIONS.RECOMMENDATION_ORDER_SUCCESS'),
      summary: this.translate.instant('TAB_CONTAINER.FAULT_RECOMMENDATIONS.RECOMMENDATION_ORDER_SUCCESS'),
      severity: 'success',
    });
    this.orderFinished.emit();
  }

  cancel() {
    this.orderFinished.emit();
  }

  trackById(_: number, item: RecommendationDto) {
    return item.id;
  }

  validateRecommendation(recommendation: RecommendationDto) {
    this.store.dispatch(new ValidateRecommendation({ ...recommendation, validationCount: 2 })).subscribe(() => {
      this.snackBar.add({
        detail: 'FAULT_RECOMMENDATIONS.VALIDATION_SUCCESS_TITLE',
        summary: 'FAULT_RECOMMENDATIONS.VALIDATION_SUCCESS_SUMMARY',
        severity: 'success',
      });
    });
  }
}
