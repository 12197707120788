import { Router } from '@angular/router';
import { Component, DestroyRef, OnInit } from '@angular/core';

import { TranslateModule, TranslateService } from '@ngx-translate/core';
import {
  ColumnDefinitionV3,
  FilterTableSettings,
  IconV3Component,
  SearchBarV2Module,
  TableServiceV3,
  TableV3Component,
} from '@gea/digital-ui-lib';
import { skipWhile, tap } from 'rxjs';
import { Store } from '@ngxs/store';

import { Organization } from '@shared/models';
import { FilterOrganizations, OrganizationsState } from '@shared/state';
import { customerOverviewColumnDefinitions } from '@customer-overview/table-config/customer-overview-column-definitions.config';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NgIf } from '@angular/common';
import { SpinnerComponent, EmptyDataPlaceholderComponent } from '@app/shared/components';
import { TooltipModule } from 'primeng/tooltip';

@Component({
  selector: 'gea-hrt-customer-overview',
  standalone: true,
  imports: [
    NgIf,
    SearchBarV2Module,
    IconV3Component,
    TableV3Component,
    SpinnerComponent,
    TooltipModule,
    EmptyDataPlaceholderComponent,
    TranslateModule,
  ],
  templateUrl: './customer-overview.component.html',
  styleUrls: ['./customer-overview.component.scss'],
})
export class CustomerOverviewComponent implements OnInit {
  public loading = true;
  public columns: ColumnDefinitionV3[] = customerOverviewColumnDefinitions;
  public organizations?: Organization[];
  public readonly TABLE_ID = 'customer-overview-table';

  isAcceptDisabled?: boolean;
  onAcceptClick?: () => void;
  onRejectClick?: () => void;

  constructor(
    readonly router: Router,
    readonly translate: TranslateService,
    readonly store: Store,
    readonly tableService: TableServiceV3,
    readonly destroyRef: DestroyRef
  ) {}

  ngOnInit(): void {
    this.store
      .select(OrganizationsState.filteredOrganizations)
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        skipWhile((data) => data === undefined || data === null),
        tap(() => {
          this.loading = false;
        })
      )
      .subscribe((organizations) => {
        this.organizations = organizations;
        this.loading = false;
      });

    this.tableService
      .getFilterTableSettings(this.TABLE_ID)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((tableFilter) => {
        this.sortOrganizations(tableFilter);
      });

    this.onSearchInputChange('');
  }

  onSearchInputChange($event: string) {
    this.store.dispatch(new FilterOrganizations($event));
  }

  sortOrganizations(tableFilter: FilterTableSettings) {
    const sortDirection = tableFilter.columns['name']?.sort;
    if (!sortDirection) return;

    if (this.organizations) {
      this.organizations = [...this.organizations].sort((a, b) =>
        sortDirection === 'asc' ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name)
      );
    }
  }
}
