import {
  AfterViewInit,
  Component,
  HostListener,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
  ElementRef,
  ChangeDetectionStrategy,
} from '@angular/core';

import { EChartsOption } from 'echarts';

import { cssVar } from '@shared/helpers';
import { formatDate } from '@angular/common';
import * as echarts from 'echarts';

export interface ChartSerie {
  name: string;
  color: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any[];
  legendLabel: string;
  yAxisName: string;
  yAxisPosition: 'left' | 'right';
  yAxisOffset: number;
  xValues: string[];
}

export interface ChartData {
  series: ChartSerie[];
}

const CHART_GRAY_COLOR = cssVar('--geaui-label-gray');

@Component({
  selector: 'gea-hrt-data-chart',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: ` <div class="chart-container" id="chart-container" #chart style="height: 600px; width: 100%"></div> `,
  styles: `
    ::ng-deep.echarts-fault-history-tooltip {
      background-color: black !important;
      color: white !important;
      * {
        color: white !important;
      }
    }
  `,
})
export class DataChartComponent implements OnChanges, AfterViewInit {
  @Input({ required: true })
  data!: ChartData;

  @ViewChild('chart')
  chartRef!: ElementRef<HTMLDivElement>;
  public chart!: echarts.EChartsType;

  options!: EChartsOption;

  ngOnChanges(changes: SimpleChanges) {
    this.setChartOptions(changes.data.currentValue as ChartData);
  }
  @HostListener('window:resize')
  onResize() {
    this.chart.resize();
  }
  setChartOptions(data: ChartData) {
    this.options = this.createChartOptions(data);

    // initially there is no echart instance available
    if (this.chartRef) {
      this.chart.setOption(this.options, { notMerge: true });
    }
  }
  ngAfterViewInit() {
    this.chart = echarts.init(this.chartRef.nativeElement);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
    this.chart.setOption(this.options);
  }
  createChartOptions(data: ChartData): EChartsOption {
    let leftYAxisDims = 0;
    let rightYAxisDims = 0;
    data.series.forEach(({ yAxisPosition }) => (yAxisPosition === 'left' ? leftYAxisDims++ : rightYAxisDims++));

    return {
      grid: {
        // dynamically calculate chart paddings (25px is the minimal padding -> it won't cut the values of last items)
        left: leftYAxisDims > 0 ? leftYAxisDims * 50 : 25,
        right: rightYAxisDims > 0 ? rightYAxisDims * 50 : 25,
        bottom: 150,
      },
      useUTC: true,
      tooltip: {
        className: 'echarts-fault-history-tooltip',
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          label: {
            formatter: (value) => {
              if (typeof value.value === 'number') {
                if (value.value > 10000) return formatDate(value.value, 'YYYY-MM-dd HH:mm:SS', 'en-US');

                return value.value.toFixed(2);
              }
              return value.value.toString();
            },
          },
        },
      },
      legend: {
        data: data.series.map(({ legendLabel }) => legendLabel),
        icon: 'circle',
        left: 'right',
        top: 'bottom',
      },
      dataZoom: [
        {
          show: true,
          bottom: 50,
        },
      ],
      xAxis: {
        type: 'time',
        axisLabel: {
          formatter: (value) => {
            return formatDate(value, 'YYYY-MM-dd HH:mm', 'en-US');
          },
        },
        splitLine: {
          show: true,
          lineStyle: {
            type: 'dashed',
            color: CHART_GRAY_COLOR,
          },
        },
        axisLine: {
          show: false,
          lineStyle: {
            color: CHART_GRAY_COLOR,
          },
        },
      },
      yAxis: data.series.map((serie) => {
        return {
          type: 'value',
          name: serie.yAxisName,
          position: serie.yAxisPosition,
          offset: serie.yAxisOffset,
          splitLine: {
            show: true,
            interval: 'auto',
            lineStyle: {
              type: 'dashed',
              color: CHART_GRAY_COLOR,
            },
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: serie.color,
            },
          },
        };
      }),
      series: data.series.map((serie, i) => ({
        name: serie.name,
        type: 'line',
        showSymbol: false,
        color: serie.color,
        yAxisIndex: i,
        data: serie.data,
      })),
    };
  }
}
