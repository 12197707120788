<div class="overview-container">
  @defer (when overviewLoaded()) {
    @if (overview()?.length) {
      <div class="customer-list">
        <ng-container *ngFor="let item of overview(); trackBy: trackByOverviewItem">
          <div class="customer-card" [attr.aria-label]="'Customer ' + item.name">
            <div class="customer-name">{{ item.name }}</div>
            <div class="metrics-container">
              <gea-hrt-metric-box
                title="OVERVIEW.WARNINGS"
                [pending]="item.warningsPending"
                [resolved]="item.warningsResolved"
                type="warnings" />
              <gea-hrt-metric-box
                title="OVERVIEW.SHUTDOWNS"
                [pending]="item.shutdownsPending"
                [resolved]="item.shutdownsResolved"
                type="shutdowns" />
            </div>
          </div>
        </ng-container>
      </div>
    } @else {
      <div class="empty-state" role="alert">
        {{ 'OVERVIEW.NO_DATA' | translate }}
      </div>
    }
  } @placeholder {
    <gea-hrt-spinner />
  } @error {
    <div class="error-state" role="alert">
      {{ 'OVERVIEW.ERROR_LOADING' | translate }}
    </div>
  }
</div>
