import { Observable } from 'rxjs';
import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { RecommendationDto } from '@shared/models';
import { RecommendationsListComponent } from './recommendations-list';
import { HeadingComponent, HintComponent, InfoBoxComponent, SpinnerComponent } from '@shared/components';
import { AsyncPipe, NgIf } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { TroubleshootingProblemSolveComponent } from '../problem-solve';
import { MachinesState, TroubleShootingState } from '@app/shared/state';
import { Select } from '@ngxs/store';

@Component({
  selector: 'gea-hrt-recommendations',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    InfoBoxComponent,
    NgIf,
    SpinnerComponent,
    TranslateModule,
    RecommendationsListComponent,
    HeadingComponent,
    TroubleshootingProblemSolveComponent,
    HintComponent,
    AsyncPipe,
  ],
  template: `
    <gea-hrt-info-box
      [boxTitle]="'TROUBLESHOOTING.RECOMMENDATIONS.TITLE' | translate"
      [tooltip]="'TROUBLESHOOTING.RECOMMENDATIONS.TOOLTIP' | translate">
      <ng-container *ngIf="hasSelectedFault$ | async; else noSelectedFault">
        <ng-container *ngIf="recommendations$ | async as recommendations; else loading">
          <ng-container *ngIf="recommendations?.length; else noRecommendations">
            <gea-hrt-heading *ngIf="recommendationText$ | async as recommendationText" class="recommendation-text" type="sub">
              {{ recommendationText }}
            </gea-hrt-heading>

            <p>{{ 'TROUBLESHOOTING.RECOMMENDATIONS.STEPS_MESSAGE' | translate }}</p>

            <gea-hrt-recommendations-list [list]="recommendations" [isEditable]="false" />

            <gea-hrt-troubleshooting-problem-solve [showText]="true" [list]="recommendations" />
          </ng-container>
        </ng-container>
      </ng-container>
    </gea-hrt-info-box>

    <ng-template #loading>
      <gea-hrt-spinner />
    </ng-template>

    <ng-template #noRecommendations>
      <gea-hrt-hint>{{ 'TROUBLESHOOTING.RECOMMENDATIONS.NO_DATA' | translate }}</gea-hrt-hint>
    </ng-template>

    <ng-template #noSelectedFault>
      <gea-hrt-hint>{{ 'TROUBLESHOOTING.RECOMMENDATIONS.NO_SELECTED_FAULT' | translate }}</gea-hrt-hint>
    </ng-template>
  `,
})
export class RecommendationsComponent {
  @Select(TroubleShootingState.recommendationText) recommendationText$!: Observable<string | undefined>;
  @Select(MachinesState.selectedMachine) selectedMachine$!: Observable<number | undefined>;
  @Select(TroubleShootingState.selectedFault) selectedFault$!: Observable<number | undefined>;

  @Input({ required: true }) recommendations$!: Observable<RecommendationDto[] | undefined>;

  readonly hasSelectedFault$ = new Observable<boolean>((subscriber) => {
    const subscription = this.selectedMachine$.subscribe((machine) => {
      this.selectedFault$.subscribe((fault) => {
        subscriber.next(!!machine && !!fault);
      });
    });

    return () => subscription.unsubscribe();
  });
}
