import { NgIf, AsyncPipe, NgFor } from '@angular/common';
import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { SystemHealthMachineComponent } from '@app/shared/models';
import { AccordionModule } from 'primeng/accordion';
import { Observable } from 'rxjs';
import { HealthMachineComponent } from '../machine-component';
import { SpinnerComponent } from '@app/shared/components';
import { Select } from '@ngxs/store';
import { OrganizationsState, SystemHealthState } from '@app/shared/state';

@Component({
  selector: 'gea-hrt-system-health-component-list',
  standalone: true,
  imports: [NgIf, AsyncPipe, NgFor, AccordionModule, HealthMachineComponent, SpinnerComponent],
  template: `
    <ng-container *ngIf="selectedMachine !== null && selectedMachine !== undefined">
      <ng-container *ngIf="organizationId$ | async as organizationId; else loading">
        <ng-container *ngIf="components$ | async as components; else loading">
          <p-accordion [activeIndex]="0">
            <ng-container *ngFor="let comp of components; trackBy: trackByComponentId">
              <gea-hrt-system-health-machine-component
                [component]="comp"
                [machineId]="selectedMachine"
                [organizationId]="organizationId" />
            </ng-container>
          </p-accordion>
        </ng-container>
      </ng-container>
    </ng-container>

    <ng-template #loading>
      <gea-hrt-spinner />
    </ng-template>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SystemHealthComponentListComponent {
  @Input({ required: true }) selectedMachine!: number | undefined | null;

  @Select(SystemHealthState.components) components$!: Observable<SystemHealthMachineComponent[] | undefined>;
  @Select(OrganizationsState.selectedId) organizationId$!: Observable<number | undefined>;

  trackByComponentId(index: number, component: SystemHealthMachineComponent) {
    return component.id ?? component.name ?? index;
  }
}
