export interface SystemHealthModel {
  lastHealthCheckTime: string;
  machine: SystemHealthMachine;
}

export interface SystemHealthConnectivity {
  component: string;
  signalStatus: SignalStatus;
}

export interface SignalStatus {
  lastSignal: Date;
  isAvailable: boolean;
  receivedPercentage: number;
}

export interface SystemHealthMachine {
  id: number | string;
  name: string;
  components?: SystemHealthMachineComponent[];
  status: MachineStatus;
}

export interface SystemHealthMachineComponent {
  id: number;
  name: string;
  healthChecks: SystemHealthCheck[];
  connectivity?: SystemHealthConnectivity;
  additionalProperties?: Record<string, number | string>;
}

export interface TimelineEntry {
  name: string;
  entries: { key: string; value: number }[];
}

export interface SystemHealthCheck {
  id: number;
  name: string;
  status: SystemHealthStatus;
  resolved: boolean;
  timestamp?: string;
  startTimestamp: string;
  endTimestamp: string;
  hints: string;
  healthStatusMetadata: Record<string, number | string>;
  additionalProperties?: Record<string, number | string>;
  timelineEntries: TimelineEntry[];
}

export enum SystemHealthStatus {
  INFO = -1,
  HEALTHY = 0,
  WARNING = 1,
  ERROR = 2,
}

export type MachineStatus = {
  healthy: number;
  warnings: number;
  critical: number;
};

export interface GetHealthCheckParams {
  machine: number;
  dateRange?: string;
}
