import { ChangeDetectionStrategy, Component, Input, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { NgFor } from '@angular/common';

import { RecommendationItemComponent } from './../recommendation-item/recommendation-item.component';
import { Permission, RecommendationDto, RecommendationStatus } from '@shared/models';
import { Store } from '@ngxs/store';
import { SnackbarService } from '@gea/digital-ui-lib';
import { UpdateRecommendation } from '@app/shared/state';

export type RecommendationAction = { recommendationId: number; recommendationStatus: RecommendationStatus };

@Component({
  selector: 'gea-hrt-recommendations-list',
  template: `
    <ng-container *ngFor="let item of list; let i = index; trackBy: trackByRecommendationId">
      <gea-hrt-recommendation-item [recommendation]="item" [permission]="permission" [index]="i" [isEditable]="isEditable" />
    </ng-container>
  `,
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [RecommendationItemComponent, NgFor],
})
export class RecommendationsListComponent implements OnDestroy {
  @Input() isEditable = true;
  @Input() list: RecommendationDto[] = [];
  @Input() permission?: Permission;
  @Input() loading?: boolean;

  private readonly subscriptions = new Subscription();

  constructor(
    private readonly dialog: MatDialog,
    private readonly store: Store,
    readonly snackBar: SnackbarService
  ) {}

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  trackByRecommendationId(index: number, item: RecommendationDto): number {
    return item.id ?? index;
  }

  updateRecommendation(recommendation: RecommendationDto) {
    if (recommendation.actionName === null) {
      return;
    }

    this.store.dispatch(new UpdateRecommendation(recommendation)).subscribe(() => {
      this.snackBar.add({
        detail: 'TAB_CONTAINER.FAULT_RECOMMENDATIONS.DIALOG.RECOMMENDATION_UPDATED_SUCCESSFULLY',
        summary: 'TAB_CONTAINER.FAULT_RECOMMENDATIONS.DIALOG.RECOMMENDATION_UPDATED_SUCCESSFULLY',
        severity: 'success',
      });
    });
  }
}
