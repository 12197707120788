import { Component } from '@angular/core';
import { UpdateTimeComponent } from '@app/shared/components';
import { Warning, Shutdown } from '@app/shared/models';
import { ColumnRendererComponentV3 } from '@gea/digital-ui-lib';

@Component({
  selector: 'gea-hrt-last-instance-renderer',
  standalone: true,
  imports: [UpdateTimeComponent],
  template: `
    @if (rowData) {
      <gea-hrt-update-time [date]="rowData.timeStamp" />
    }
  `,
})
export class LastInstanceRendererComponent implements ColumnRendererComponentV3<Warning | Shutdown> {
  rowData?: Warning | Shutdown;
}
