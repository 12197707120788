import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HrtApiService } from '../hrt-api/hrt-api.service';
import { OverviewItem } from '@shared/models/overview.model';

@Injectable({
  providedIn: 'root',
})
export class OverviewApiService {
  baseUrl = 'overview';

  constructor(readonly api: HrtApiService) {}

  fetchOverviewData(organizationId?: number): Observable<OverviewItem[]> {
    const params: { [param: string]: string | number | boolean } = {};
    if (organizationId !== undefined) {
      params.organizationId = organizationId;
    }
    return this.api.get<OverviewItem[]>(this.baseUrl, { params });
  }
}
