import { MetricBoxComponent } from './metric-box/metric-box.component';
import { Component, effect, inject, signal } from '@angular/core';
import { NgFor } from '@angular/common';
import { Store } from '@ngxs/store';
import { OverviewItem } from '@shared/models/overview.model';
import { FetchOverview, OverviewState, OrganizationsState } from '@shared/state';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { SpinnerComponent } from '@app/shared/components';
import { toSignal } from '@angular/core/rxjs-interop';
import { catchError, of } from 'rxjs';

@Component({
  selector: 'gea-hrt-overview',
  standalone: true,
  templateUrl: './overview.component.html',
  imports: [NgFor, TranslateModule, MetricBoxComponent, SpinnerComponent],
  styleUrls: ['./overview.component.scss'],
})
export class OverviewComponent {
  private readonly store = inject(Store);
  private readonly translate = inject(TranslateService);

  readonly overview = toSignal(this.store.select(OverviewState.overview).pipe(catchError(() => of([] as OverviewItem[]))), {
    initialValue: [],
  });
  readonly selectedOrg = toSignal(this.store.select(OrganizationsState.selected), { initialValue: undefined });
  readonly overviewLoaded = signal(false);

  constructor() {
    effect(
      () => {
        const selected = this.selectedOrg();
        if (selected?.organization) {
          this.overviewLoaded.set(false);
          this.store.dispatch(new FetchOverview(selected.organization.id)).subscribe({
            next: () => this.overviewLoaded.set(true),
            error: () => this.overviewLoaded.set(true),
          });
        }
      },
      { allowSignalWrites: true }
    );
  }

  trackByOverviewItem(_: number, item: OverviewItem): number {
    return item?.id ?? -1;
  }
}
