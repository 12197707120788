import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';

@Component({
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'gea-hrt-close-dialog-button',
  template: `<div class="close-dialog-button" data-testid="close-dialog-button" (click)="closeDialog.emit()"></div>`,
  styles: `
    .close-dialog-button {
      width: 24px;
      height: 24px;
      position: relative;
      cursor: pointer;
      user-select: none; /* Prevent text selection */
    }

    .close-dialog-button::before,
    .close-dialog-button::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: 2px;
      background-color: rgba(0, 0, 0, 0.7);
      transform-origin: center;
      user-select: none;
    }

    .close-dialog-button::before {
      transform: translate(-50%, -50%) rotate(45deg);
    }

    .close-dialog-button::after {
      transform: translate(-50%, -50%) rotate(-45deg);
    }

    .close-dialog-button:hover::before,
    .close-dialog-button:hover::after {
      background-color: rgba(0, 0, 0, 0.3);
    }
  `,
})
export class CloseDialogButtonComponent {
  @Output() closeDialog = new EventEmitter();
}
