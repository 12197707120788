import { Injectable } from '@angular/core';

import { Action, Selector, State, StateContext } from '@ngxs/store';
import { ApiErrorResponse, ErrorHandlerV2Service } from '@gea/digital-ui-lib';
import { catchError, of, tap } from 'rxjs';

import { RelatedOperatingDataApiService } from '@shared/services';
import { RelatedOperatingDataStateModel, MeasurementSerieDto } from '@shared/models';

import { CachedAction } from '@shared/helpers';

import { FetchRelatedOperatingDataProps } from '@app/shared/models';

export class FetchRelatedOperatingData {
  static readonly type = '[Troubleshooting] Fetch Related Operating Data';
  constructor(public readonly params: FetchRelatedOperatingDataProps) {}
}

@State<RelatedOperatingDataStateModel>({
  name: 'relatedOperatingData',
})
@Injectable()
export class RelatedOperatingDataState {
  @Selector()
  static measurements(state: RelatedOperatingDataStateModel): MeasurementSerieDto[] | undefined {
    return state.measurements;
  }

  constructor(
    readonly relatedOperatingDataApi: RelatedOperatingDataApiService,
    readonly errorHandler: ErrorHandlerV2Service
  ) {}

  @CachedAction(0)
  @Action(FetchRelatedOperatingData)
  fetchRelatedOperatingData(
    { patchState }: StateContext<RelatedOperatingDataStateModel>,
    { params: { machine, dateHours, pressureUnit, temperatureUnit } }: FetchRelatedOperatingData
  ) {
    return this.relatedOperatingDataApi.fetchRelatedOperatingData({ machine, dateHours, temperatureUnit, pressureUnit }).pipe(
      catchError((error: ApiErrorResponse) => {
        if (error?.headers) {
          this.errorHandler.handleError(error);
        }
        return of([]);
      }),
      tap((measurements) => {
        patchState({
          measurements,
        });
      })
    );
  }
}
