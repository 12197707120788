import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { ErrorHandlerV2Service, UserModel, UserState } from '@gea/digital-ui-lib';
import { MembershipModel, MembershipStateModel } from '@shared/models';
import { catchError, map, Observable, of, tap } from 'rxjs';
import { FetchOrganizations } from '@shared/state';
import { UserService } from '@shared/services';
import { User } from '@shared/models/user.model';

export class FetchMemberships {
  static readonly type = '[Memberships] Fetch Memberships';
}

@State<MembershipStateModel>({
  name: 'memberships',
})
@Injectable()
export class MembershipsState {
  constructor(
    private readonly store: Store,
    private readonly usersApiService: UserService,
    private readonly errorHandler: ErrorHandlerV2Service
  ) {}

  @Selector()
  static memberships(state: MembershipStateModel): MembershipModel[] | undefined {
    return state.memberships;
  }

  @Action(FetchMemberships)
  fetchMemberships({ patchState, dispatch }: StateContext<MembershipStateModel>): Observable<MembershipModel[]> {
    const user = this.store.selectSnapshot<UserModel>(UserState.user);

    if (!user?.userId || !user?.language) {
      return of([]);
    }

    return this.usersApiService.getUser(user.userId).pipe(
      map((user: User) => user.memberships ?? []),
      tap((memberships: MembershipModel[]) => {
        patchState({ memberships });
        dispatch(new FetchOrganizations());
      }),
      catchError((error): Observable<MembershipModel[]> => {
        this.errorHandler.handleError(error);
        patchState({ memberships: [] });
        return of([]);
      })
    );
  }
}
