import { NgClass } from '@angular/common';
import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { MatCard, MatCardContent } from '@angular/material/card';
import { HeadingComponent } from '../heading';
import { IconV3Component } from '@gea/digital-ui-lib';
import { TooltipModule } from 'primeng/tooltip';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'gea-hrt-info-box',
  template: `<div class="info-box">
    <div class="mdc-layout-grid__inner">
      <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12">
        <mat-card [ngClass]="{ 'with-border': withBorder }">
          <mat-card-content>
            <div class="flex">
              <gea-hrt-heading>{{ boxTitle | translate }}</gea-hrt-heading>
              <geaui-icon-v3
                pTooltip="{{ tooltip | translate }}"
                [color]="'grey'"
                tooltipPosition="left"
                [file]="'c-info'"
                [fileSize]="16" />
            </div>
            <div><ng-content></ng-content></div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </div> `,
  styleUrls: ['./info-box.component.scss'],
  standalone: true,
  imports: [MatCard, NgClass, MatCardContent, HeadingComponent, IconV3Component, TooltipModule, TranslateModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfoBoxComponent {
  @Input()
  boxTitle = '';

  @Input()
  tooltipHeading = '';

  @Input()
  tooltip = '';

  @Input()
  withBorder? = false;
}
