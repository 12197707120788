import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';

import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, Subject, takeUntil } from 'rxjs';

import {
  BarChartComponent,
  BarChartData,
  BarChartPatternType,
  HintComponent,
  InfoBoxComponent,
  SpinnerComponent,
} from '@shared/components';
import { DateHelper, cssVar } from '@shared/helpers';
import { FaultAnalysisHistoryItem, ResolutionCount } from '@shared/models';
import { AsyncPipe, NgIf } from '@angular/common';
import { FaultsState } from '@app/shared/state';
import { Select } from '@ngxs/store';

@Component({
  selector: 'gea-hrt-fault-history',
  templateUrl: './history.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [AsyncPipe, NgIf, TranslateModule, BarChartComponent, InfoBoxComponent, HintComponent, SpinnerComponent],
})
export class FaultHistoryComponent implements OnInit, OnDestroy {
  @Select(FaultsState.faultHistory) faultHistory$!: Observable<FaultAnalysisHistoryItem[] | undefined>;

  loading = true;

  private readonly historyChartData: BehaviorSubject<BarChartData<string, number> | null> = new BehaviorSubject<BarChartData<
    string,
    number
  > | null>(null);
  public historyChartData$: Observable<BarChartData<string, number> | null> = this.historyChartData.asObservable();

  seriesToShow = [
    {
      id: 'shutdowns.solved',
      dataKey: 'shutdowns.solved',
      translationKey: 'FAULT_ANALYSIS.HISTORY.SOLVED_SHUTDOWN',
      translatedName: '',
      stack: 'shutdown',
      color: cssVar('--geaui-brighter-red'),
      pattern: 'diagonal-line' as BarChartPatternType,
      orderInLegend: 4,
    },
    {
      id: 'shutdowns.pending',
      dataKey: 'shutdowns.pending',
      translationKey: 'FAULT_ANALYSIS.HISTORY.PENDING_SHUTDOWN',
      translatedName: '',
      stack: 'shutdown',
      color: cssVar('--geaui-red'),
      orderInLegend: 1,
    },
    {
      id: 'warnings.solved',
      dataKey: 'warnings.solved',
      translationKey: 'FAULT_ANALYSIS.HISTORY.SOLVED_WARNING',
      translatedName: '',
      stack: 'warning',
      color: cssVar('--geaui-brighter-yelow'),
      pattern: 'diagonal-line' as BarChartPatternType,
      orderInLegend: 3,
    },
    {
      id: 'warnings.pending',
      dataKey: 'warnings.pending',
      translationKey: 'FAULT_ANALYSIS.HISTORY.PENDING_WARNING',
      translatedName: '',
      stack: 'warning',
      color: cssVar('--geaui-yellow'),
      orderInLegend: 2,
    },
  ];

  readonly ngDestroyed$ = new Subject<void>();

  constructor(readonly translateService: TranslateService) {}

  ngOnInit() {
    this.translateLabels();
    this.subscribeToFautHistory();
  }

  ngOnDestroy() {
    this.ngDestroyed$.next();
    this.ngDestroyed$.complete();
  }

  private subscribeToFautHistory() {
    this.faultHistory$.pipe(takeUntil(this.ngDestroyed$)).subscribe((data) => {
      if (!data) {
        return;
      }

      this.loading = false;

      if (data.length === 0) {
        this.historyChartData.next(null);
        this.loading = false;
        return;
      }

      this.generateChartData(data);
    });
  }

  generateChartData(historyData: FaultAnalysisHistoryItem[]): BarChartData<string, number> {
    const locale = this.translateService.currentLang;
    const sortedHistoryData = [...historyData].sort((a, b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime());

    const historyChartData = {
      showTooltip: true,
      showLegend: true,
      legendPosition: ['bottom', 'right'],
      padding: { left: 0, right: 0 },
      xValues: sortedHistoryData.map(({ timestamp }) => DateHelper.localize(new Date(timestamp), locale)),
      yValues: this.seriesToShow.map((bar) => ({
        name: bar.translatedName,
        color: bar.color,
        pattern: bar.pattern
          ? {
              type: bar.pattern,
              color: 'white',
            }
          : undefined,
        stackName: bar.stack,
        data: sortedHistoryData.map((item) => {
          const parts = bar.dataKey.split('.');
          const faultType = parts[0];
          const resolution = parts[1];
          if (faultType && resolution) {
            const resolutionCount = item[faultType as keyof FaultAnalysisHistoryItem] as ResolutionCount;
            return resolutionCount[resolution as keyof ResolutionCount];
          } else {
            return 0;
          }
        }),
      })),
      legend: {
        data: [...this.seriesToShow]
          .sort((a, b) => a.orderInLegend - b.orderInLegend)
          .map((item) => ({
            name: item.translatedName,
          })),
      },
    };
    const nextChartData = historyChartData as unknown as BarChartData<string, number>;
    this.historyChartData.next(nextChartData);
    return nextChartData;
  }

  translateLabels() {
    this.seriesToShow = this.seriesToShow.map((row) => ({
      ...row,
      translatedName: this.translateService.instant(row['translationKey']) as string,
    }));
  }
}
