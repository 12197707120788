<gea-hrt-info-box [boxTitle]="title" [tooltip]="tooltip" [withBorder]="true">
  <p class="small-font">
    {{ 'FAULT_ANALYSIS.DATA_EXTRACTED_ON' | translate }}:
    <gea-hrt-update-time [date]="currentDate" />
  </p>
  <div class="table-container-section">
    <ng-container *ngIf="annunciations$ | async as data; else Loading">
      <ng-container *ngIf="data?.length; else NoData">
        <geaui-table-v3
          class="table"
          [tableId]="TABLE_ID"
          [columnDefinitions]="columnDefinitions"
          [sortable]="true"
          [data]="data" />
      </ng-container>
    </ng-container>
  </div>

  <ng-template #Loading>
    <gea-hrt-spinner />
  </ng-template>

  <ng-template #NoData>
    <gea-hrt-empty-data-placeholder [text]="placeholder | titlecase" />
  </ng-template>
</gea-hrt-info-box>
