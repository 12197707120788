import { Observable } from 'rxjs';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { LayoutService, MultiSelectV2Module, SelectOption, SelectV2Module } from '@gea/digital-ui-lib';
import { FiltersBaseComponentComponent, SpinnerComponent } from '@app/shared/components';
import { AsyncPipe, NgIf } from '@angular/common';
import { ToSelectOptionsPipe } from '@app/shared/pipes';
import { Select } from '@ngxs/store';
import { FaultsState, MachinesState } from '@app/shared/state';

@Component({
  selector: 'gea-hrt-fault-filters',
  templateUrl: './fault-analysis-filters.component.html',
  styleUrls: ['./fault-analysis-filters.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [SelectV2Module, MultiSelectV2Module, NgIf, AsyncPipe, TranslateModule, ToSelectOptionsPipe, SpinnerComponent],
})
export class FaultAnalysisFiltersComponent extends FiltersBaseComponentComponent {
  @Select(MachinesState.machines) machines$!: Observable<SelectOption[]>;
  @Select(FaultsState.faultCodeGroups) faults$!: Observable<SelectOption[]>;
  @Select(MachinesState.selectedForFaultAnalyses) selectedMachines$!: Observable<number[] | undefined>;
  @Select(FaultsState.selected) selectedFaults$!: Observable<number[] | undefined>;

  @Input() dateRange: SelectOption<number> = { value: 28 };

  @Output() setDateRange = new EventEmitter<number>();
  @Output() setStatus = new EventEmitter<string[]>();
  @Output() setMachines = new EventEmitter<number[]>();
  @Output() setFaultCodes = new EventEmitter<number[]>();

  formGroup = new FormGroup({
    machines: new FormControl<SelectOption<number>[]>([]),
    faultCodes: new FormControl<SelectOption<number>[]>([]),
    dateRange: new FormControl<SelectOption<number>[]>([]),
    status: new FormControl<SelectOption<string>[]>([]),
  });

  constructor(
    public translate: TranslateService,
    public layout: LayoutService
  ) {
    super(layout, translate);
    this.translateOptions();
  }

  onSetSelectedMachines(values: SelectOption<number>[]): void {
    this.setMachines.emit(values.map(({ value }) => value));
  }

  onSetSelectedFaultCodes(values: SelectOption<number>[]): void {
    this.setFaultCodes.emit(values.map(({ value }) => value));
  }

  onSetDateRange(event: { value: SelectOption } | SelectOption<number>): void {
    const value = this.isSelectOption(event) ? event.value : event.value.value;
    this.setDateRange.emit(value);
  }

  onSetStatus(status: SelectOption<string>[]): void {
    this.setStatus.emit(status.map((status) => status.value));
  }
}
