import { Component, Inject, Input, ChangeDetectionStrategy, OnInit, signal, effect } from '@angular/core';
import { OrganizationPermission } from '@app/shared/models';
import { UserPermissionsService } from '@app/shared/services';
import { OrganizationsState } from '@app/shared/state';
import {
  AboutPanelComponent,
  IconV3Component,
  NavigationItem,
  SidebarV2Module,
  SidePanel,
  SidepanelTypes,
} from '@gea/digital-ui-lib';
import { TranslateModule } from '@ngx-translate/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'gea-hrt-sidebar',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TranslateModule, IconV3Component, SidebarV2Module],
  template: `
    <div class="side-bar">
      <geaui-sidebar-v2
        [devisionName]="'GENERAL.TITLE' | translate"
        [navigationItems]="navigationItems"
        devisionIconPath="assets/images/GEA-Insight-partner-blue-red-care.svg"
        [bottomNavigation]="bottomNavigation">
        <a href="mailto:ber.bluredcare.doc@gea.com?subject=Regarding BLU-RED-Care:">
          <geaui-icon-v3 file="mail" class="icon-mail" />
          <span class="contact-text">{{ 'CONTACT_TEXT' | translate }}</span>
        </a>
      </geaui-sidebar-v2>
    </div>
  `,
  styles: `
    ::ng-deep {
      .sidebar .tree-container .tree-node .nav-item-name .node-label {
        width: unset !important;
        max-width: 30vw !important;
      }
    }
  `,
})
export class SidebarComponent implements OnInit {
  AboutPanelComponent = AboutPanelComponent;

  @Input({ required: true }) currentLanguage!: string;

  @Select(OrganizationsState.selected) selectedOrg$!: Observable<OrganizationPermission>;

  navigationItems: NavigationItem[] = [];

  selectedOrg = signal<OrganizationPermission | undefined>(undefined);

  constructor(
    @Inject('packageVersion') readonly packageVersion: string,
    private readonly tabNavigationService: UserPermissionsService
  ) {
    effect(() => {
      const selectedOrg = this.selectedOrg();

      if (selectedOrg) {
        const { permission, organization } = selectedOrg;

        if (organization) {
          this.navigationItems = this.tabNavigationService.getTabs({ permission, organization: organization.id });
        }
      }
    });
  }

  ngOnInit(): void {
    this.selectedOrg$.subscribe(this.selectedOrg.set);
  }

  get appVersion(): string {
    return `Version ${this.packageVersion}`;
  }

  private createFooterItem(key: string, header: string, label: string, action: () => void) {
    return {
      key,
      header,
      content: [
        {
          action,
          key: `UI-LIB.FOOTER.${key}`,
          icon: 'pdf',
          label: `UI-LIB.FOOTER.${label}`,
        },
      ],
    };
  }

  get bottomNavigation(): SidePanel[] {
    return [
      {
        key: 'about',
        icon: 'c-info',
        label: 'NAV-BAR.ABOUT',
        type: SidepanelTypes.FOOTER,
        ref: AboutPanelComponent,
        inputs: {
          copyright: 'GEA Group Services GmbH 2024',
          footers: [
            this.createFooterItem('imprint', 'IMPRINT', 'IMPRINT', this.openImprint.bind(this)),
            this.createFooterItem('privacy', 'DATAPRIVACY', 'DATAPRIVACY', this.openDataPrivacy.bind(this)),
            this.createFooterItem(
              'terms',
              'TERMS-AND-CONDITIONS',
              'TERMS-AND-CONDITIONS',
              this.openTermsAndConditions.bind(this)
            ),
            this.createFooterItem('cookies', 'COOKIE-SETTINGS', 'COOKIES', this.openCookieSettings.bind(this)),
          ],
        },
      },
      {
        key: 'appVersion',
        icon: 'new-notification',
        label: this.appVersion,
        card: 'AppVersionComponent',
        type: SidepanelTypes.CARD,
      },
    ];
  }

  openDataPrivacy() {
    window.open('../../assets/documents/Dataprivacy' + this.currentLanguage + '.pdf', '_blank');
  }

  openTermsAndConditions() {
    window.open('../../assets/documents/TandC' + this.currentLanguage + '.pdf', '_blank');
  }

  openImprint() {
    window.open('../../assets/documents/Imprint' + this.currentLanguage + '.pdf', '_blank');
  }

  openCookieSettings() {
    // @ts-expect-error Property 'OneTrust' does not exist on type 'Window & typeof globalThis'.ts(2339)
    window.OneTrust.ToggleInfoDisplay();
  }
}
