import { FaultRecommendationsApiService } from '@app/shared/services';
import { Injectable } from '@angular/core';

import { Action, Selector, State, StateContext } from '@ngxs/store';
import { ApiErrorResponse, ErrorHandlerV2Service } from '@gea/digital-ui-lib';
import { EMPTY, catchError, first, of, tap } from 'rxjs';

import {
  CreateRecommendationFromResolvedFault,
  FetchAnnunciationsMetadata,
  FetchFaultCatalog,
  FetchResolvedFaults,
} from './fault-recommendations.action';
import {
  FaultCatalog,
  AnnunciationsMetaData,
  FaultRecommendationsStateModel,
  ResolvedFault,
  RecommendationDto,
} from '@shared/models';
import { FetchRecommendations } from '../troubleshooting';

@State<FaultRecommendationsStateModel>({
  name: 'faultRecommendations',
})
@Injectable()
export class FaultRecommendationsState {
  constructor(
    public readonly faultRecommendationsApiService: FaultRecommendationsApiService,
    public readonly errorHandler: ErrorHandlerV2Service
  ) {}

  @Selector()
  static faultCatalog(state: FaultRecommendationsStateModel): FaultCatalog | undefined {
    return state.faultCatalog;
  }

  @Selector()
  static annunciationsMetadata(state: FaultRecommendationsStateModel): AnnunciationsMetaData | undefined {
    return state.annunciationsMetadata;
  }

  @Selector()
  static resolvedFaults(state: FaultRecommendationsStateModel): ResolvedFault[] | undefined {
    return state.resolvedFaults;
  }

  @Action(FetchFaultCatalog)
  fetchFaultCatalog({ patchState }: StateContext<FaultRecommendationsStateModel>, { params }: FetchFaultCatalog) {
    patchState({
      faultCatalog: undefined,
    });

    this.faultRecommendationsApiService
      .fetchFaultCatalog(params)
      .pipe(
        catchError((error: ApiErrorResponse) => {
          if (error) {
            this.errorHandler.handleError(error);
          }

          return of([]);
        }),
        first()
      )
      .subscribe((faultCatalog) => {
        patchState({
          faultCatalog,
        });
      });
  }

  @Action(FetchAnnunciationsMetadata)
  fetchAnnunciationsMetadata({ patchState }: StateContext<FaultRecommendationsStateModel>) {
    patchState({
      annunciationsMetadata: undefined,
    });

    this.faultRecommendationsApiService
      .fetchAnnunciationsMetadata()
      .pipe(
        catchError((error: ApiErrorResponse) => {
          if (error) {
            this.errorHandler.handleError(error);
          }

          return of();
        }),
        first()
      )
      .subscribe((annunciationsMetadata) => {
        patchState({
          annunciationsMetadata,
        });
      });
  }

  @Action(FetchResolvedFaults)
  fetchResolvedFaults({ patchState }: StateContext<FaultRecommendationsStateModel>) {
    patchState({
      resolvedFaults: undefined,
    });

    this.faultRecommendationsApiService
      .fetchResolvedFaults()
      .pipe(
        catchError((error: ApiErrorResponse) => {
          if (error) {
            this.errorHandler.handleError(error);
          }

          return of([]);
        }),
        first()
      )
      .subscribe((resolvedFaults) => {
        patchState({
          resolvedFaults,
        });
      });
  }

  @Action(CreateRecommendationFromResolvedFault)
  createRecommendationFromResolvedFault(
    { dispatch }: StateContext<FaultRecommendationsStateModel>,
    { params: { resolvedFault, reviewStatus } }: CreateRecommendationFromResolvedFault
  ) {
    return this.faultRecommendationsApiService.createRecommendationFromResolvedFault({ resolvedFault, reviewStatus }).pipe(
      catchError((error: ApiErrorResponse) => {
        this.errorHandler.handleError(error);
        return EMPTY;
      }),
      tap((newRecommendation: RecommendationDto) => {
        if (newRecommendation) {
          dispatch(new FetchRecommendations({ machine: resolvedFault.machineId, faultCode: resolvedFault.faultCode }));
        }
      })
    );
  }
}
