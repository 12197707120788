<div class="fault-recommendations-container">
  <gea-hrt-fault-recommendations-filters
    [faultCatalog]="faultCatalog"
    [hasRecommendations]="hasRecommendations"
    [selectedFaultName]="selectedFaultName"
    [annunciationsMetadata$]="annunciationsMetadata$"
    (setSelectedFault)="faultChanged($event)"
    (setHasRecommendations)="setHasRecommendations($event)" />

  <ng-container *ngIf="selectedFaultCode; else NoFaultSelected">
    <gea-hrt-fault-recommendations-section
      [selectedFaultCode]="selectedFaultCode"
      [editOrderModeOn]="editOrderModeOn"
      [recommendations$]="recommendations$"
      [permission$]="permission$"
      (orderFinished)="orderFinished()" />

    <gea-hrt-fault-recommendations-actions
      *ngIf="!editOrderModeOn"
      [recommendations$]="recommendations$"
      [selectedFaultCode]="selectedFaultCode"
      (setEditMode)="editOrderModeOn = true" />
  </ng-container>

  <gea-hrt-fault-recommendations-solved-faults (setReviewStatus)="setReviewStatus($event)" />
</div>

<ng-template #NoFaultSelected>
  <gea-hrt-hint>{{ 'TAB_CONTAINER.FAULT_RECOMMENDATIONS.NO_FAULT_SELECTED' | translate }}</gea-hrt-hint>
</ng-template>
