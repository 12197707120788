import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, signal } from '@angular/core';
import { RecommendationDto, RecommendationStatus } from '@app/shared/models';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NgIf, NgSwitch, NgSwitchCase } from '@angular/common';
import { ButtonV2Module, IconV3Component, SnackbarService } from '@gea/digital-ui-lib';
import { TroubleshootingService } from '@app/shared/services';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'gea-hrt-recommendation-readonly-actions',
  styleUrl: 'readonly-actions.component.scss',
  templateUrl: './readonly-actions.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgSwitch, NgSwitchCase, NgIf, TranslateModule, ButtonV2Module, IconV3Component],
})
export class ReadonlyActionsComponent {
  readonly Status = RecommendationStatus;
  loading = signal(false);

  @Input({ required: true }) recommendation!: RecommendationDto;

  constructor(
    public readonly translate: TranslateService,
    private readonly troubleshootingService: TroubleshootingService,
    private readonly snackBar: SnackbarService,
    private readonly cdr: ChangeDetectorRef
  ) {}

  updateStatus(status: RecommendationStatus): void {
    this.loading.set(true);
    this.cdr.detectChanges();

    this.troubleshootingService
      .setRecommendationStatus({ recommendationId: this.recommendation.id, recommendationStatus: status })
      .pipe(
        finalize(() => {
          this.cdr.markForCheck();
          setTimeout(() => {
            this.loading.set(false);
          }, 1000);
        })
      )
      .subscribe({
        next: () => {
          this.snackBar.add({
            detail: 'SUCCESS.UPDATE_RECOMMENDATION_SUCCESS',
            summary: 'SUCCESS.UPDATE_RECOMMENDATION_SUCCESS',
            severity: 'success',
          });
        },
        error: () => {
          this.snackBar.add({
            detail: 'ERROR.UPDATE_RECOMMENDATION_FAILED',
            summary: 'ERROR.UPDATE_RECOMMENDATION_FAILED',
            severity: 'error',
          });
        },
      });
  }
}
