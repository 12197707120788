import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgClass, NgIf } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { IconV3Component } from '@gea/digital-ui-lib';

@Component({
  selector: 'gea-hrt-fault-card',
  standalone: true,
  imports: [NgIf, NgClass, TranslateModule, IconV3Component],
  templateUrl: './fault-card.component.html',
  styleUrl: './fault-card.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FaultCardComponent {
  @Input() displayNumber = 0;
  @Input() cardType?: 'warning' | 'shutdown';
}
