import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, Input, OnChanges, OnInit } from '@angular/core';

import { ColumnDefinitionV3, TableServiceV3, TableV3Component } from '@gea/digital-ui-lib';

import { BaseFault, FaultCatalogType, Shutdown, Warning } from '@shared/models';
import {
  Fix_ACTION,
  shutdownDefinitions,
  warningDefinitions,
} from '@fault-analysis/table-config/fault-analysis-column-definitions';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { sortTableData } from '@fault-analysis/table-config/table.util';
import { filter, Observable } from 'rxjs';
import { TranslateEnumService } from '@shared/services';
import { EmptyDataPlaceholderComponent, InfoBoxComponent, SpinnerComponent, UpdateTimeComponent } from '@app/shared/components';
import { TranslateModule } from '@ngx-translate/core';
import { AsyncPipe, NgIf, TitleCasePipe } from '@angular/common';
import { Store } from '@ngxs/store';
import { Router } from '@angular/router';
import { OrganizationsState } from '@app/shared/state';

const generateText = (type: FaultCatalogType, text: 'TITLE' | 'TOOLTIP') => `FAULT_ANALYSIS.${type.toUpperCase()}S.${text}`;

@Component({
  selector: 'gea-hrt-fault-analysis-section',
  templateUrl: './section.component.html',
  styleUrl: './section.component.scss',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    InfoBoxComponent,
    TranslateModule,
    UpdateTimeComponent,
    NgIf,
    AsyncPipe,
    TableV3Component,
    SpinnerComponent,
    EmptyDataPlaceholderComponent,
    TitleCasePipe,
  ],
})
export class FaultAnalysisSectionComponent implements OnInit, OnChanges {
  columnDefinitions!: ColumnDefinitionV3[];
  TABLE_ID!: `${FaultCatalogType}-table`;
  placeholder!: string;
  title!: string;
  tooltip!: string;

  @Input({ required: true }) annunciations$!: Observable<Warning[] | Shutdown[] | undefined>;
  @Input({ required: true }) set type(type: FaultCatalogType) {
    this.TABLE_ID = `${type}-table`;
    this.placeholder = this.translateService.GetTranslationPlural(type);
    this.title = generateText(type, 'TITLE');
    this.tooltip = generateText(type, 'TOOLTIP');
    this.columnDefinitions = type === 'shutdown' ? shutdownDefinitions : warningDefinitions;
  }

  data!: Warning[] | Shutdown[];

  public currentDate = new Date();

  constructor(
    readonly tableService: TableServiceV3,
    readonly destroyRef: DestroyRef,
    readonly translateService: TranslateEnumService,
    readonly changeDetectorRef: ChangeDetectorRef,
    private readonly router: Router,
    private readonly store: Store
  ) {}

  ngOnInit() {
    this.currentDate = new Date();

    this.tableService
      .getFilterTableSettings(this.TABLE_ID)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((tableFilter) => {
        this.data = sortTableData(tableFilter, this.data);
      });

    this.tableService.actions
      .pipe(
        filter((action) => action.tableId === this.TABLE_ID),
        filter((action) => action.action === Fix_ACTION),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(async (action) => {
        await this.resolveFaultCode(action.rowData);
      });
  }

  ngOnChanges() {
    this.currentDate = new Date();
    this.changeDetectorRef.detectChanges();
  }

  async resolveFaultCode(baseFault: BaseFault): Promise<boolean> {
    try {
      const { faultCode, machineId } = baseFault;
      const organizationId = this.store.selectSnapshot(OrganizationsState.selectedId);
      return await this.router.navigate([`troubleshooting/${organizationId}`], {
        queryParams: { faultCode, machine: machineId },
      });
    } catch {
      return false;
    }
  }
}
