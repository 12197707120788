import { NgIf, NgSwitch, NgSwitchCase } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Permission, RecommendationDto } from '@app/shared/models';
import { IconV3Component } from '@gea/digital-ui-lib';
import { EditableActionsComponent } from '../editable-actions/editable-actions.component';
import { ReadonlyActionsComponent } from '../readonly-actions/readonly-actions.component';
import { RecommendationImagesDialogComponent } from '../images-dialog';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'gea-hrt-recommendation-item',
  templateUrl: './recommendation-item.component.html',
  styleUrl: './recommendation-item.component.scss',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [IconV3Component, NgIf, NgSwitch, NgSwitchCase, EditableActionsComponent, ReadonlyActionsComponent],
})
export class RecommendationItemComponent {
  @Input({ required: true }) recommendation!: RecommendationDto;
  @Input() permission?: Permission;
  @Input() index?: number;
  @Input() isEditable = false;
  @Input() isDraggable = false;

  constructor(private readonly dialog: MatDialog) {}

  openImagesDialog(images: (ArrayBuffer[] | string)[]) {
    this.dialog.open(RecommendationImagesDialogComponent, {
      data: { images },
    });
  }
}
