import { NgClass, NgIf, NgSwitch, NgSwitchCase, NgSwitchDefault, NgTemplateOutlet } from '@angular/common';
import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'gea-hrt-heading',
  standalone: true,
  imports: [NgClass, NgSwitch, NgSwitchCase, NgSwitchDefault, NgTemplateOutlet, NgIf],
  templateUrl: './heading.component.html',
  styleUrls: ['./heading.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeadingComponent {
  @Input() text: string | undefined;
  @Input() type: 'main' | 'sub' | 'minor' | 'tiny' = 'main';
  @Input() disabled = false;
  // blur title on small screens - in conjunction with a "switch to landscape" overlay
  @Input() blur = false;
}
