import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MeasurementSerieDto } from '@shared/models';
import { cssVar } from '@shared/helpers';
import { ChartData, ChartSerie, DataChartComponent } from './data-chart';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DateRangeComponent } from './date-range';
import { RelatedOperatingDataTogglesComponent } from './toggles';
import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { EmptyDataPlaceholderComponent, HintComponent, InfoBoxComponent, SpinnerComponent } from '@app/shared/components';
import { TroubleShootingState, RelatedOperatingDataState } from '@app/shared/state';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';

type MeasurementsConfig = Record<string, { color: string; yAxisPosition: 'left' | 'right' }>;

const measurementsConfig: MeasurementsConfig = {
  'Pending Fault': { color: cssVar('--geaui-brighter-red'), yAxisPosition: 'left' },
  pressure: { color: '#2F80ED', yAxisPosition: 'left' },
  temperature: { color: 'red', yAxisPosition: 'left' },
  motor_speed: { color: 'green', yAxisPosition: 'right' },
  motor_current: { color: 'blue', yAxisPosition: 'right' },
  saturated_suction_temperature: { color: '#fd7e14', yAxisPosition: 'right' },
  saturated_discharge_temperature: { color: '#6f42c1', yAxisPosition: 'right' },
};

@Component({
  selector: 'gea-hrt-related-operating-data',
  templateUrl: './related-operating-data.component.html',
  styleUrls: ['./related-operating-data.component.scss'],
  standalone: true,
  imports: [
    DateRangeComponent,
    RelatedOperatingDataTogglesComponent,
    NgIf,
    NgFor,
    EmptyDataPlaceholderComponent,
    SpinnerComponent,
    TranslateModule,
    DataChartComponent,
    InfoBoxComponent,
    HintComponent,
    AsyncPipe,
  ],
})
export class RelatedOperatingDataComponent implements OnInit {
  switches: [string, string, boolean][] = [];
  groupedChartData: [string, ChartData][] = [];
  data: MeasurementSerieDto[] = [];
  defaultSelection = ['temperature', 'pressure'];

  @Select(RelatedOperatingDataState.measurements) measurements$!: Observable<MeasurementSerieDto[] | undefined>;
  @Select(TroubleShootingState.selectedFault) selectedFault$!: Observable<number | undefined>;

  @Output() setDateRange = new EventEmitter<number | null>();

  loading = true;

  constructor(
    readonly translation: TranslateService,
    readonly cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.subscribeToMeasurements();
  }

  private subscribeToMeasurements() {
    this.measurements$.subscribe((measurements) => {
      if (!measurements) {
        return;
      }

      this.loading = false;
      this.data = measurements.map((temp) => ({
        ...temp,
        measurementType:
          this.translation.instant('SIGNAL_MEASUREMENT_TYPE.' + temp.measurementType.toLowerCase()) || temp.measurementType,
        measurementKey: temp.measurementType.toLowerCase(),
      }));

      this.updateSwitchesAndChart(this.defaultSelection);
      this.cdr.detectChanges();
    });
  }

  private updateSwitchesAndChart(defaultSelection: string[]) {
    this.switches = this.filterUniqueEntries(
      this.data.map((curr) => [curr.measurementKey, curr.measurementType, defaultSelection.includes(curr.measurementKey)])
    );
    this.generateChart(defaultSelection);
  }

  private generateSeries(data: MeasurementSerieDto[]): ChartSerie[] {
    let leftOffset = 0;
    let rightOffset = 0;

    return data.map(({ measurementType, dataPoints, measurementUnit, measurementKey }) => {
      const { color, yAxisPosition } = measurementsConfig[measurementKey] || { color: 'black', yAxisPosition: 'left' };
      const yAxisOffset = yAxisPosition === 'left' ? leftOffset : rightOffset;

      if (yAxisPosition === 'left') {
        leftOffset += 50;
      } else {
        rightOffset += 50;
      }

      return {
        name: measurementType,
        color,
        data: dataPoints.map(({ timestamp, value }) => [timestamp, Number(value)]),
        legendLabel: measurementType,
        yAxisName: measurementUnit,
        yAxisPosition,
        yAxisOffset,
        xValues: dataPoints.map(({ timestamp }) => timestamp),
      };
    });
  }

  private filterUniqueEntries(data: [string, string, boolean][]): [string, string, boolean][] {
    const seen = new Set<string>();
    return data.filter(([name]) => {
      if (seen.has(name)) {
        return false;
      }
      seen.add(name);
      return true;
    });
  }

  generateChart(types: string[]) {
    const filteredMeasurements = this.data.filter(({ measurementKey }) => types.includes(measurementKey));
    const temp: Record<string, MeasurementSerieDto[]> = {};

    filteredMeasurements.forEach((measurement) => {
      const key = measurement.component;
      if (!temp[key]) {
        temp[key] = [];
      }
      temp[key].push(measurement);
    });

    this.groupedChartData = Object.entries(temp).map(([component, measurements]) => [
      component,
      { series: this.generateSeries(measurements) },
    ]);
  }

  dateRangeChanged(dateRange: number | null) {
    this.setDateRange.emit(dateRange);
  }
}
