import { NgFor } from '@angular/common';
import { Component, Input } from '@angular/core';
import { SystemHealthCheck, SystemHealthMachineComponent, SystemHealthStatus } from '@shared/models';
import { AccordionModule } from 'primeng/accordion';
import { SystemHealthCheckComponent } from '../health-check';
import { SystemHealthComponentHeaderComponent } from '../component-header/component-header.component';

@Component({
  standalone: true,
  selector: 'gea-hrt-system-health-machine-component',
  imports: [NgFor, AccordionModule, SystemHealthComponentHeaderComponent, SystemHealthCheckComponent],
  template: `
    <p-accordionTab>
      <ng-template pTemplate="header">
        <gea-hrt-system-health-component-header [component]="component" />
      </ng-template>
      <div class="system-health-machine-component">
        <div class="health-cards">
          <gea-hrt-system-health-check
            *ngFor="let data of sortedHealthChecks"
            [organizationId]="organizationId"
            [machine]="machineId"
            [data]="data" />
        </div>
      </div>
    </p-accordionTab>
  `,
  styles: `
    .system-health-machine-component {
      margin-bottom: 0.5rem;
      overflow: hidden;
      .health-cards {
        display: flex !important;
        max-width: 84vw;
        overflow-x: auto;
        &::-webkit-scrollbar {
          height: 0.75rem;
        }
      }
    }
  `,
})
export class HealthMachineComponent {
  @Input({ required: true }) component!: SystemHealthMachineComponent;
  @Input({ required: true }) organizationId!: number;
  @Input({ required: true }) machineId!: number;

  get sortedHealthChecks() {
    const healthy: SystemHealthCheck[] = [];
    const errors: SystemHealthCheck[] = [];
    const warnings: SystemHealthCheck[] = [];
    const incomplete: SystemHealthCheck[] = [];
    const infos: SystemHealthCheck[] = [];

    this.component.healthChecks.forEach((check) => {
      switch (check.status) {
        case SystemHealthStatus.ERROR:
          errors.push(check);
          break;
        case SystemHealthStatus.WARNING:
          warnings.push(check);
          break;
        case SystemHealthStatus.HEALTHY:
          healthy.push(check);
          break;
        case SystemHealthStatus.INFO:
          infos.push(check);
          break;
      }
    });

    return [...errors, ...warnings, ...incomplete, ...healthy, ...infos];
  }
}
