import { Injectable } from '@angular/core';
import { ProblemResolveDto, RecommendationDto } from '@app/shared/models';
import { MachinesState, TroubleShootingState, SetRecommendationStatus, SolveProblem } from '@app/shared/state';
import { RecommendationAction } from '@app/tab-container/troubleshooting';
import { ErrorHandlerV2Service } from '@gea/digital-ui-lib';
import { Store } from '@ngxs/store';
import { catchError, Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TroubleshootingService {
  machine?: number;
  faultCode?: number;

  constructor(
    private readonly store: Store,
    public readonly errorHandler: ErrorHandlerV2Service
  ) {}

  setRecommendationStatus({
    recommendationId,
    recommendationStatus,
  }: RecommendationAction): Observable<RecommendationDto | undefined> {
    const { store } = this;
    const machine = store.selectSnapshot(MachinesState.selected)?.[0];
    const faultCode = store.selectSnapshot(TroubleShootingState.selectedFault);

    if (!machine || !faultCode) {
      return throwError(() => new Error('Missing Machine.'));
    }

    return store.dispatch(new SetRecommendationStatus({ machine, faultCode, recommendationId, recommendationStatus })).pipe(
      catchError((error) => {
        return throwError(() => new Error(error));
      })
    );
  }

  solveProblem(props?: ProblemResolveDto): Observable<{ success: boolean }> {
    const { store } = this;
    const machine = store.selectSnapshot(MachinesState.selected)?.[0];
    const faultCode = store.selectSnapshot(TroubleShootingState.selectedFault);

    if (!machine || !faultCode) {
      return throwError(() => new Error('Missing Machine.'));
    }

    return this.store
      .dispatch(
        new SolveProblem({
          machine,
          faultCode,
          ...props,
        })
      )
      .pipe(
        catchError((error) => {
          return throwError(() => new Error(error));
        })
      );
  }
}
