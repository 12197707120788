import { State, Action, StateContext, Selector } from '@ngxs/store';
import { OverviewItem } from '@shared/models/overview.model';
import { OverviewApiService } from '@shared/services/overview-api/overview-api.service';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';

export class FetchOverview {
  static readonly type = '[Overview] Fetch Overview';
  constructor(public organizationId?: number) {}
}

export interface OverviewStateModel {
  overview?: OverviewItem[];
}

@State<OverviewStateModel>({
  name: 'overview',
})
@Injectable()
export class OverviewState {
  @Selector()
  static overview(state: OverviewStateModel): OverviewItem[] | undefined {
    return state.overview;
  }

  constructor(private readonly overviewApi: OverviewApiService) {}

  @Action(FetchOverview)
  fetchOverview(ctx: StateContext<OverviewStateModel>, action: FetchOverview) {
    return this.overviewApi.fetchOverviewData(action.organizationId).pipe(
      tap((overview: OverviewItem[]) => {
        ctx.patchState({ overview });
      })
    );
  }
}
