import { NgFor } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogContent, MatDialogRef } from '@angular/material/dialog';
import { IconV3Component } from '@gea/digital-ui-lib';

@Component({
  templateUrl: './images-dialog.component.html',
  styleUrls: ['./images-dialog.component.scss'],
  standalone: true,
  imports: [IconV3Component, MatDialogContent, NgFor],
})
export class RecommendationImagesDialogComponent {
  currentImage!: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { images: string[] },
    public dialogRef: MatDialogRef<RecommendationImagesDialogComponent>
  ) {
    this.currentImage = data.images[0];
  }

  setCurrentImage(image: string) {
    this.currentImage = image;
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  trackByImage(_: number, image: string): string {
    return image;
  }
}
