<gea-hrt-info-box boxTitle="FAULT_ANALYSIS.HISTORY.TITLE" tooltip="FAULT_ANALYSIS.HISTORY.TOOLTIP" [withBorder]="false">
  @defer (when faultHistory$ | async) {
    <gea-hrt-spinner *ngIf="loading" [diameter]="40" />
    <ng-container *ngIf="!loading && (historyChartData$ | async); else noData">
      <gea-hrt-bar-chart [data]="historyChartData$ | async" width="100%" height="400px" />
    </ng-container>
  } @placeholder {
    <gea-hrt-spinner />
  }
</gea-hrt-info-box>

<ng-template #noData>
  <gea-hrt-hint>{{ 'FAULT_ANALYSIS.HISTORY.NO_DATA_AVAILABLE' | translate }}</gea-hrt-hint>
</ng-template>
