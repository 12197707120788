<div class="system-health-check" class="system-health-check">
  <div class="header" [class]="computedClass()">
    <div class="name">
      <b>{{ data.name | toTitle }}</b>
    </div>
    <div class="icon">
      <container-element [ngSwitch]="data.status">
        <geaui-icon-v3 *ngSwitchCase="0" file="check_white" />
        <geaui-icon-v3 *ngSwitchCase="1" file="warning_triangle" />
        <geaui-icon-v3 *ngSwitchCase="2" file="warning_round" />
        <geaui-icon-v3 *ngSwitchDefault file="check_white" />
      </container-element>
    </div>
  </div>
  <div class="metadata" *ngFor="let meta of meta">
    <div class="key">{{ meta[0] | toTitle }}</div>
    <div class="value">{{ meta[1] | number: '.1-2' }}</div>
  </div>
  <div class="footer">
    <span class="resolve-button" [class]="computedClass()" data-testid="resolve-button" (click)="openDetailsPopUp(data)">{{
      'SYSTEM_HEALTH.CARD.SHOW_DETAILS' | translate
    }}</span>
  </div>
</div>
