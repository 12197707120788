import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
  selector: 'gea-hrt-spinner',
  imports: [MatProgressSpinnerModule],
  template: `
    <div class="spinner">
      <mat-spinner class="spinner" [diameter]="diameter" />
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  styles: `
    div.spinner {
      height: 100% !important;
      width: 100% !important;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  `,
})
export class SpinnerComponent {
  @Input() diameter = 40;
}
