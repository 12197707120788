<gea-hrt-info-box
  [withBorder]="false"
  boxTitle="{{ 'TROUBLESHOOTING.RELATED_OPERATING_DATA.TITLE' | translate }}"
  tooltip="{{ 'TROUBLESHOOTING.RELATED_OPERATING_DATA.TOOLTIP' | translate }}">
  <div class="hrt-related-operating-data-header">
    <gea-hrt-ts-date-range (hoursChanged)="dateRangeChanged($event)" />
    <gea-hrt-related-opd-toggle
      *ngIf="selectedFault$ | async"
      class="toggle-buttons"
      [switches]="switches"
      [defaultSelection]="defaultSelection"
      (updateSelection)="generateChart($event)" />
  </div>
  <div *ngIf="selectedFault$ | async; else noSelectedFault" class="hrt-related-operating-data-content">
    <ng-container *ngIf="!loading; else spinner">
      <ng-container *ngIf="groupedChartData.length; else emptyChartData">
        <div *ngFor="let item of groupedChartData">
          <gea-hrt-data-chart *ngIf="item[1].series.length; else emptyChartData" [data]="item[1]" />
        </div>
      </ng-container>
    </ng-container>
  </div>
</gea-hrt-info-box>

<ng-template #emptyChartData>
  <gea-hrt-empty-data-placeholder text="{{ 'TROUBLESHOOTING.RELATED_OPERATING_DATA.TITLE' | translate }}" />
</ng-template>

<ng-template #spinner>
  <div class="spinner" class="alternative-spinner">
    <gea-hrt-spinner />
  </div>
</ng-template>

<ng-template #noSelectedFault>
  <gea-hrt-hint>{{ 'TROUBLESHOOTING.RECOMMENDATIONS.NO_SELECTED_FAULT' | translate }}</gea-hrt-hint>
</ng-template>
