<div class="card-container" [ngClass]="cardType">
  <div class="header">
    <h5 class="title">
      <ng-container *ngIf="cardType === 'warning'">{{ 'CUSTOMER_OVERVIEW.PENDING_WARNINGS' | translate }}</ng-container>
      <ng-container *ngIf="cardType === 'shutdown'">{{ 'CUSTOMER_OVERVIEW.PENDING_SHUTDOWNS' | translate }}</ng-container>
    </h5>
    <span class="icon">
      <geaui-icon-v3 *ngIf="cardType === 'warning'" file="pending_warnings" alt="warning" />
      <geaui-icon-v3 *ngIf="cardType === 'shutdown'" file="pending_shutdowns" alt="shutdown" />
    </span>
  </div>
  <div class="number">{{ displayNumber }}</div>
</div>
