import { Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { PermissionsGuard } from '@shared/guards/permissions.guard';
import { FaultAnalysisComponent } from './tab-container/fault-analysis';
import { SystemHealthComponent } from './tab-container/system-health/system-health.component';
import { FaultRecommendationsComponent } from './tab-container/fault-recommendations';
import { TabContainerComponent } from './tab-container/tab-container.component';
import { TroubleshootingComponent } from './tab-container/troubleshooting';
import { MachineOverviewComponent } from '@tab-container/machine-overview/machine-overview.component';
import { OverviewComponent } from '@tab-container/overview/overview.component';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'fault-analysis',
    pathMatch: 'full',
  },
  {
    path: 'state',
    redirectTo: 'fault-analysis',
    pathMatch: 'full',
  },
  {
    path: '',
    component: TabContainerComponent,
    canActivate: [MsalGuard],
    children: [
      {
        path: 'overview/:organizationId',
        component: OverviewComponent,
        canActivate: [MsalGuard],
      },
      {
        path: 'fault-analysis/:organizationId',
        component: FaultAnalysisComponent,
        canActivate: [MsalGuard],
      },
      {
        path: 'system-health/:organizationId',
        component: SystemHealthComponent,
        canActivate: [MsalGuard],
      },
      {
        path: 'recommendations/:organizationId',
        component: FaultRecommendationsComponent,
        canActivate: [MsalGuard, PermissionsGuard],
      },
      {
        path: 'troubleshooting/:organizationId',
        component: TroubleshootingComponent,
        canActivate: [MsalGuard],
      },
      {
        path: 'machine-overview/:organizationId',
        component: MachineOverviewComponent,
        canActivate: [MsalGuard],
      },
    ],
  },
];
