import { FetchFaultCatalogParams, CreateRecommendationFromResolvedFaultParams } from '@app/shared/models';

export class FetchFaultCatalog {
  static readonly type = '[FaultRecommendations] Fetch Fault Catalog';
  constructor(public readonly params?: FetchFaultCatalogParams) {}
}

export class FetchAnnunciationsMetadata {
  static readonly type = '[FaultRecommendations] Fetch Annunciations Metadata';
}

export class FetchResolvedFaults {
  static readonly type = '[FaultRecommendations] Fetch Resolved Faults';
}

export class CreateRecommendationFromResolvedFault {
  static readonly type = '[FaultRecommendations] Create Recommendation';
  constructor(public readonly params: CreateRecommendationFromResolvedFaultParams) {}
}
