import { Component } from '@angular/core';
import { Select } from '@ngxs/store';
import { OrganizationsState } from '@shared/state';
import { Observable, Subject } from 'rxjs';
import { OrganizationPermission } from '@shared/models';
import { DemoAccountApiService } from '@shared/services';
import { ButtonV2Module } from '@gea/digital-ui-lib';

@Component({
  standalone: true,
  imports: [ButtonV2Module],
  template: ` <geaui-button-v2 (click)="resetData()" [loading]="isLoading">Reset Demodata</geaui-button-v2> `,
  selector: 'gea-hrt-reset-demo-account',
})
export class ResetDemoAccountComponent {
  @Select(OrganizationsState.selected) organizationPermission$?: Observable<OrganizationPermission>;
  ngDestroyed$ = new Subject<void>();

  isLoading = false;

  constructor(readonly service: DemoAccountApiService) {}

  resetData() {
    this.isLoading = true;
    this.service.createData().subscribe(() => {
      this.isLoading = false;
      window.location.reload();
    });
  }
}
