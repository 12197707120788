import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { HrtApiService } from '../hrt-api/hrt-api.service';
import { MeasurementSerieDto } from '@shared/models';
import { DateHelper } from '@app/shared/helpers';

@Injectable({
  providedIn: 'root',
})
export class RelatedOperatingDataApiService {
  url = 'machines/{machine}/relatedOperatingDatas';

  constructor(readonly api: HrtApiService) {}

  fetchRelatedOperatingData({
    machine,
    dateHours,
    temperatureUnit,
    pressureUnit,
  }: {
    machine: number;
    dateHours?: number;
    temperatureUnit?: string;
    pressureUnit?: string;
  }): Observable<MeasurementSerieDto[]> {
    const url = this.url.replace('{machine}', machine.toString());
    const queryParams = {
      dateRange: DateHelper.hoursBack(dateHours),
      temperatureUnit: temperatureUnit ?? '',
      pressureUnit: pressureUnit ?? '',
    };
    return this.api.getForOrganization<MeasurementSerieDto[]>(url, { params: queryParams });
  }
}
