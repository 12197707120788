import { Component, OnDestroy, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { combineLatest, first, Observable, of, skipWhile, Subject, takeUntil, tap, distinctUntilChanged } from 'rxjs';
import { Store, Select } from '@ngxs/store';
import { ActivatedRoute, Params, Router } from '@angular/router';

import { RecommendationDto, RecommendationFaultStatus } from '@shared/models';
import {
  ClearRecommendations,
  FaultsState,
  FetchFaultCodes,
  FetchRecommendations,
  FetchRelatedOperatingData,
  MachinesState,
  OrganizationsState,
  TroubleShootingState,
  SetTroubleShootingSelectedFault,
  SetSelectedMachines,
} from '@shared/state';
import { SelectOption } from '@gea/digital-ui-lib';
import { Permission } from '@shared/models/permission.model';
import { AsyncPipe, NgIf } from '@angular/common';
import { TroubleshootingFiltersComponent } from './filters';
import { RecommendationsComponent } from './recommendations';
import { TranslateModule } from '@ngx-translate/core';
import { RelatedOperatingDataComponent } from './related-operating-data';

@Component({
  selector: 'gea-hrt-troubleshooting',
  styleUrls: ['./troubleshooting.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgIf,
    TroubleshootingFiltersComponent,
    AsyncPipe,
    RecommendationsComponent,
    TranslateModule,
    RelatedOperatingDataComponent,
  ],
  template: `
    <div class="troubleshooting-container">
      <gea-hrt-troubleshooting-filters
        (setSelectedMachine)="onMachineSelected($event)"
        (setSelectedFaultCode)="onFaultCodeSelected($event)" />

      <gea-hrt-recommendations
        *ngIf="(recommendationFaultStatus$ | async) !== RecommendationFaultStatus.SOLVED"
        [recommendations$]="recommendations$" />

      <gea-hrt-related-operating-data (setDateRange)="setDateRange($event)" />
    </div>
  `,
})
export class TroubleshootingComponent implements OnInit, OnDestroy {
  readonly ngDestroyed$ = new Subject<void>();
  readonly RecommendationFaultStatus = RecommendationFaultStatus;
  private userHasLimitedPermissions = false;

  @Select(TroubleShootingState.approvedRecommendations) private readonly _approvedRecommendations$!: Observable<
    RecommendationDto[] | undefined
  >;
  @Select(TroubleShootingState.recommendationFaultStatus) recommendationFaultStatus$!: Observable<
    RecommendationFaultStatus | undefined
  >;
  @Select(MachinesState.machines) machineOptions$!: Observable<SelectOption[] | undefined>;
  @Select(FaultsState.faultCodes) faultOptions$!: Observable<SelectOption[] | undefined>;
  @Select(OrganizationsState.permission) private readonly _permissions$!: Observable<Permission | undefined>;

  dateHours = 168;

  constructor(
    private readonly store: Store,
    private readonly router: Router,
    private readonly route: ActivatedRoute
  ) {}

  get recommendations$(): Observable<RecommendationDto[] | undefined> {
    return this.userHasLimitedPermissions ? of([]) : this._approvedRecommendations$;
  }

  ngOnInit() {
    this._permissions$.pipe(distinctUntilChanged(), takeUntil(this.ngDestroyed$)).subscribe((permission) => {
      this.userHasLimitedPermissions = ['executive_board', 'technician'].includes(permission as Permission);
    });

    this.subscribeToStreams();
    this.fetchData();
  }

  ngOnDestroy() {
    this.store.dispatch(new ClearRecommendations());
    this.ngDestroyed$.next();
    this.ngDestroyed$.complete();
  }

  private subscribeToStreams() {
    const machinesReady$ = this.machineOptions$.pipe(
      skipWhile((options) => !options?.length),
      first(),
      tap((machines) => machines?.length && this.fetchFaultCodesIfMissing(machines))
    );

    combineLatest([
      this.route.queryParams.pipe(first()),
      machinesReady$,
      this.faultOptions$.pipe(
        skipWhile((options) => !options?.length),
        first()
      ),
    ])
      .pipe(takeUntil(this.ngDestroyed$))
      .subscribe(([params, machines, faults]) => this.handleQueryParams(params, machines ?? [], faults ?? []));
  }

  private fetchFaultCodesIfMissing(machines: SelectOption<number>[]) {
    if (!this.store.selectSnapshot(FaultsState.faultCodes)?.length) {
      this.store.dispatch(new FetchFaultCodes({ machines: machines.map(({ value }) => value) }));
    }
  }

  private handleQueryParams(params: Params, machines: SelectOption[], faults: SelectOption[]) {
    const machine = this.getValidMachine(params['machine'], machines);
    const faultCode = this.getValidFaultCode(params['faultCode'], faults);
    this.onMachineSelected(machine);
    this.onFaultCodeSelected(faultCode, machine);
  }

  private getValidMachine(paramMachine: string, machines: SelectOption[]): number {
    const parsedMachine = parseInt(paramMachine, 10);
    return isNaN(parsedMachine)
      ? (this.store.selectSnapshot(MachinesState.selectedMachine) ?? machines[0]?.value)
      : parsedMachine;
  }

  private getValidFaultCode(paramFault: string, faults: SelectOption[]): number {
    const parsedFault = parseInt(paramFault, 10);
    return isNaN(parsedFault) ? (this.store.selectSnapshot(TroubleShootingState.selectedFault) ?? faults[0]?.value) : parsedFault;
  }

  private fetchRelatedOperatingData({ machine, dateHours = this.dateHours }: { machine: number; dateHours?: number }) {
    this.store.dispatch(new FetchRelatedOperatingData({ machine, dateHours }));
  }

  onMachineSelected(machine: number) {
    this.setQueryParams({ machine });
    this.store.dispatch(new SetSelectedMachines([machine]));
    this.fetchRelatedOperatingData({ machine });
  }

  onFaultCodeSelected(faultCode: number, machine?: number) {
    this.setQueryParams({ machine, faultCode });
    this.store.dispatch(new SetTroubleShootingSelectedFault(faultCode));
    this.fetchData();
  }

  setDateRange(dateHours: number | null) {
    if (dateHours === null) return;

    this.dateHours = dateHours;
    const machine = this.store.selectSnapshot(MachinesState.selectedMachine);

    if (machine) {
      this.fetchRelatedOperatingData({ machine, dateHours });
    }
  }

  setQueryParams(queryParams: { machine?: number; faultCode?: number }) {
    const newQueryParams = { ...queryParams };

    if (!newQueryParams.machine) {
      const urlTree = this.router.parseUrl(this.router.url);
      const machineParam = urlTree.queryParams['machine'];

      if (machineParam) {
        newQueryParams.machine = parseInt(machineParam, 10);
      }
    }

    this.router
      .navigate([], { queryParams: newQueryParams, queryParamsHandling: 'merge' as const })
      .catch(() => console.warn('Query param could not be set'));
  }

  fetchData() {
    const { store, dateHours } = this;
    const machine = store.selectSnapshot(MachinesState.selected)?.[0];
    const faultCode = store.selectSnapshot(TroubleShootingState.selectedFault);

    store.dispatch(new ClearRecommendations());

    if (!machine) return;

    this.fetchRelatedOperatingData({ machine, dateHours });

    if (faultCode) {
      store.dispatch(new FetchRecommendations({ machine, faultCode }));
    }
  }
}
