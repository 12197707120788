import { Component } from '@angular/core';
import { IconV3Component } from '@gea/digital-ui-lib';

@Component({
  standalone: true,
  imports: [IconV3Component],
  selector: 'gea-hrt-hint',
  template: `
    <span class="gea-hrt-hint-icon-container">
      <geaui-icon-v3 file="blue-info" />
    </span>
    <span class="gea-hrt-hint-text-container">
      <div>
        <ng-content></ng-content>
      </div>
    </span>
  `,

  styles: `
    .gea-hrt-hint-icon-container {
      float: left;
      ::ng-deep {
        svg,
        path {
          stroke: unset !important;
        }
      }
    }
    .gea-hrt-hint-text-container {
      float: left;
      line-height: 26px;
      margin-left: 10px;
    }
  `,
})
export class HintComponent {}
