import { NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ConnectivityStatusComponent, HeadingComponent } from '@app/shared/components';
import { SystemHealthMachineComponent } from '@app/shared/models';
import { IconV3Component } from '@gea/digital-ui-lib';
import { TranslateModule } from '@ngx-translate/core';
import { TooltipModule } from 'primeng/tooltip';

@Component({
  selector: 'gea-hrt-system-health-component-header',
  standalone: true,
  imports: [NgIf, ConnectivityStatusComponent, HeadingComponent, TranslateModule, IconV3Component, TooltipModule],
  template: `
    <div class="health-machine-component-header">
      <gea-hrt-heading>{{ component.name | translate }}</gea-hrt-heading>
      <geaui-icon-v3 [pTooltip]="component.name | translate" [color]="'grey'" tooltipPosition="left" [file]="'24px_c-info'" />
      <ng-container *ngIf="component.connectivity">
        <gea-hrt-connectivity-status [connectivity]="component.connectivity" />
      </ng-container>
    </div>
  `,
  styles: `
    .health-machine-component-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 0.5rem;
      padding: 0;
      margin: auto;
    }
  `,
})
export class SystemHealthComponentHeaderComponent {
  @Input({ required: true }) component!: SystemHealthMachineComponent;
}
