import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { MachineOverviewModel, MachineOverviewModelSerialized, Organization } from '@shared/models';
import { HrtApiService } from '../hrt-api/hrt-api.service';

@Injectable({
  providedIn: 'root',
})
export class OrganizationsApiService {
  readonly organizationsUrl = 'organizations';

  constructor(readonly api: HrtApiService) {}

  public fetchOrganizations(): Observable<Organization[]> {
    return this.api.get<Organization[]>(this.organizationsUrl);
  }

  public updateMachine(organizationId: number, machine: MachineOverviewModel) {
    const serialized = this.serializeMachineOverview(machine);
    return this.api.put<void>(`${this.organizationsUrl}/${organizationId}/machines`, [serialized]);
  }

  private serializeMachineOverview(machineOverview: MachineOverviewModel): MachineOverviewModelSerialized {
    return {
      id: machineOverview.machine.id,
      name: machineOverview.machine.name,
      nameInCRM: machineOverview.machine.nameInCRM,
      equipmentType: machineOverview.machine.equipmentType,
      omniId: machineOverview.machine.omniId,
      contactPersonName: machineOverview.contactPersonName,
      contactPersonEmail: machineOverview.contactPersonEmail,
      contactPersonPhone: machineOverview.contactPersonPhone,
    };
  }
}
