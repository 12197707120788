import { SolvedFaultViewDetailRendererComponent } from './table-renderers/button-renderer.component';
import { ResolvedFault } from '@shared/models';
import {
  ColumnDefinitionV3,
  ColumnRendererComponentV3,
  DateRendererV3Component,
  TextFilterV3Component,
} from '@gea/digital-ui-lib';
import { ResolvedFaultStatusRendererComponent, SolvedFaultImagesRendererComponent } from './table-renderers';

export const resolvedFaultsColumnDefinitions: ColumnDefinitionV3[] = [
  {
    key: 'faultCode',
    displayName: 'FAULT_RECOMMENDATIONS.SOLVED_FAULTS.FAULT_CODE',
    width: 0.1,
  },
  {
    key: 'faultName',
    displayName: 'FAULT_RECOMMENDATIONS.SOLVED_FAULTS.SOLVED_FAULT',
    resizeable: true,
    width: 1,
    filter: {
      component: TextFilterV3Component,
    },
  },
  {
    key: 'additionalNote',
    displayName: 'FAULT_RECOMMENDATIONS.SOLVED_FAULTS.ADDITIONAL_NOTE',
    sortable: false,
    resizeable: true,
  },
  {
    key: 'images',
    displayName: 'FAULT_RECOMMENDATIONS.SOLVED_FAULTS.IMAGES',
    sortable: false,
    resizeable: true,
    width: 1,
    renderer: {
      component: SolvedFaultImagesRendererComponent as ColumnRendererComponentV3<ResolvedFault>,
    },
  },
  {
    key: 'customer',
    displayName: 'FAULT_RECOMMENDATIONS.SOLVED_FAULTS.CREATED_BY',
    resizeable: true,
  },
  {
    key: 'solvedOn',
    displayName: 'FAULT_RECOMMENDATIONS.SOLVED_FAULTS.SOLVED_ON',
    resizeable: true,
    width: 0.5,
    renderer: {
      component: DateRendererV3Component as ColumnRendererComponentV3<ResolvedFault>,
      config: {
        columnKey: 'solvedOn',
      },
    },
  },
  {
    key: 'status',
    displayName: 'FAULT_RECOMMENDATIONS.SOLVED_FAULTS.STATUS',
    resizeable: true,
    renderer: {
      component: ResolvedFaultStatusRendererComponent as ColumnRendererComponentV3<ResolvedFault>,
    },
  },
  {
    key: 'actionBtn',
    sortable: false,
    displayName: '',
    width: 1,
    resizeable: true,
    renderer: {
      component: SolvedFaultViewDetailRendererComponent as ColumnRendererComponentV3<ResolvedFault>,
    },
  },
];
