import { SystemHealthDiagramComponent } from './../health-diagram/health-diagram.component';
import { NgSwitch, NgSwitchCase, NgSwitchDefault, NgFor, DecimalPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { HeadingComponent, UpdateTimeComponent } from '@app/shared/components';
import { SystemHealthCheck } from '@app/shared/models';
import { ToTitlePipe } from '@app/shared/pipes';
import { IconV3Component } from '@gea/digital-ui-lib';
import { TranslateModule } from '@ngx-translate/core';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';

@Component({
  selector: 'gea-hrt-health-check-dialog',
  templateUrl: './health-check-dialog.component.html',
  styleUrl: './health-check-dialog.component.scss',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    HeadingComponent,
    ToTitlePipe,
    IconV3Component,
    NgSwitch,
    NgSwitchCase,
    NgSwitchDefault,
    TranslateModule,
    UpdateTimeComponent,
    NgFor,
    DecimalPipe,
    SystemHealthDiagramComponent,
  ],
})
export class HealthCheckDialogComponent {
  public data: SystemHealthCheck;
  constructor(@Inject(DynamicDialogConfig) config: DynamicDialogConfig) {
    this.data = config.data;
  }

  computedClass() {
    const className = {
      0: 'healthy',
      1: 'warning',
      2: 'error',
      '-1': 'info',
    }[this.data.status];
    return `${className}`;
  }

  get metadata(): [string, number][] {
    // @ts-expect-error Type '[string, string | number][]' is not assignable to type '[string, number][]'.
    return Object.entries(this.data.healthStatusMetadata);
  }
}
