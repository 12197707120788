import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'toTitle',
  standalone: true,
})
export class ToTitlePipe implements PipeTransform {
  constructor(readonly translateService: TranslateService) {}

  transform(value: string): string {
    if (!value) {
      return value;
    }
    return this.translateService.instant('SYSTEM_HEALTH.PARAMETER_NAMES.' + value);
  }
}
