import { Observable } from 'rxjs';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { LayoutService, SelectOption, SelectV2Module } from '@gea/digital-ui-lib';
import { FiltersBaseComponentComponent, SpinnerComponent } from '@app/shared/components';
import { AsyncPipe, NgIf } from '@angular/common';
import { MachinesState } from '@app/shared/state';
import { Select } from '@ngxs/store';

@Component({
  selector: 'gea-hrt-health-filters',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgIf, AsyncPipe, SelectV2Module, TranslateModule, SpinnerComponent],
  template: `
    <div class="fault-analysis-filters-container">
      <ng-container *ngIf="machines$ | async as machines; else spinner">
        <geaui-select-v2
          [id]="'machine'"
          [label]="'FAULT_ANALYSIS.MACHINE' | translate"
          [group]="formGroup"
          [options]="machines"
          [selected]="{ value: selectedMachine }"
          (selectChanged)="setSelectedMachine.emit($event)" />
      </ng-container>
    </div>

    <ng-template #spinner>
      <gea-hrt-spinner />
    </ng-template>
  `,
  styleUrls: ['./system-health-filters.component.scss'],
})
export class SystemHealthFiltersComponent extends FiltersBaseComponentComponent {
  @Output() setSelectedMachine = new EventEmitter<SelectOption<number | undefined | null>>();

  @Select(MachinesState.machines) machines$!: Observable<SelectOption[] | undefined>;
  @Input({ required: true }) selectedMachine?: number | null;

  formGroup = new FormGroup({
    machine: new FormControl<number | null>(null),
  });

  constructor(
    public translate: TranslateService,
    public layout: LayoutService
  ) {
    super(layout, translate);
  }
}
