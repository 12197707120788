import { Injectable } from '@angular/core';
import { Permission } from '@app/shared/models';
import { NavigationItem } from '@gea/digital-ui-lib';

export enum RouterTabs {
  OVERVIEW = 0,
  SYSTEM_HEALTH_STATUS = 1,
  FAULT_ANALYSIS = 2,
  TROUBLESHOOTING = 3,
  FAULT_RECOMMENDATIONS = 4,
  MACHINE_OVERVIEW = 5,
}

@Injectable({
  providedIn: 'root',
})
export class UserPermissionsService {
  private organizationId!: number;
  private cachedTabs?: NavigationItem[];

  getTabs({ permission, organization }: { permission: Permission; organization: number }): NavigationItem[] {
    this.organizationId = organization;
    this.cachedTabs = this.generateTabs();

    switch (permission) {
      case 'GEA_App_Administrator':
      case 'GEA_App_Operator':
      case 'admin':
      case 'APP_hrt_insightpartner-bluredcare_admin':
        return this.cachedTabs;
      case 'executive_board':
        return this.executiveBoardTabs();
      case 'customer':
      case 'customerAdmin':
      case 'maintenance_manager':
      case 'technician':
        return this.techniciansTabs();
      default:
        return [];
    }
  }

  getActiveTab(currentRoute: string): NavigationItem | undefined {
    const tabs = this.cachedTabs ?? this.generateTabs();
    return tabs.find((tab) => tab.route?.startsWith(currentRoute)) ?? tabs[RouterTabs.FAULT_ANALYSIS];
  }

  private generateTabs(): NavigationItem[] {
    return [
      { label: 'TAB_CONTAINER.TABS.OVERVIEW_PAGE', route: `overview/${this.organizationId}`, icon: 'candlestick-chart' },
      { label: 'TAB_CONTAINER.TABS.SYSTEM_HEALTH_STATUS', route: `system-health/${this.organizationId}`, icon: 'add-fav' },
      { label: 'TAB_CONTAINER.TABS.FAULT_ANALYSIS', route: `fault-analysis/${this.organizationId}`, icon: 'candlestick-chart' },
      { label: 'TAB_CONTAINER.TABS.TROUBLESHOOTING', route: `troubleshooting/${this.organizationId}`, icon: 'api' },
      { label: 'TAB_CONTAINER.TABS.FAULT_RECOMMENDATIONS', route: `recommendations/${this.organizationId}`, icon: 'digital-key' },
      { label: 'TAB_CONTAINER.TABS.MACHINE_OVERVIEW', route: `machine-overview/${this.organizationId}`, icon: 'grid-interface' },
    ];
  }

  private techniciansTabs(): NavigationItem[] {
    return this.selectTabs([
      RouterTabs.OVERVIEW,
      RouterTabs.FAULT_ANALYSIS,
      RouterTabs.TROUBLESHOOTING,
      RouterTabs.MACHINE_OVERVIEW,
    ]);
  }

  private executiveBoardTabs(): NavigationItem[] {
    return this.selectTabs([
      RouterTabs.OVERVIEW,
      RouterTabs.FAULT_ANALYSIS,
      RouterTabs.FAULT_RECOMMENDATIONS,
      RouterTabs.TROUBLESHOOTING,
    ]);
  }

  private selectTabs(indices: RouterTabs[]): NavigationItem[] {
    return (this.cachedTabs ?? this.generateTabs()).filter((_, i) => indices.includes(i));
  }
}
