import { OrganizationsApiService } from '@shared/services';
import { ActivatedRoute } from '@angular/router';
import { Inject, Injectable } from '@angular/core';

import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { ApiErrorResponse, ErrorHandlerV2Service } from '@gea/digital-ui-lib';
import { catchError, first, of, take } from 'rxjs';

import { FetchMachines } from '@shared/state/machines';
import { OrganizationsComparator } from './organizations.comparator';
import { FetchOrganizations, FilterOrganizations, SetSelectedOrganization, SortOrganizations } from './organizations.action';
import { Organization, OrganizationsStateModel, MembershipModel, Permission, OrganizationPermission } from '@shared/models';
import { MembershipsState } from '@shared/state/memberships/memberships.state';

@State<OrganizationsStateModel>({
  name: 'organizations',
})
@Injectable()
export class OrganizationsState {
  constructor(
    @Inject('isAdminInLocal') protected isAdminInLocal: boolean,
    @Inject(Store) protected store: Store,
    readonly organizationsApi: OrganizationsApiService,
    readonly route: ActivatedRoute,
    readonly errorHandler: ErrorHandlerV2Service
  ) {}

  @Selector()
  static organizations(state: OrganizationsStateModel): Organization[] | undefined {
    return state.organizations;
  }

  @Selector()
  static filteredOrganizations(state: OrganizationsStateModel): Organization[] | undefined {
    return state.filteredOrganizations;
  }

  @Selector()
  static selected(state: OrganizationsStateModel): OrganizationPermission | undefined {
    return state.selected;
  }

  @Selector()
  static permission(state: OrganizationsStateModel): Permission | undefined {
    return state.selected?.permission;
  }

  @Selector()
  static selectedId(state: OrganizationsStateModel): number | undefined {
    return state.selected?.organization?.id;
  }

  @Action(FetchOrganizations)
  fetchOrganizations({ patchState, dispatch }: StateContext<OrganizationsStateModel>) {
    this.organizationsApi
      .fetchOrganizations()
      .pipe(
        catchError((error: ApiErrorResponse) => {
          if (error) {
            this.errorHandler.handleError(error);
          }
          return of([]);
        }),
        first()
      )
      .subscribe((organizations: Organization[]) => {
        patchState({
          organizations,
          filteredOrganizations: organizations,
        });

        // Auto-select if only one organization exists
        if (organizations?.length === 1) {
          dispatch(new SetSelectedOrganization(organizations[0]));
          return;
        }

        // Otherwise try to get organization from route
        const snapshot = this.route.firstChild?.firstChild?.snapshot;
        const organizationId = parseInt(snapshot?.params.organizationId as string);
        const organization = organizations.find(
          (_organization: Organization) => _organization.id === organizationId
        ) as Organization;
        if (organization) {
          dispatch(new SetSelectedOrganization(organization));
        }
      });
  }

  @Action(SetSelectedOrganization)
  setSelectedOrganization({ patchState, dispatch }: StateContext<OrganizationsStateModel>, action: SetSelectedOrganization) {
    if (action.organization) {
      // Small delay to ensure components can prepare for the transition
      setTimeout(() => {
        let memberships: MembershipModel[] = [];
        this.store
          .select(MembershipsState.memberships)
          .pipe(take(1))
          .subscribe((data) => {
            if (data) {
              memberships = data;
            }
          });

        // Make a local copy of the organization to avoid null issues
        const organization = action.organization;

        // Ensure organization is not null before proceeding
        if (!organization) {
          return;
        }

        const permission = this.getPermissionForSelectedOrganiZation(organization, memberships);

        // Update state with organization and permission
        patchState({
          selected: {
            organization,
            permission,
          },
        });

        // Wait a moment before dispatching further actions to ensure the UI has time to update
        setTimeout(() => {
          dispatch([
            new FetchMachines(),
            new SortOrganizations({ columnName: 'faults', sortOrder: 'desc', organizationId: organization.id }),
          ]);
        }, 100);
      }, 50);
    }
  }

  getPermissionForSelectedOrganiZation(organization: Organization, memberships: MembershipModel[]): Permission {
    const membership = memberships?.find((m) => m.organization.name === organization.name);
    const roleName = membership?.role.name ?? '';
    return this.createPermission(roleName);
  }

  createPermission(roleName: string): Permission {
    if (this.isAdminInLocal) return 'admin';

    switch (roleName) {
      case 'APP_hrt_insightpartner-bluredcare_admin':
      case 'GEA_App_Administrator':
      case 'GEA_App_Operator':
        return 'admin';
      case 'admin':
        return 'customerAdmin';
      case 'gea_read_only':
        return 'customer';
      case 'maintenance_manager':
        return 'maintenance_manager';
      case 'technician':
        return 'technician';
      case 'executive_board':
        return 'executive_board';
      default:
        return 'customer';
    }
  }

  @Action(SortOrganizations)
  sortOrganizations(
    { patchState, getState }: StateContext<OrganizationsStateModel>,
    { params: { columnName, sortOrder, organizationId } }: SortOrganizations
  ) {
    let customerList = [...(getState().filteredOrganizations ?? [])];
    customerList.sort(OrganizationsComparator.by(columnName, sortOrder));

    customerList = this.moveSelectedOrganizationToTopOfList(customerList, organizationId);

    if (customerList) {
      patchState({
        filteredOrganizations: customerList,
      });
    }
  }

  @Action(FilterOrganizations)
  filterCustomer({ patchState, getState }: StateContext<OrganizationsStateModel>, action: FilterOrganizations) {
    const customerList = [...(getState().organizations ?? [])];
    let filteredOrganizations = customerList.filter((customer: Organization) => {
      return customer.name.toLowerCase().indexOf(action.filterString.toLowerCase()) !== -1;
    });

    filteredOrganizations = this.moveSelectedOrganizationToTopOfList(
      filteredOrganizations,
      getState().selected?.organization?.id
    );

    if (filteredOrganizations) {
      patchState({
        filteredOrganizations,
      });
    }
  }

  private moveSelectedOrganizationToTopOfList(organizations: Organization[], organizationId: number | undefined): Organization[] {
    if (!organizationId) {
      return organizations;
    }

    const selected = organizations.find((org) => org.id === organizationId);
    if (selected) {
      return [selected, ...organizations.filter((org) => org.id !== selected.id)];
    }

    return organizations;
  }
}
