import { Router, RouterModule, Scroll } from '@angular/router';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable, Subject, combineLatest, EMPTY } from 'rxjs';
import { Select } from '@ngxs/store';
import { Organization, OrganizationPermission } from '@shared/models';
import { OrganizationsState } from '@shared/state';
import {
  ComplexDialogV3Service,
  NavigationItem,
  ErrorHandlerV2Service,
  Environment,
  AppEnvironmentState,
} from '@gea/digital-ui-lib';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { UserPermissionsService } from '@app/shared/services';
import { CustomerOverviewComponent } from '@app/customer-overview';
import { map, distinctUntilChanged, takeUntil, catchError, tap, filter } from 'rxjs/operators';
import { SidebarComponent } from './sidebar/sidebar.component';
import { TabContainerHeaderComponent } from './header/';
import { NgIf } from '@angular/common';

@Component({
  selector: 'gea-hrt-tab-container',
  standalone: true,
  imports: [SidebarComponent, TabContainerHeaderComponent, NgIf, RouterModule, TranslateModule],
  template: `
    <div class="tab-container">
      <ng-container *ngIf="organization">
        <gea-hrt-sidebar [currentLanguage]="currentLanguage" />
      </ng-container>
      <div class="tab-content">
        <gea-hrt-tab-container-header
          *ngIf="activeTab"
          [organization]="organization"
          [activeTab]="activeTab"
          [shouldRenderResetDemoAccountButton]="shouldRenderResetDemoAccountButton"
          [shouldRenderCustomerOverviewButton]="shouldRenderCustomerOverviewButton"
          (openCustomerOverview)="openCustomerOverview()" />
        <router-outlet />
      </div>
    </div>
  `,
  styleUrls: ['./tab-container.component.scss'],
})
export class TabContainerComponent implements OnInit, OnDestroy {
  @Select(OrganizationsState.selected) organizationPermission$!: Observable<OrganizationPermission>;
  @Select(OrganizationsState.organizations) organizations$!: Observable<Organization[]>;
  @Select(AppEnvironmentState.environment) environment$!: Observable<Environment | null>;

  private readonly destroy$ = new Subject<void>();

  currentLanguage = '';
  shouldRenderCustomerOverviewButton = false;
  shouldRenderResetDemoAccountButton = false;

  activeTab?: NavigationItem;
  dialogRef?: DynamicDialogRef<CustomerOverviewComponent>;
  organization!: Organization;

  readonly HRT_DEMO = 100258802;

  constructor(
    private readonly complexDialogService: ComplexDialogV3Service,
    private readonly router: Router,
    private readonly translate: TranslateService,
    private readonly tabNavigationService: UserPermissionsService,
    private readonly errorHandler: ErrorHandlerV2Service
  ) {}

  ngOnInit() {
    this.subscribeToTranslation();
    this.subscribeToStreams();
    this.checkMultipleOrganizations();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    this.closeCustomerOverviewDialog();
  }

  private subscribeToTranslation() {
    this.translate.onLangChange
      .pipe(
        takeUntil(this.destroy$),
        map(({ lang }) => lang),
        distinctUntilChanged()
      )
      .subscribe((lang) => (this.currentLanguage = lang));
  }

  private subscribeToStreams() {
    combineLatest([this.selectedOrd$(), this.scroll$(), this.environment$.pipe(takeUntil(this.destroy$))]).subscribe(
      ([{ organization }, scroll, env]) => {
        if (organization) {
          this.setOrganizationData({ organization, scroll, env });
        }
      }
    );
  }

  private selectedOrd$(): Observable<OrganizationPermission> {
    return this.organizationPermission$.pipe(
      takeUntil(this.destroy$),
      tap((data) => {
        if (!data) {
          this.openCustomerOverview();
        }
      }),
      filter(Boolean),
      catchError((err) => {
        this.errorHandler.handleError(err);
        return EMPTY;
      })
    );
  }

  private scroll$(): Observable<Scroll> {
    return this.router.events.pipe(
      filter((event): event is Scroll => event instanceof Scroll),
      takeUntil(this.destroy$),
      distinctUntilChanged(),
      catchError((err) => {
        this.errorHandler.handleError(err);
        return EMPTY;
      })
    );
  }

  private checkMultipleOrganizations() {
    this.organizations$
      .pipe(takeUntil(this.destroy$), filter(Boolean))
      .subscribe((orgs) => (this.shouldRenderCustomerOverviewButton = orgs.length > 1));
  }

  private setOrganizationData({
    organization,
    env,
    scroll,
  }: {
    organization: Organization;
    scroll: Scroll;
    env: Environment | null;
  }) {
    this.organization = organization;

    if (env) {
      this.shouldRenderResetDemoAccountButton = this.checkShouldRenderDemoAccountButton(
        this.router.url,
        this.organization.id,
        env
      );
    }

    this.updateNavigation(scroll.routerEvent.url, organization.id);
    this.closeCustomerOverviewDialog();
  }

  private checkShouldRenderDemoAccountButton(currentRoute: string, organizationId: number, environment: Environment) {
    if (!currentRoute.includes('fault-analysis')) {
      return false;
    }

    if (organizationId !== this.HRT_DEMO) {
      return false;
    }

    if (environment !== Environment.TEST) {
      return false;
    }

    return true;
  }

  private updateNavigation(currentUrl: string, organizationId: number) {
    const currentRoute = currentUrl.split('/')[1] ?? '';
    this.activeTab = this.tabNavigationService.getActiveTab(currentRoute);

    if (!currentRoute) {
      void this.router.navigate(['fault-analysis', organizationId]);
    }
  }

  private closeCustomerOverviewDialog() {
    this.dialogRef?.close();
    this.dialogRef = undefined;
  }

  openCustomerOverview() {
    if (this.dialogRef) return;

    this.dialogRef = this.complexDialogService.open(
      {
        header: 'CUSTOMER_OVERVIEW.TITLE',
        closable: true,
        hideButtons: true,
      },
      CustomerOverviewComponent
    ).ref;

    this.dialogRef.onClose.pipe(takeUntil(this.destroy$)).subscribe(() => (this.dialogRef = undefined));
  }
}
