import { Observable } from 'rxjs';
import { AsyncPipe, NgIf } from '@angular/common';
import { Component, EventEmitter, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { FiltersBaseComponentComponent, SpinnerComponent } from '@app/shared/components';
import { LayoutService, SelectOption, SelectV2Module } from '@gea/digital-ui-lib';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MachinesState, FaultsState, TroubleShootingState } from '@app/shared/state';
import { Select } from '@ngxs/store';

@Component({
  selector: 'gea-hrt-troubleshooting-filters',
  standalone: true,
  imports: [NgIf, SelectV2Module, TranslateModule, AsyncPipe, SpinnerComponent],
  template: `
    <div class="troubleshooting-filters">
      <ng-container *ngIf="machines$ | async as machineOptions; else loading">
        <geaui-select-v2
          id="machine"
          [label]="'TROUBLESHOOTING.MACHINE' | translate"
          [group]="formGroup"
          formControlName="machine"
          [options]="machineOptions"
          [selected]="{ value: selectedMachine$ | async }"
          (selectChanged)="setSelectedMachine.emit($event.value)" />

        <ng-container *ngIf="faults$ | async as faultOptions; else loading">
          <geaui-select-v2
            id="faultCode"
            [label]="'TROUBLESHOOTING.FAULT_NAME' | translate"
            [group]="formGroup"
            formControlName="faultCode"
            [options]="faultOptions"
            [disabled]="(selectedMachine$ | async) === undefined"
            [selected]="{ value: selectedFault$ | async }"
            (selectChanged)="setSelectedFaultCode.emit($event.value)" />
        </ng-container>
      </ng-container>
    </div>

    <ng-template #loading>
      <gea-hrt-spinner />
    </ng-template>
  `,
  styles: `
    .troubleshooting-filters {
      display: flex;
      gap: 0.5rem;
    }
  `,
})
export class TroubleshootingFiltersComponent extends FiltersBaseComponentComponent {
  @Output() setSelectedMachine = new EventEmitter<number>();
  @Output() setSelectedFaultCode = new EventEmitter<number>();

  @Select(MachinesState.machines) machines$!: Observable<SelectOption[] | undefined>;
  @Select(FaultsState.faultCodes) faults$!: Observable<SelectOption[] | undefined>;
  @Select(MachinesState.selectedMachine) selectedMachine$!: Observable<number | undefined>;
  @Select(TroubleShootingState.selectedFault) selectedFault$!: Observable<number | undefined>;

  formGroup = new FormGroup({
    machine: new FormControl(),
    faultCode: new FormControl(),
  });

  constructor(
    public layout: LayoutService,
    public translate: TranslateService
  ) {
    super(layout, translate);
    this.translateOptions();
  }
}
