import { Observable } from 'rxjs';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Permission, RecommendationDto } from '@app/shared/models';
import { InfoBoxComponent, SpinnerComponent } from '@app/shared/components';
import { AsyncPipe, NgIf } from '@angular/common';
import { RecommendationOrderListComponent } from '../recommendation-order-list';
import { RecommendationsListComponent } from '@app/tab-container/troubleshooting';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'gea-hrt-fault-recommendations-section',
  templateUrl: './recommendations-section.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    InfoBoxComponent,
    NgIf,
    AsyncPipe,
    RecommendationOrderListComponent,
    RecommendationsListComponent,
    SpinnerComponent,
    TranslateModule,
  ],
})
export class FaultRecommendationsSectionComponent {
  @Input({ required: true }) selectedFaultCode!: number;
  @Input({ required: true }) editOrderModeOn!: boolean;
  @Input({ required: true }) recommendations$!: Observable<RecommendationDto[] | undefined>;
  @Input({ required: true }) permission$!: Observable<Permission | undefined>;

  @Output() orderFinished = new EventEmitter<void>();
}
