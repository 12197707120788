<div class="fault-analysis-filters">
  <div class="flex">
    <div class="filter machines">
      <ng-container *ngIf="machines$ | async as machines; else spinner">
        <ng-container *ngIf="selectedMachines$ | async as selectedMachines">
          <geaui-multi-select-v2
            id="machines"
            [label]="'FAULT_ANALYSIS.MACHINE' | translate"
            [group]="formGroup"
            [selected]="selectedMachines | toSelectOptions"
            [options]="machines"
            (clearButtonClick)="onSetSelectedMachines([])"
            (selectChanged)="onSetSelectedMachines($event)" />
        </ng-container>
      </ng-container>
    </div>
    <div class="filter dateRange">
      <geaui-select-v2
        id="dateRange"
        [label]="'FAULT_ANALYSIS.DATE_RANGE' | translate"
        [group]="formGroup"
        [options]="dateRangeOptions"
        [selected]="dateRange"
        (selectChanged)="onSetDateRange($event)" />
    </div>
    <div class="filter status">
      <geaui-multi-select-v2
        id="status"
        [label]="'FAULT_ANALYSIS.FAULT_STATUS' | translate"
        [group]="formGroup"
        [options]="statusOptions"
        [selected]="statusOptionsDefault"
        (selectChanged)="onSetStatus($event)" />
    </div>
    <div class="filter faults">
      <ng-container *ngIf="faults$ | async as faults; else spinner">
        <ng-container *ngIf="selectedFaults$ | async as selectedFaults">
          <geaui-multi-select-v2
            id="faultCodes"
            [label]="'FAULT_ANALYSIS.FAULT_NAME' | translate"
            [group]="formGroup"
            [options]="faults"
            [disabled]="!faults.length"
            [selected]="selectedFaults | toSelectOptions"
            (clearButtonClick)="onSetSelectedFaultCodes([])"
            (selectChanged)="onSetSelectedFaultCodes($event)" />
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>

<ng-template #spinner>
  <gea-hrt-spinner />
</ng-template>
