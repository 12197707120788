<div class="recommendation-item container">
  <ng-container *ngIf="isDraggable">
    <geaui-icon-v3 file="scroll-vertical" class="images-button sort-icon" data-testid="btn-draggable" />
  </ng-container>

  <ng-container *ngIf="isEditable">
    <span class="validation-count" data-testid="span-validation-count">
      <ng-container [ngSwitch]="recommendation.validationCount">
        <geaui-icon-v3 file="done" *ngSwitchCase="1" />
        <geaui-icon-v3 file="done_all" *ngSwitchCase="2" />
      </ng-container>
    </span>
  </ng-container>

  <div class="text">
    <ng-container *ngIf="index !== undefined">
      <span data-testid="span-index">{{ index + 1 }}.</span>
    </ng-container>
    <span data-testid="span-action-name"> {{ recommendation.actionName }}</span>
  </div>

  <ng-container *ngIf="isEditable">
    <span *ngIf="recommendation.usageCount" class="usage-count" data-testid="span-usage-count"
      >({{ recommendation.usageCount }})</span
    >
  </ng-container>

  <div class="actions">
    <geaui-icon-v3
      *ngIf="recommendation.images.length"
      data-testid="icon-images"
      file="image"
      class="images-button"
      (click)="openImagesDialog(recommendation.images)" />

    <ng-container *ngIf="!isDraggable">
      <gea-hrt-recommendation-editable-actions *ngIf="isEditable" [permission]="permission" [recommendation]="recommendation" />
      <gea-hrt-recommendation-readonly-actions *ngIf="!isEditable" [recommendation]="recommendation" />
    </ng-container>
  </div>
</div>
