<ng-container *ngIf="annunciationsMetadata$ | async as annunciationsMetadata; else placeholder">
  <div class="fault-recommendations-filters filters">
    <div class="select">
      <ng-container *ngIf="selectedFaultName">
        <geaui-autocomplete-select-v2
          [id]="'fault'"
          label="TAB_CONTAINER.FAULT_RECOMMENDATIONS.ANNUNCIATION"
          [options]="faultCodes"
          [selected]="selectedFaultName"
          (selectChanged)="setSelectedFault.emit($event)" />
      </ng-container>
    </div>
    <div class="display">
      <div class="checkbox">
        <geaui-checkbox-v2
          id="hasRecommendations"
          [label]="'TAB_CONTAINER.FAULT_RECOMMENDATIONS.NO_RECOMMENDATIONS'"
          [formName]="'hasRecommendations'"
          [form]="formGroup"
          (changed)="setHasRecommendations.emit($event)" />
      </div>
      <div class="status">
        <div class="report" data-testid="total-faults">
          {{ 'TAB_CONTAINER.FAULT_RECOMMENDATIONS.TOTAL_ANNUNCIATIONS' | translate }}:
          <strong>{{ annunciationsMetadata.totalFaults }}</strong>
        </div>
        <div class="report" data-testid="without-recommendations">
          {{ 'TAB_CONTAINER.FAULT_RECOMMENDATIONS.WITHOUT_RECOMMENDATIONS' | translate }}:
          <strong>{{ annunciationsMetadata.withoutRecommendations }}</strong>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #placeholder>
  <gea-hrt-spinner />
</ng-template>
