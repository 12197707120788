import { TroubleshootingService } from '@shared/services';
import { MatDialog } from '@angular/material/dialog';
import { Component, Input, OnDestroy, ChangeDetectionStrategy } from '@angular/core';
import { Observable, Subject, Subscription, takeUntil } from 'rxjs';
import { ProblemSolveDialogComponent } from './solve-dialog';
import { RecommendationDto, RecommendationStatus, ProblemResolveDto, Permission } from '@shared/models';
import { ContactDialogComponent } from './contact-dialog/contact-dialog.component';
import { Select, Store } from '@ngxs/store';
import { OrganizationsState, TroubleShootingState } from '@shared/state';
import { AsyncPipe, NgIf } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonV2Module } from '@gea/digital-ui-lib';
import { Router } from '@angular/router';

@Component({
  selector: 'gea-hrt-troubleshooting-problem-solve',
  template: `
    <div class="problem-solve">
      <div *ngIf="showText" class="problem-solve-text">
        {{ 'TROUBLESHOOTING.RECOMMENDATIONS.UNSOLVABLE_PROBLEM_MESSAGE' | translate }}
        <a href="mailto:CustomerPortal@GEA.com" class="contact">
          {{ 'TROUBLESHOOTING.PROBLEM_SOLVING.CONTACT' | translate }}
        </a>
      </div>
      <div class="problem-solve-button">
        <geaui-button-v2
          (click)="openSolutionDescriptionDialog()"
          [disabled]="(permission$ | async) === 'executive_board' || (permission$ | async) === 'customer'">
          {{ 'TROUBLESHOOTING.PROBLEM_SOLVING.BUTTON_PROBLEM_SOLVED' | translate }}
        </geaui-button-v2>
      </div>
    </div>
  `,
  styleUrls: ['./problem-solve.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, TranslateModule, ButtonV2Module, AsyncPipe],
})
export class TroubleshootingProblemSolveComponent implements OnDestroy {
  permission$?: Observable<Permission | undefined>;

  constructor(
    readonly dialog: MatDialog,
    readonly store: Store,
    readonly troubleshootingService: TroubleshootingService,
    readonly router: Router
  ) {
    this.permission$ = this.store.select(OrganizationsState.permission);
  }

  @Input() showText = true;
  @Input({ required: true }) list: RecommendationDto[] = [];

  @Select(TroubleShootingState.problemSolvedSuccess) problemSolvedSuccess$!: Observable<boolean>;

  closeDialog$!: Subscription;
  ngDestroyed$ = new Subject<void>();

  private subscribeToProblemSolvedSuccess() {
    this.problemSolvedSuccess$.pipe(takeUntil(this.ngDestroyed$)).subscribe((success: boolean) => {
      if (success) {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        this.router.navigate(['fault-analysis', this.store.selectSnapshot(OrganizationsState.selected)?.organization?.id]);
      }
    });
  }

  ngOnDestroy() {
    if (this.closeDialog$) {
      this.closeDialog$.unsubscribe();
    }

    this.ngDestroyed$.next();
    this.ngDestroyed$.complete();
  }

  problemSolvedClicked() {
    if (this.list.some(({ status }) => status === RecommendationStatus.DONE)) {
      this.troubleshootingService.solveProblem();
    } else {
      this.openSolutionDescriptionDialog();
    }
  }

  openContactDialog() {
    const dialog = this.dialog.open(ContactDialogComponent, {
      width: '100rem',
      minHeight: '50vh',
    });

    this.closeDialog$ = dialog.afterClosed().subscribe(() => ({}));
  }

  openSolutionDescriptionDialog() {
    const dialog = this.dialog.open(ProblemSolveDialogComponent, {
      width: '100rem',
      minHeight: '50vh',
    });

    dialog.componentInstance.recommendations = this.list;

    this.closeDialog$ = dialog.afterClosed().subscribe((props: ProblemResolveDto) => {
      if (!props) {
        // user closed/canceled the modal -> do nothing
        return;
      }
      this.troubleshootingService.solveProblem(props);
    });
  }
}
